import { connect } from "react-redux";
import ReportedContent from '../../components/ReportedContent/ReportedContent';
import { fetchContent, fetchuserList, updateReportedContent } from '../../redux/actions/reportedContentActions';
 
const mapStateToProps = state => {
  console.log('state in container', state);
  return {
    premiumContentList : state.reportContent,
    listReporteeUsers : state.listReporteeUsers,
    deleteContent : state.deleteContent,
    updateContent : state.updateReportedContent ,
    deleteNotification : state.deleteNotification ,
  };
};
 
const mapDispatchToProps = (dispatch) => {
  return {
    _fetchData: (page, skip, contentByUser, byStatus) => {
      return dispatch(fetchContent(page, skip, contentByUser, byStatus));
    },
    _fetchReporteeUsers : (recordId) => {
      return dispatch(fetchuserList(recordId))
    },
    _updateReportedContent : (id, data) => {
      return dispatch(updateReportedContent(id, data));
    },
  };
};
 
const ReportedContentContainer = connect(mapStateToProps, mapDispatchToProps)(
    ReportedContent
);
 
export default ReportedContentContainer;