import * as Actions from '../actions/actionTypes';

const initialState = {
    isFetching : false,
    err : false,
    isFetched : false,
    message : ''
  };
  
  // receiving response sent by action according to type of action
  export default function createFAQ(state = initialState, action) {
      //console.log(action,'action')
    switch (action.type) {
      case Actions.CREATEFAQREQUEST:
        return { 
            isFetching : true,
            err : false,
            isFetched : false,
            message : 'fetching...'
        };
        break;
  
      case Actions.CREATEFAQSUCCESS:
        return { 
            isFetching : false,
            err : false,
            isFetched : true,
            message : action.payload.message
            
          };
          break;
          
          case Actions.CREATEFAQERROR:
          return { 
            isFetching : false,
            err : true,
            isFetched : false,
            message : action.payload.message
         };
        break;
      
      case Actions.CREATEFAQCLEAR:
        return { 
            isFetching : false,
            err : false,
            isFetched : false,
            message : ''
         };
        break;

      default:
        return state;
    }
  }
  