import { GETFINDERMEETINGSERROR, GETFINDERMEETINGSREQUEST, GETFINDERMEETINGSSUCCESS } from './../actions/actionTypes'
 
const initialState = {
    requestDone: false,
    isFetched: false,
    isFetching : false,
    err : false,
    message:'',
    finderMeetings: [],
    total : 0,
    configs : {}
  };
  
  // receiving response sent by action according to type of action
  export default function listFinderMeetings(state = initialState, action) {
    let { payload } = action
    switch (action.type) {
        case GETFINDERMEETINGSREQUEST:
          return { 
            requestDone: false,
            isFetched: false,
            isFetching : true,
            err : false,
            message:'',
            finderMeetings: [],
            total : 0,
            configs : payload.configs
           };
          break;
        case GETFINDERMEETINGSSUCCESS:
        return { 
            requestDone: true,
            isFetched: true,
            isFetching : false,
            err : false,
            message:'',
            finderMeetings: payload.finderMeetings,
            total : payload.total,
            configs : payload.configs
        };
        break;
  
        case GETFINDERMEETINGSERROR:
        return { 
            requestDone: true,
            isFetched: false,
            isFetching : false,
            err : true,
            message: payload.message,
            finderMeetings : [],
            total : 0,
            configs : payload.configs
        };
        break;
        
      default:
        return state;
    }
  }
