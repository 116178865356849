import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

export default class TopbarMenuLinks extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  };
  
  render() {
    if(!this.props.onclickHandler){
        return (
          <Link className='topbar__link' to={this.props.path}>
            <span className={`topbar__link-icon lnr lnr-${this.props.icon}`}/>
            <p className='topbar__link-title'>{this.props.title}</p>
          </Link>
        )
      }else{
        return (
          <button className={`topbar__link ${this.props.className}`} onClick={ ()=>{this.props.onclickHandler()} }>
            <span className={`topbar__link-icon lnr lnr-${this.props.icon} `}/>
            <p className='topbar__link-title'>{this.props.title}</p>
          </button>
        )
      }
  }
}