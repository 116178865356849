import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
} from "reactstrap";
import TableComponent from "../Shared/Table/Table";
import AddReactions from "./AddReactionsModal";
import notification from "../../helpers/notificationHelper";
import PenIcon from "mdi-react/PencilIcon";
import notify from "./../../helpers/notificationHelper";
// import TablePagination from './../Shared/Table/TablePagination';

class AllReactions extends React.Component {
  state = {
    headsAg: [],
    modal: false,
    title: "",
    reactions: [],
    limit: 10,
    page: 1,
    content: null,
    file: "",
    file2: "",
    content2: null,
    createdBy: "",
    modalType: "",
    react_id: "",
    editModal: false,
    deleteModal: false,
    selectedEmoji: "",
  };

  componentDidMount() {
    //console.log("here in all users", this.props);
    this.props._fetchReactions();
    let { reactions } = this.props.reactions;
    // let total = categories.length
    //console.log("=*******************", this.props.categories);
    let headsAg = [
      {
        headerName: "Sr. No",
        field: "i",
        width: "80",
        sortable: true,
        filter: "text",
        cellRendererFramework: this.renderIndex,
      },
      {
        headerName: "English Title",
        field: "en_title",
        suppressSizeToFit: true,
      },
      {
        headerName: "French Title",
        field: "fr_title",
        suppressSizeToFit: true,
      },
      {
        headerName: "Spanish Title",
        field: "es_title",
        suppressSizeToFit: true,
      },
      //     {headerName: "Is Active",
      //     field: "isActive",
      //     suppressSizeToFit: true,
      //     cellRendererFramework : this.renderIsActiveColumn,
      //     width:"160",
      //     cellStyle : (params) =>{
      //       return {textAlign :  'center'}
      //     }
      //   },
      {
        headerName: "Actions",
        cellRendererFramework: this.renderAction,
        cellStyle: (params) => {
          return { textAlign: "center" };
        },
      },
    ];
    this.setState({ headsAg, reactions });
  }
  setData = (data, type) => {
    //console.log('data, type', data, type);
    let { _id, es_title, en_title, fr_title, media } = data;
    this.setState(
      {
        es_title,
        en_title,
        fr_title,
        react_id: _id,
        selectedEmoji: media,
      },
      () => {
        this.toggle(type);
      }
    );
  };
  renderAction = (record) => {
    let buttons = (
      <span>
        {/* <EyeIcon onClick={()=>{ this.setData(record.data, 'modal') } } size={'18px'} className="cursor-pointer"/> */}
        <PenIcon
          className="cursor-pointer ml-2"
          size={18}
          onClick={() => {
            this.setData(record.data, "editModal");
          }}
        />
        {/* <DeleteIcon
          className="cursor-pointer ml-2"
          size={18}
          onClick={() => {
            this.setData(record.data, "deleteModal");
          }}
        /> */}
      </span>
    );
    return buttons;
  };

  renderIndex = (record) => {
    return record.rowIndex + 1;
  };

  editRecord = (record) => {
    //let id = record.id;
    //console.log("record", record);
  };

  getSelectedRows = () => {
    const selectedNodes = this.gridApi.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    const selectedDataStringPresentation = selectedData
      .map((node) => node.make + " " + node.model)
      .join(", ");
    alert(`Selected nodes: ${selectedDataStringPresentation}`);
  };

  renderGetDeleteColumn = () => {
    return <div>Delete</div>;
  };

  static getDerivedStateFromProps(props, state) {
    //   console.log("inside the getDerivedStateFromProps condition======", props.categories.categories && props.categories.categories !== state.categories && !props.categories.isFetching && props.categories.isFetched)
    if (
      props.reactions.reactions &&
      props.reactions.reactions !== state.reactions &&
      !props.reactions.isFetching &&
      props.reactions.isFetched
    ) {
      //console.log("going in derived props - 21", props);
      let cats = props.reactions.reactions
        .map((cat) => cat)
        .filter((o) => o !== undefined);
      //console.log("cats", cats);
      return {
        reactions: cats,
        total: props.reactions.reactions.length,
      };
    }
  }

  componentDidUpdate(prevProps, prevState) {
    //console.log("updated props", this.props);
    let { addReactions, updateReaction, deleteReaction } = this.props;
    if (addReactions && addReactions !== prevProps.addReactions) {
      //console.log("going in updated props - 1");
      if (
        addReactions &&
        !addReactions.catCreateError &&
        !addReactions.creating
      ) {
        notification("succes", "Reactions Created!");
        return this.props._fetchReactions();
      }
      if (
        addReactions &&
        addReactions.catCreateError &&
        !addReactions.creating
      ) {
        //console.log("going in updated props - 2");
        return notification("err", addReactions.message);
      }
    }
    if (updateReaction && updateReaction !== prevProps.updateReaction) {
      //console.log("going in updated props - 1");
      if (
        updateReaction &&
        updateReaction.isFetched &&
        !updateReaction.err &&
        !updateReaction.isFetching
      ) {
        notification("succes", updateReaction.message);
        this.setState(
          {
            modal: false,
            editModal: false,
            react_id: "",
            en_title: "",
            es_title: "",
            fr_title: "",
            content: "",
          },
          () => {
            return this.props._fetchReactions();
          }
        );
        //return this.toggle("editModal");
      }
      if (
        updateReaction &&
        updateReaction.err &&
        !updateReaction.isFetched &&
        !updateReaction.isFetching
      ) {
        //console.log("going in updated props - 2");
        return notification("err", updateReaction.message);
      }
    }
    if (deleteReaction && deleteReaction !== prevProps.deleteReaction) {
      //console.log("going in deleted props - 1");
      if (
        deleteReaction &&
        deleteReaction.isFetched &&
        !deleteReaction.err &&
        !deleteReaction.isFetching
      ) {
        notification("succes", deleteReaction.message);
        this.props._fetchReactions();
        return this.toggle("deleteModal");
      }
      if (
        deleteReaction &&
        deleteReaction.err &&
        !deleteReaction.isFetched &&
        !deleteReaction.isFetching
      ) {
        //console.log("going in delted props - 2");
        return notification("err", deleteReaction.message);
      }
    }
  }

  toggle = (type) => {
    //   e.preventDefault();
    //console.log("here in toggle modal", typeof type);
    if (typeof type !== "string") {
      return this.setState({
        modal: false,
        editModal: false,
        deleteModal: false,
      });
    }
    this.setState((prevStates) => {
      return {
        [type]: !prevStates[type],
      };
    });
  };

  createReaction = (e) => {
    e.preventDefault();
    let { name } = e.target;
    //console.log('state here is ', this.state);
    const formData = new FormData();
    //console.log('this.state.content', this.state.content);
    let { en_title, es_title, fr_title, content, modal } = this.state;

    if (content === null)
      return notify("error", "Please upload a non-empty file !");
    // Update the formData object
    formData.append("media", content, content.name);
    formData.append("en_title", en_title);
    formData.append("es_title", es_title);
    formData.append("fr_title", fr_title);
    formData.append("isActive", true);
    formData.append("isDefault", false);

    if (name == "add") {
      if (!(en_title && es_title && fr_title && content))
        return notification("err", "All Fields are mandatory!");

      if (!(en_title && es_title && fr_title)) {
        return this.setState({ modal: modal });
      } else {
        this.setState({ modal: !modal, content: null });
        //console.log('modal', modal)
        return this.props._addReactions(formData);
      }
    } else {
      return notification("err", "something went wroong!");
    }
  };

  updateReaction = (e) => {
    //console.log('update reaction -----')
    e.preventDefault();
    let { react_id, en_title, es_title, fr_title, content, editModal } =
      this.state;
    //console.log('state now is *******************************', this.state);
    const formData2 = new FormData();
    // if (content === null) return notify('error', 'Please upload a non-empty file !')
    // Update the formData object
    if (content) {
      formData2.append("media", content, content.name);
    }
    formData2.append("en_title", en_title);
    formData2.append("es_title", es_title);
    formData2.append("fr_title", fr_title);
    formData2.append("isActive", true);
    formData2.append("isDefault", false);

    if (!(en_title && es_title && fr_title))
      return notification("err", "All Fields are mandatory!");

    // this.setState({ editModal: !editModal });
    this.props._updateReaction(react_id, formData2);
    //this.props._fetchReactions();// needs to be optimised
  };

  deleteReaction = (e) => {
    //console.log("delete reaction -----");
    e.preventDefault();
    let { name } = e.target;
    let { react_id, deleteModal } = this.state;
    this.setState({ deleteModal: !deleteModal });
    this.props._removeReaction(react_id);
    this.props._fetchReactions(); //needs optimisation
  };

  onChangeHandler = (name, value) => {
    //console.log("name, value", name, value);
    this.setState({ [name]: value });
  };

  onFileChange = (event) => {
    // Update the state
    //console.log('uploaded file is', event.target.files[0])
    let file = event.target.files[0];
    this.setState({
      content: file,
      file: event.target.files[0].name,
    });
  };

  renderIsActiveColumn = (data) => {
    //   console.log("check for the data======================",this.props)
    return (
      <CustomInput
        type="switch"
        id={Math.random() * 10000}
        readOnly
        checked={data.data.isActive}
      />
    );
  };

  // onEmojiClick = (event, emojiObjectvalue) => {
  //   this.setState({ chosenEmoji: emojiObjectvalue });
  // };

  render() {
    // console.log('this.props allusers', this.props);
    let {
      headsAg,
      modal,
      reactions,
      deleteModal,
      editModal,
      fr_title,
      en_title,
      es_title,
      selectedEmoji,
    } = this.state;
    let {
      reactions: { isFetched },
    } = this.props;
    return (
      <Container>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <h3 className="page-title bb-1 border-gray pb-2">
                  Post Reactions
                </h3>
                <Row className="mt-2">
                  <Col md={12} className="text-right">
                    <Button
                      color="primary"
                      htmlFor="add"
                      onClick={(e) => {
                        this.toggle("modal");
                      }}
                    >
                      Add +
                    </Button>
                  </Col>
                  <Col
                    md={12}
                    style={{ height: "100vh", width: "600px" }}
                    className="ag-theme-material"
                  >
                    <TableComponent
                      rowsData={reactions}
                      headsAg={headsAg}
                      editRecord={this.editRecord}
                      rowSelectable={"multiple"}
                      rowsPerPage={20}
                      isFetched={isFetched}
                      {...this.props}
                      noRecordsText={"No Records Found!"}
                      fetchingRecordsText={"Fetching Reactions..."}
                    />
                    <Col md={12} className="text-right mt-2">
                      {/* <TablePagination onPageChange={this.onPageChange} {...this.state}/> */}
                    </Col>
                  </Col>
                  <Col md={12}>
                    <AddReactions
                      toggle={this.toggle}
                      customClass={"test"}
                      cancelButtonText={"Cancel"}
                      cancelAction={(e) => this.toggle("modal")}
                      confirmAction={(e) => this.createReaction(e)}
                      confirmButtonText={"Add"}
                      modal={modal}
                      modalTitle={"Add New Reaction"}
                    >
                      <Form
                        name="add"
                        encType="multipart/form-data"
                        id="myForm"
                        onSubmit={this.createReaction}
                      >
                        <FormGroup row>
                          <Label htmlFor="enTitle" sm={2}>
                            English Title
                          </Label>
                          <Col sm={10}>
                            <Input
                              type="text"
                              name="en_title"
                              id="en_title"
                              value={en_title}
                              onChange={(e) => {
                                this.onChangeHandler(
                                  e.target.name,
                                  e.target.value
                                );
                              }}
                              required
                              placeholder="Enter English Title"
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label htmlFor="esTitle" sm={2}>
                            Spanish Title
                          </Label>
                          <Col sm={10}>
                            <Input
                              type="text"
                              name="es_title"
                              id="es_title"
                              value={es_title}
                              onChange={(e) => {
                                this.onChangeHandler(
                                  e.target.name,
                                  e.target.value
                                );
                              }}
                              required
                              placeholder="Enter Spanish Title"
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label htmlFor="frTitle" sm={2}>
                            French Title
                          </Label>
                          <Col sm={10}>
                            <Input
                              type="text"
                              name="fr_title"
                              id="fr_title"
                              value={fr_title}
                              onChange={(e) => {
                                this.onChangeHandler(
                                  e.target.name,
                                  e.target.value
                                );
                              }}
                              required
                              placeholder="Enter French Title"
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup>
                          <div>
                            <label>Choose the emoji</label>
                            <input
                              type="file"
                              onChange={this.onFileChange}
                            ></input>
                          </div>
                        </FormGroup>
                        <hr />
                        <div style={{ textAlign: "end" }}>
                          <Button color="primary" type="submit" name="add">
                            Add{" "}
                          </Button>{" "}
                          <Button
                            color="secondary"
                            name="cancel"
                            onClick={(e) => this.toggle("modal")}
                          >
                            {" "}
                            Cancel
                          </Button>
                        </div>
                      </Form>
                    </AddReactions>

                    <AddReactions
                      toggle={this.toggle}
                      customClass={"test"}
                      cancelButtonText={"Cancel"}
                      cancelAction={(e) => this.toggle("editModal")}
                      confirmAction={(e) => this.updateReaction(e)}
                      confirmButtonText={"Edit"}
                      modal={editModal}
                      modalTitle={"Edit Reaction"}
                    >
                      <Form name="add" onSubmit={this.updateReaction}>
                        <FormGroup>
                          <FormGroup row>
                            <Label htmlFor="enTitle" sm={2}>
                              English Title
                            </Label>
                            <Col sm={10}>
                              <Input
                                type="text"
                                name="en_title"
                                id="en_title"
                                value={en_title}
                                onChange={(e) => {
                                  this.onChangeHandler(
                                    e.target.name,
                                    e.target.value
                                  );
                                }}
                                required
                                placeholder="Enter English Title"
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label htmlFor="esTitle" sm={2}>
                              Spanish Title
                            </Label>
                            <Col sm={10}>
                              <Input
                                type="text"
                                name="es_title"
                                id="es_title"
                                value={es_title}
                                onChange={(e) => {
                                  this.onChangeHandler(
                                    e.target.name,
                                    e.target.value
                                  );
                                }}
                                required
                                placeholder="Enter Spanish Title"
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label htmlFor="frTitle" sm={2}>
                              French Title
                            </Label>
                            <Col sm={10}>
                              <Input
                                type="text"
                                name="fr_title"
                                id="fr_title"
                                value={fr_title}
                                onChange={(e) => {
                                  this.onChangeHandler(
                                    e.target.name,
                                    e.target.value
                                  );
                                }}
                                required
                                placeholder="Enter French Title"
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup>
                            <div>
                              <label>Emoji :- </label>
                              {selectedEmoji && selectedEmoji.url && (
                                <img
                                  src={selectedEmoji.url}
                                  height="40"
                                  width="40"
                                  style={{
                                    maxWidth: "40px",
                                    marginLeft: "40px",
                                  }}
                                />
                              )}
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <div>
                              <label>Choose the emoji</label>
                              <input
                                type="file"
                                onChange={this.onFileChange}
                              ></input>
                            </div>
                          </FormGroup>
                        </FormGroup>
                        <hr />
                        <div style={{ textAlign: "end" }}>
                          <Button color="primary" type="submit" name="update">
                            Update
                          </Button>{" "}
                          <Button
                            color="secondary"
                            name="cancel"
                            onClick={(e) => this.toggle("editModal")}
                          >
                            {" "}
                            Cancel
                          </Button>
                        </div>
                      </Form>
                    </AddReactions>

                    <AddReactions
                      toggle={this.toggle}
                      customClass={"test"}
                      cancelButtonText={"Cancel"}
                      cancelAction={(e) => this.toggle("editModal")}
                      confirmAction={(e) => this.deleteReaction(e)}
                      confirmButtonText={"Edit"}
                      modal={deleteModal}
                      modalTitle={"Are you Sure ?"}
                    >
                      <Form name="add" onSubmit={this.deleteReaction}>
                        <div style={{ textAlign: "end" }}>
                          <Button color="primary" type="submit" name="update">
                            Yes
                          </Button>{" "}
                          <Button
                            color="secondary"
                            name="cancel"
                            onClick={(e) => this.toggle("deleteModal")}
                          >
                            {" "}
                            Cancel
                          </Button>
                        </div>
                      </Form>
                    </AddReactions>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default AllReactions;
