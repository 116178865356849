import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button
} from "reactstrap";
import TableComponent from "../Shared/Table/Table";
import DeleteIcon from 'mdi-react/CancelIcon';
import notify from './../../helpers/notificationHelper';

const RemoveBannedEmail = React.lazy(() => import('./RemoveBannedEmail'));
const ListBannedEmail = (props) => {
    const [content, setContent] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(100);
    const [isFetched, setFetched] = useState(false);
    const [emailId, setEmailId] = useState()
    const [modalDelete, setModalDelete] = useState(false);
    const renderAction = (record) => {
        let buttons = <span>
                    <>
                    <DeleteIcon className="cursor-pointer ml-2" size={18} onClick={()=>{ deleteData(record.data) }} />
                    </>
                </span>;   
        return buttons;
    }

    const deleteData = (data) => {
        let { _id } = data;
        setEmailId(_id)
        return setModalDelete(true)
    }

    const renderIndex=(record) => {
        let index = record.rowIndex + 1 ;
        console.log(page);
        if(page > 1){
            index  = (page - 1)*limit + record.rowIndex + 1
        }
        return index;
    }

    const confirmAction = (e) => {
        e.preventDefault();
        props._removeBannedEmail({emailId});
    }

    const toggleRemoveModal = () => {
        return setModalDelete(!modalDelete)
    }

    useEffect(()=>{
        if(props.removeBannedEmail.isFetched && !props.removeBannedEmail.isFetching && !props.removeBannedEmail.err)
        {
            toggleRemoveModal();
            props._getAllBannedEmails()
            notify('success',props.removeBannedEmail.message)
        }

        if(!props.removeBannedEmail.isFetched && props.removeBannedEmail.isFetching && props.removeBannedEmail.err)
        {
            notify('error',props.removeBannedEmail.message)
        }


    },[props.removeBannedEmail])

    useEffect(()=>{
        if(!props.bannedEmails.isFetching && props.bannedEmails.isFetched && props.bannedEmails.bannedEmails !== content ){
            setContent(props.bannedEmails.bannedEmails);
            setFetched(props.bannedEmails.isFetched);
        }

    },[props.bannedEmails])  

    useEffect(() => {
        props._getAllBannedEmails();
    }, [page])

    const headsAg = [
        {headerName: "Id", field: "_id", width:"80", sortable:true, filter:'text', 
            cellRendererFramework : renderIndex ,
            // checkboxSelection : true
        },
        {headerName: "Email", field: "email", width:"200", sortable:true, filter:'text'}
    ]

    if(localStorage.getItem("UserRole") === "superadmin" || localStorage.getItem("UserRole") === "admin"){
        headsAg.push({headerName: "Actions", cellRendererFramework : renderAction, width:"100",  cellStyle : (params) =>{ return {textAlign :  'center'} } })
    }

    useEffect(()=>{
        console.log('props in resources', props)
    })

    return(
    < Container>
      <Row>
        <Col md={12}>
            <Card>
                <CardBody className="bg-white ">
                <h3 className='page-title bb-1 border-gray mb-4'>Banned Emails</h3>
                <Row className="mt-12">
                   { (localStorage.getItem('UserRole') === "superadmin" || localStorage.getItem("UserRole") === "admin") && 
                    <Col md={12} className="text-right">
                            <Button color="primary" href="/add-banned-emails" >Add +</Button>
                    </Col>}
                </Row>
                    <Col md={12} style={{ height: '90vh', width: '1000px' }} className="ag-theme-material">
                    <div style={{height : '100%', width : '100%'}}>
                    <TableComponent 
                                rowsData={ content }
                                headsAg={headsAg}
                                isFetched ={isFetched}
                                rowSelectable={'multiple'} 
                                rowsPerPage={10}
                                checkboxSelection={true}
                                fetchingRecordsText = {'Fetching List...'}
                                noRecordsText = {'No Records Found!'}
                                {...props} 

                            />
                    </div>
                    </Col>  
                    <React.Suspense fallback={''}>
                    <RemoveBannedEmail 
                        modal={modalDelete} 
                        toggle={ toggleRemoveModal }
                        modalTitle={ 'Details' }
                        confirmAction = {confirmAction}
                        CustomClass = { 'test_class'}
                        modalText = {'Are you sure , you want to remove this email?'}
                    />
                    </React.Suspense>
                </CardBody>
            </Card>
        </Col>
      </Row>
     </Container>
    ) 
}

export default ListBannedEmail