import React from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import '../scss/app.scss';
import Router from './Router';

function App() {
    return <Router/>
}

export default App;
