import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import {routes} from './routes';
const UnAuths = (props) => {
      let { checked, isAuthenticated, ...rest } = props
      const elements = routes.map((item, index) => {
          if(item.authRoute) return null
          const { path, exact } = item
          const routeProps = { path, exact }
          return (<Route key={index} {...routeProps} render={ props=> <item.component {...props} />} />)
      })
      //console.log('props unauth',props)
      return(
        <Switch>
          {elements}
        </Switch>
      )
  }

export default UnAuths