// import { ADDADMIN, ERROR, FORGETPASSWORD } from '../actions/ADDADMIN_action';
import { LISTHOSTSERROR, LISTHOSTSREQUEST, LISTHOSTSSUCCESS } from './../actions/actionTypes'
 
const initialState = {
    requestDone: false,
    isFetched: false,
    isFetching : false,
    err : false,
    message:'',
    hosts: [],
    total : 0,
  };
  
  // receiving response sent by action according to type of action
  export default function hostReducers(state = initialState, action) {
      //console.log(action,'action')
      let { payload } = action
    switch (action.type) {
        case LISTHOSTSREQUEST:
          return { 
            requestDone: false,
            isFetched: false,
            isFetching : true,
            err : false,
            message:'',
            hosts: [],
            total : 0
           };
          break;
        case LISTHOSTSSUCCESS:
            //console.log('========in switch', payload.users)
        return { 
            requestDone: true,
            isFetched: true,
            isFetching : false,
            err : false,
            message:'',
            hosts: payload.users,
        };
        break;
  
        case LISTHOSTSERROR:
        return { 
            requestDone: true,
            isFetched: false,
            isFetching : false,
            err : true,
            message: payload.message
        };
        break;
        
      default:
        return state;
    }
  }
