// import { ADDADMIN, ERROR, FORGETPASSWORD } from '../actions/ADDADMIN_action';
import * as Actions from './../actions/actionTypes'
 
const initialState = {
    requestDone: false,
    isFetched: false,
    isFetching : false,
    err : false,
    message:'',
    data: {},
    totalAmount : 0
  };
  
  // receiving response sent by action according to type of action
  export default function donationStatsReducers(state = initialState, action) {
      //console.log(action,'action')
      let { payload } = action
    switch (action.type) {
   
        case Actions.FETCHDONATIONSTATSREQUEST:
          return { 
            requestDone: false,
            isFetched: false,
            isFetching : true,
            err : false,
            message:'',
            data: {},
            totalAmount : 0
           };
          break;
        case Actions.FETCHDONATIONSSTATSSUCCESS:
            console.log('========in switch', payload)
        return { 
            requestDone: true,
            isFetched: true,
            isFetching : false,
            err : false,
            message:'',
            data: payload.data,
            totalAmount : payload.totalamout
        };
        break;
  
        case Actions.FETCHDONATIONSSTATSERROR:
        return { 
            requestDone: true,
            isFetched: false,
            isFetching : false,
            err : true,
            data: {},
            totalAmount : 0
        };
        break;
      default:
        return state;
    }
  }
