import * as Actions from '../actions/actionTypes';

const initialState = {
    isFetching: false,
    fetched: false,
    error:false,
    message : '',
    contentData:[],
    count : 0
  };
  
  // receiving response sent by action according to type of action
  export default function premiumContentListReducer(state = initialState, action) {
      //console.log(action,'action in list');
      let { payload } = action;
    switch (action.type) {
      case Actions.FETCHCONTENTREQUEST:
        return { 
            isFetching: true,
            fetched: false,
            error:false,
            contentData:[],
            message : '',
            count : 0
        };
        break;
  
      case Actions.FETCHCONTENTSUCCESS:
        return { 
            isFetching: false,
            fetched: true,
            error:false,
            message : payload.message,
            contentData: payload.content,
            count : payload.count 
        };
        break;
  
      case Actions.FETCHCONTENTERROR:
        return { 
            isFetching: false,
            fetched: false,
            error:true,
            contentData: payload,
            message : payload.message,
            count : 0

         };
        break;
        break;

      default:
        return state;
    }
  }
  