import React, {PureComponent} from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import TopbarMenuLink from './TopbarMenuLink';
import {Collapse} from 'reactstrap';
import {connect} from 'react-redux';
import {logOutAction} from './../../../redux/actions/loginActions'
const Ava = process.env.PUBLIC_URL + '/img/ava.png';

class TopbarProfile extends PureComponent {
    state = {
      collapse: false
    };
  onLogoutHandler = () => {
    //console.log('logout clicked')
    this.props._logout();
  }
  toggle = ()=> {
    this.setState({collapse: !this.state.collapse});
  }

  render() {
    let {userDetails} = this.props;
    return (
      <div className='topbar__profile'>
        <div className='topbar__avatar' onClick={this.toggle}>
          <img className='topbar__avatar-img' src={Ava} alt='avatar'/>
          <p className='topbar__avatar-name'>{userDetails && userDetails.username}</p>
          <DownIcon className='topbar__avatar-icon'/>
        </div>
        {this.state.collapse && <div className='topbar__back' onClick={this.toggle}/>}
        <Collapse isOpen={this.state.collapse} className='topbar__menu-wrap'>
          <div className='topbar__menu'>
            <TopbarMenuLink title='My Profile' icon='user' path='/account/my-profile'/>
            {/* <TopbarMenuLink title='Calendar' icon='calendar-full' path='/default_pages/calendar'/> */}
            {/* <TopbarMenuLink title='Tasks' icon='list' path='/default_pages/calendar'/> */}
            {/* <TopbarMenuLink title='Inbox' icon='inbox' path='/mail'/> */}
            {/* <div className='topbar__menu-divider'/> */}
            {/* <TopbarMenuLink title='Account Settings' icon='cog' path='/account/my-profile'/> */}
            {/* <TopbarMenuLink title='Lock Screen' icon='lock' path='/lock_screen'/> */}
            <TopbarMenuLink title='Log Out' className="logout-button" icon='exit' path='/login' onclickHandler = {this.onLogoutHandler}/>
          </div>
        </Collapse>
      </div>
    )
  }
}

const mapStateToProps = (state)=>{
  return {
    state : state.login
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    _logOUt : (token)=>{
      dispatch(logOutAction(token))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopbarProfile)