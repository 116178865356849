import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Input } from 'reactstrap';
import TableComponent from './../Shared/Table/Table';
import TablePagination from '../Shared/Table/TablePagination';
import moment from 'moment';

const ListIssueLog = (props) => {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [content, setContent] = useState([]);
    const [total, setTotal] = useState(10);
    const [selected_lang, setSelectedLang] = useState("");
    const [isFetched, setFetched] = useState(false);

    let renderIndex = (record) => {
        let index = record.rowIndex + 1;
        console.log(page);
        if (page > 1) {
            index = (page - 1) * limit + record.rowIndex + 1
        }
        return index;
    }

    useEffect(() => {
        props.fetchLogs(page, limit, selected_lang);
    }, [page, selected_lang])

    let renderDate = (data) => {
        return moment(data.data.createdAt).tz("America/New_York").format('MM-DD-YYYY HH:mm:ss')
    }

    useEffect(() => {
        if (!props.premiumContentIssueLogList.isFetching && props.premiumContentIssueLogList.contentData !== content) {
            setTotal(props.premiumContentIssueLogList.count)
            if (props.premiumContentIssueLogList.contentData.length > 0) {
                props.premiumContentIssueLogList.contentData = props.premiumContentIssueLogList.contentData.map((obj, index) => {
                  return {
                    ...obj,
                    id_number: index + (page - 1) * 10 + 1, 
                    stage: obj.stage.toLowerCase(),
                    error: obj.error.toLowerCase()
                  };
                });
            }
            setContent(props.premiumContentIssueLogList.contentData);
        }
    }, [props.premiumContentIssueLogList])

    let onPageChange = (pagenum) => {
        setPage(pagenum);
        props.fetchLogs(pagenum, limit, selected_lang);
    }

    const onLangChangeHandler = (value) => {
        setPage(1)
        setSelectedLang(value)
    }

    const resetFiter = () =>{
        setPage(1)
        setSelectedLang('')
        props.fetchLogs(1, limit, '');
    }

    const headsAg = [
        { headerName: "Id", field: "id_number", width: "100", sortable: true },
        { headerName: "Device", field: "deviceType", sortable: true },
        { headerName: "Issue", field: "error", sortable: true , tooltipField:"error"},
        { headerName: "Stage", field: "stage", sortable: true },
        { headerName: "Email", field: "user.email", sortable: true },
        { headerName: "Display Name", field: "user.displayname", sortable: true },
        { headerName: "Date", field: "date", sortable: true, cellRendererFramework: renderDate },
    ]

    return (
        <Container style={{ textAlign: '' }}>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardBody className="bg-white ">
                            <Row className='page-title bb-1 border-gray no-gutters'>
                                <Col md={5}>
                                    <h3 >Premium Content Issue Log</h3>
                                </Col>
                                <Col md={3} className="text-right">
                                    <Input type="select" name="selected_lang" onChange={(e) => { onLangChangeHandler(e.target.value) }} value={selected_lang} className="sm">
                                        <option value="" >Select Filter</option>
                                        <option value="f" >Failed - Payment Fail</option>
                                        <option value="s" >Success - Payment done</option>
                                    </Input>
                                   
                                </Col>
                                <Col md={1} className="text-right"> <Button color="primary btn-sm" onClick={()=>resetFiter()}>Reset</Button></Col>
                                <Col md={3} className="text-right">
                                    <Button color="primary" href="/subscribed-content/list" >Back</Button>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col style={{ height: '90vh', width: '600px' }} className="ag-theme-material">
                                    <div style={{ height: '100%', width: '100%' }}>
                                        <TableComponent
                                            rowsData={content}
                                            headsAg={headsAg}
                                            rowSelectable={'multiple'}
                                            rowsPerPage={10}
                                            checkboxSelection={true}
                                            fetchingRecordsText={'Fetching List...'}
                                            noRecordsText={'No Content Found!'}
                                            {...props}

                                        />
                                    </div>
                                </Col>
                                <Col md={12} className="mt-2">
                                    
                                    {/* <nav aria-label="Page navigation example">
                                        <ul class="pagination">
                                            <li class="page-item">
                                                <a class="page-link" href="#" aria-label="Previous">
                                                    <span aria-hidden="true">&laquo;</span>
                                                    <span class="sr-only">Previous</span>
                                                </a>
                                            </li>
                                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                                            <li class="page-item">
                                                <a class="page-link" href="#" aria-label="Next">
                                                    <span aria-hidden="true">&raquo;</span>
                                                    <span class="sr-only">Next</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav> */}
                                    <TablePagination onPageChange={onPageChange} page={page} limit={limit} total={total} />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default ListIssueLog;