import React from 'react';
import { Card, CardBody, Container, Row, Col, Label, Input, Form, Button, FormGroup, FormText } from 'reactstrap';
import notify from './../../helpers/notificationHelper';
import {SPOKEN_LANGUAG_ARRAY, SPOKEN_LANGUAGE_OBJECT} from './../../inc/constants';
import * as _ from 'lodash';
export default class TermsAndConditions extends React.Component {

    state = {
        isFetching : false,
        content : '',
        language : '',
        listings : [],
        numPages : null,
        pageNumber : 1  ,
        file : ''
    }
    componentDidMount(){
        this.props._fetchPremiumContent();
    }

    static getDerivedStateFromProps(props, state){
        console.log('props derived states', props);
        console.log('state derived states', state);
        
        if(props.premiumContent.isFetching !== state.isFetching){
            return {
                isFetching : props.premiumContent.isFetching
            }
        }
        return null
    }

    onClickHandler = (name, value) => {
        this.setState({
            [name] : value
        })
    }

    onChangeHandler = (e) => {
        console.log('e', e.target.files[0].name  );
        let file = e.target.files[0];
        let ext = file.type.split("/")[1];

        if(ext !== 'pdf') return notify('error', 'Only pdf format is allowed')
        this.setState({
            content : file,
            file : e.target.files[0].name
        })
    }


    toggle = () => {
        this.setState((prevState) => {
            return {
                modal : !prevState.modal
            }
        })
    }
    
    componentDidUpdate(prevProps,prevstate){
        let { premiumContent, listTermsConditions } = this.props;
        if(prevProps.premiumContent !== premiumContent){
            if(!premiumContent.isFetching && premiumContent.contentCreated && !premiumContent.error){
                notify('success', premiumContent.message);
                return this.setState({file : ''}, ()=>{
                    this.props._fetchPremiumContent();
                })
            }
            if(!premiumContent.isFetching && !premiumContent.contentCreated && premiumContent.error){
                notify('error', premiumContent.message)
            }
        }
        if(prevProps.listTermsConditions !== listTermsConditions){
            if(!listTermsConditions.isFetching && listTermsConditions.isFetched && !listTermsConditions.error){
                this.setState({
                    listings : listTermsConditions.data
                })
            }
            if(!listTermsConditions.isFetching && !listTermsConditions.isFetched && listTermsConditions.error){
                notify('error', listTermsConditions.message)
            }
        }
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }
      
     // On file upload (click the upload button) 
    onSubmitHandler = () => { 
        let { content, language } = this.state;
        // console.log('content', content.has('File'));
        console.log('content', content);
        if(content === '') return notify('error', 'Please upload a non-empty file !')
        if(language === '') return notify('error', 'Please select a language !')
        // Create an object of formData 
        const formData = new FormData(); 
       console.log('this.state.content', this.state.content);
        // Update the formData object 
        formData.append( 
          "myFile", 
          content, 
          content.name 
        ); 
        formData.append( 
            "language", 
            language 
          ); 
       
        // Details of the uploaded file 
        console.log(content); 
        this.props._postPremiumContent(formData);
        // Request made to the backend api 
        // Send formData object 
        // axios.post("api/uploadfile", formData); 
      }; 
      loaderror= (e) => {
        console.log('e', e)
      }
    render(){
        let { isFetching, language, file, listings } = this.state;
        console.log('props', this.state)
        return (
            <Container  >
                <Row>
                    <Col md={12}>
                        <Card>
                            <CardBody className="bg-white ">
                                <h3 className='page-title bb-1 border-gray mb-4'>Terms And Conditions</h3>
                                <Row className="mt-3 bb-1 border-gray ">
                                    <Col md={12}>
                                        <Form onSubmit ={this.onSubmitHandler}>
                                                <Row form className="mb-3">

                                                    <Col md={6} sm={12} className="">
                                                        <FormGroup>
                                                            <Label htmlFor="exampleFile" >File</Label>
                                                            <Input type="file" name="file" id="exampleFile" onChange = { (e) => {this.onChangeHandler(e)} } />
                                                            <FormText color="muted">
                                                                Only .pdf is allowed !
                                                            </FormText>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6} sm={12} className="mt-3">
                                                        <Label className="text-muted">Language:</Label>
                                                        <Input type="select" name="language" value={language}  required 
                                                            onChange = { (e) => { this.onClickHandler( e.target.name, e.target.value  ) }}
                                                            disabled = {isFetching}
                                                            value = {language}
                                                            placeholder = "Enter Content" >
                                                                <option value=''>Select Language</option>
                                                                {SPOKEN_LANGUAG_ARRAY && SPOKEN_LANGUAG_ARRAY.map((l)=>{
                                                                    return <option value={l.value}>{l.name}</option>
                                                                })}
                                                        </Input>
                                                    </Col>
                                                    <Col md={12} sm={12} className="text-right mt-3">
                                                        {/* <Label className="text-muted">Enter title:</Label> */}
                                                        {/* <Button color="primary" disabled={ isFetching ? true : false } type="button" onClick={ this.onSubmitHandler }>{ 'View Existing' }</Button> */}
                                                        <Button color="primary" disabled={ isFetching ? true : false } type="button" onClick={ this.onSubmitHandler }>{ isFetching ? 'Saving...' : 'Submit' }</Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                    </Col>
                                </Row>
                                <Row className="mt-3 bb-1 border-gray ">
                                    <Col md={12}>
                                        <h3 className='page-title  mb-4'>View</h3>
                                    </Col>
                                    <Col md={12}>
                                        <Form onSubmit ={this.onSubmitHandler}>
                                                <Row form className="mb-3">

                                                    {listings && listings.length > 0 && listings.map((l)=>{
                                                        if(!l.configs.language) return
                                                        return (
                                                            <Col md={6} sm={12} className="mt-3">
                                                                <Label className="text-muted">Language: <strong>{ SPOKEN_LANGUAGE_OBJECT[l.configs.language] }</strong></Label>
                                                                {/* <PDFViewer
                                                                    document={{
                                                                        url: `${l.configs.text}`,
                                                                    }}
                                                                /> */}
                                                                <a href={`${l.configs.text}`} target='_blank' className="ml-3" type="button" >View</a>
                                                            </Col>

                                                        )
                                                    })}
                                                  
                                                </Row>
                                            </Form>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>            
        )
    }
}