import { wrappedFetch, wrappedGet } from './urlFuncs';
import { LIST_REPORTEE_USERS_URL, LIST_REPORTED_CONTENT_URL, CHANGE_REPORTED_CONTENT_STATUS } from './../inc/constants';
import { API_URL } from './../inc/config';

export const fetchReportedContent = (page, limit, contentBy, byStatus) =>{
  return wrappedGet(`${API_URL}${LIST_REPORTED_CONTENT_URL}?page=${page}&limit=${limit}&contentBy=${contentBy}&status=${byStatus}`, 'GET');
}

export const fetchReporteeUsers = (recordId) =>{
  return wrappedGet(`${API_URL}${LIST_REPORTEE_USERS_URL}${recordId}`, 'GET');
}

//change status of a reported content
export const rejectReportedContent = (recordId, data) =>{
  return wrappedFetch(`${API_URL}${CHANGE_REPORTED_CONTENT_STATUS}${recordId}`, 'PUT', data);
}
