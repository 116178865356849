import { 
    FETCHREPORTEDCONTENTERROR,
    FETCHREPORTEDCONTENTREQUEST,
    FETCHREPORTEDCONTENTSUCCESS,
    FETCHREPORTEEUSERSREQUEST,
    FETCHREPORTEEUSERSSUCCESS,
    FETCHREPORTEEUSERSERROR,
    UPDATEREPORTEDCONTENTERROR,
    UPDATEREPORTEDCONTENTSUCCESS,
    UPDATEREPORTEDCONTENTREQUEST
  } from './actionTypes';
  import * as reportedContent from './../../api/reportedContent';
  
  
  
//   export function  createNotificationsRequest() {
//       return {
//         type: CREATENOTIFICATIONSREQUEST,
//         payload:{}
//       };
//     }
     
//     export function createNotificationsSuccess(data) {
//       return {
//         type: CREATENOTIFICATIONSSUCCESS,
//         payload : data,
//       };
//     }
     
//     export function  createNotificationsError(message) {
//         return {
//           type: CREATENOTIFICATIONSERROR,
//           payload : { message },
//         };
//     }


  //fetch reported content list
    export function  fetchNotificationsRequest() {
      return {
        type: FETCHREPORTEDCONTENTREQUEST,
        payload:{}
      };
    }
     
    export function fetchNotificationsSuccess(data) {
      return {
        type: FETCHREPORTEDCONTENTSUCCESS,
        payload : data,
      };
    }
     
    export function fetchNotificationsError(message) {
        return {
          type: FETCHREPORTEDCONTENTERROR,
          payload : { message },
        };
    }

  //fetch user list
  export function  fetchReporteeUsersRequest() {
    return {
      type: FETCHREPORTEEUSERSREQUEST,
      payload:{}
    };
  }
   
  export function fetchReporteeUsersSuccess(data) {
    return {
      type: FETCHREPORTEEUSERSSUCCESS,
      payload : data,
    };
  }
   
  export function fetchReporteeUsersError(message) {
      return {
        type: FETCHREPORTEEUSERSERROR,
        payload : { message },
      };
  }

    // export function  deleteNotificationsRequest() {
    //   return {
    //     type: DELETENOTIFICATIONSREQUEST,
    //     payload:{}
    //   };
    // }
     
    // export function deleteNotificationsSuccess(data) {
    //   return {
    //     type: DELETENOTIFICATIONSSUCCESS,
    //     payload : data,
    //   };
    // }
     
    // export function deleteNotificationsError(message) {
    //     return {
    //       type: DELETENOTIFICATIONSERROR,
    //       payload : { message },
    //     };
    // }

    export function  updateReportedContentRequest() {
      return {
        type: UPDATEREPORTEDCONTENTREQUEST,
        payload:{}
      };
    }
     
    export function updateReportedContentSuccess(data) {
      return {
        type: UPDATEREPORTEDCONTENTSUCCESS,
        payload : data,
      };
    }
     
    export function updateReportedContentError(message) {
        return {
          type: UPDATEREPORTEDCONTENTERROR,
          payload : { message },
        };
    }
  
//   export const createNotifications = (data) =>{
//     return (dispatch) => {
//         dispatch(createNotificationsRequest());
//         console.log('notifications data', data);
//         notificationFuncs.craeteNotifications(data).then((res)=>{
//             console.log('responseData*****************************', res)
//             if(res.status === 200) return dispatch(createNotificationsSuccess(res.data));
//             if(res.status === 400) return dispatch(createNotificationsError(res.data.message));
//             if(res.status === 401) return dispatch(createNotificationsError(res.data.message));
//             if(res.status === 409) return dispatch(createNotificationsError(res.data.message));
  
//         }).catch((err)=>{
//             console.log('err', err)
//             return dispatch(createNotificationsError(err.message));
//         })
//     }
//   }
  
  //fetch all reported content
  export const fetchContent = (page, limit, contentBy, byStatus) =>{
    return (dispatch) => {
        dispatch(fetchNotificationsRequest());
        reportedContent.fetchReportedContent(page, limit, contentBy, byStatus).then((res)=>{
            console.log('responseData*****************************', res)
            if(res.status === 200) return dispatch(fetchNotificationsSuccess(res.data));
            if(res.status === 400) return dispatch(fetchNotificationsError(res.data.message));
            if(res.status === 401) return dispatch(fetchNotificationsError(res.data.message));
            if(res.status === 409) return dispatch(fetchNotificationsError(res.data.message));
  
        }).catch((err)=>{
            console.log('err', err)
            return dispatch(fetchNotificationsError(err.message));
        })
    }
  }

  export const fetchuserList = (recordId) =>{
    return (dispatch) => {
        dispatch(fetchReporteeUsersRequest());
        reportedContent.fetchReporteeUsers(recordId).then((res)=>{
            console.log('responseData*****************************', res)
            if(res.status === 200) return dispatch(fetchReporteeUsersSuccess(res.data));
            if(res.status === 400) return dispatch(fetchReporteeUsersError(res.data.message));
            if(res.status === 401) return dispatch(fetchReporteeUsersError(res.data.message));
            if(res.status === 409) return dispatch(fetchReporteeUsersError(res.data.message));
  
        }).catch((err)=>{
            console.log('err', err)
            return dispatch(fetchReporteeUsersError(err.message));
        })
    }
  }
  
//   //action creator to delete notifications
//   export const deleteNotifications = (id) =>{
//     return (dispatch) => {
//       dispatch(fetchNotificationsRequest());
//       notificationFuncs.removeNotifications(id).then((res)=>{
//         console.log('responseData*****************************', res)
//         if(res.status === 200) return dispatch(deleteNotificationsSuccess(res.data));
//         if(res.status === 400) return dispatch(deleteNotificationsError(res.data.message));
//         if(res.status === 401) return dispatch(deleteNotificationsError(res.data.message));
//         if(res.status === 409) return dispatch(deleteNotificationsError(res.data.message));
        
//       }).catch((err)=>{
//         console.log('err', err)
//         return dispatch(deleteNotificationsError(err.message));
//       })
//     }
//   }
  
  //action creator to udpate notifications
  export const updateReportedContent = (id, data) =>{
    return (dispatch) => {
        dispatch(updateReportedContentRequest());
        reportedContent.rejectReportedContent(id, data).then((res)=>{
            console.log('responseData*****************************', res)
            if(res.status === 200) return dispatch(updateReportedContentSuccess(res.data));
            if(res.status === 400) return dispatch(updateReportedContentError(res.data.message));
            if(res.status === 401) return dispatch(updateReportedContentError(res.data.message));
            if(res.status === 409) return dispatch(updateReportedContentError(res.data.message));
  
        }).catch((err)=>{
            console.log('err', err)
            return dispatch(updateReportedContentError(err.message));
        })
    }
  }
  