import { connect } from "react-redux";
import PostCategories from '../../components/PostCategories/PostCategory';
import { createCategory } from '../../redux/actions/postCategoryActions';

const mapStateToProps = state => {
    return {
        addPostCategory: state.addPostCategory
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        _createCategory : (data) => {
            return dispatch(createCategory(data))
        }
    }
}

const PostCategoriesContainer = connect(mapStateToProps, mapDispatchToProps)(
    PostCategories
);
 
export default PostCategoriesContainer;