import { connect } from "react-redux";
import ListIssueLog from "../../components/SubscribedContent/ListIssueLog";
import { fetchPremiumContentIssueLog} from '../../redux/actions/premiumContentActiions';
 
const mapStateToProps = state => {
  //console.log('state in container', state);
  return {
    premiumContentIssueLogList : state.premiumContentIssueLogList,
  };
};
 
const mapDispatchToProps = (dispatch) => {
  return {
    fetchLogs: (page, skip, filter) => {
      return dispatch(fetchPremiumContentIssueLog(page, skip, filter));
    }
  };
};
 
const ListSubscribedContentIssueLogContainer = connect(mapStateToProps, mapDispatchToProps)(
    ListIssueLog
);
 
export default ListSubscribedContentIssueLogContainer;