import { wrappedFetch, wrappedGet } from './urlFuncs';
import {GET_ALL_ADMIN_USERS, GET_ALL_APP_USERS, CHECK_LOGIN_URL,BLOCK_USER_URL,SEARCH_USER_URL, HOST_USER_URL, DELETE_ADMIN_USER, DELETE_APP_USER, MAKE_TEST_USER_URL} from './../inc/constants';
import {API_URL} from './../inc/config';


export const getAllAdminUsers = (page, limit) =>{
  return wrappedGet(`${API_URL}${GET_ALL_ADMIN_USERS}?page=${page}&limit=${limit}`, 'GET');
}

export const getAllAppUsers = (page, limit, active, subscribed, tester, blocked, sortBy, join_date_sort, sort, sortOrder) =>{
  //console.log('page, limit, active, subscribed', page, limit, active, subscribed, sortBy, join_date_sort)
  let url = `${API_URL}${GET_ALL_APP_USERS}?page=${page}&limit=${limit}&sortby=${sortBy}&join_date=${join_date_sort}`;
  if(active) url = `${url}&active=${active}`;
  if(subscribed) url = `${url}&subscribed=${subscribed}`;
  if(tester) url = `${url}&tester=${tester}`
  if(blocked) url = `${url}&blocked=${blocked}`
  if(sort && sortOrder) url = `${url}&sort=${sort}&sortOrder=${sortOrder}`
  return wrappedGet(url, 'GET');
}

export const checkLogin = () =>{
  return wrappedFetch(`${API_URL}${CHECK_LOGIN_URL}`,'POST',{});
    
}

export const blockAppUser = (data) =>{
  //console.log(`${API_URL}${BLOCK_USER_URL}`,'POST',data)
  return wrappedFetch(`${API_URL}${BLOCK_USER_URL}`,'POST',data);
}

export const hostUser = (data) =>{
  console.log(`${API_URL}${BLOCK_USER_URL}`,'POST',data)
  return wrappedFetch(`${API_URL}${HOST_USER_URL}`,'POST',data);
}


export const searchAppUser = (searchInfo) =>{
  let { searchKey, page, limit, active, subscribed, searchBy } = searchInfo
  //console.log(`${API_URL}${SEARCH_USER_URL}?searchkey=${searchKey}&page=${page}&limit=${limit}`,'GET');
  let url = `${API_URL}${SEARCH_USER_URL}?searchkey=${searchKey}&page=${page}&limit=${limit}&searchBy=${searchBy}`
  if(active) url = `${url}&active=${active}`;
  if(subscribed) url = `${url}&subscribed=${subscribed}`;
  return wrappedGet(url,'GET');
}

export const deleteAppUser = (userId) =>{
  //console.log(`${API_URL}${DELETE_ADMIN_USER}userId`,'DELETE')
  return wrappedFetch(`${API_URL}${DELETE_APP_USER}${userId}`,'DELETE');
}



export const makeUserTester = (data) =>{
  //console.log(`${API_URL}${DELETE_ADMIN_USER}userId`,'DELETE')
  return wrappedFetch(`${API_URL}${MAKE_TEST_USER_URL}`,'PUT', data);
}

// let logout = (token) =>{
//   let endPoint = '/logout';
//   let bParams =  {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//       'Auth-type' : 'JWT',
//       'Auth' : token 
//     }
//   }
//   return wrappedApi(endPoint, bParams)
//   .then(res => res.json())
// }
