import { DateRange } from '@material-ui/icons';
import React , { useState, useEffect } from 'react';
import { Card, CardBody, Container, Row, Col, Label, Input, Form, Button, FormGroup, CustomInput } from 'reactstrap';
import notify from '../../helpers/notificationHelper';

const PostCategories = (props) => {
    const [name, setName] = useState('');
    const [visible, setVisible] = useState(true)
    const [language, setLanguage] = useState('')

    let onChangeHandler = (nme,value) => {
        //console.log(nme,value)
        if(nme == 'name'){
            setName(value)
        }

        if(nme == 'language'){
            setLanguage(value)
        }

        if(nme == 'visible'){
            var check = document.getElementById('visible').checked
            setVisible(check)
        }
    }

    let onSubmitHandler = (e) => {
        e.preventDefault();
        // console.log('name', name)
        // console.log('visible', visible)
        // console.log('language', language)

        if(!language){
            notify('error','Language is mandatory')   
        }else{
            let data = {
                name,
                isVisible : visible,
                language
            }

            props._createCategory(data)
        }
    }

    useEffect(()=>{
        if(props.addPostCategory.isFetched && !props.addPostCategory.isFetching && !props.addPostCategory.err)
        {
            notify('success',props.addPostCategory.message)
        }

        if(!props.addPostCategory.isFetched && props.addPostCategory.isFetching && props.addPostCategory.err)
        {
            notify('error',props.addPostCategory.message)
        }


    },[props.addPostCategory])
    return (
        <Container>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardBody className="bg-white ">
                        <h3 className='page-title bb-1 border-gray mb-4'>Category</h3>
                        <Row className="mt-12">
                            <Col md={12} className="text-right">
                                    <Button color="primary" href="/al-anon-post-categories" >Back</Button>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col md={12}>
                            <Form onSubmit ={onSubmitHandler}>
                                <FormGroup row >
                                    <Label  sm={5}>Name</Label>
                                    <Col sm={7}>
                                        <Input type="text" value = {name} name="name"  required placeholder="Enter Name" 
                                        onChange = { (e) => { onChangeHandler( e.target.name, e.target.value  )} } />
                                        </Col>
                                </FormGroup> 
                                <FormGroup row >
                                    <Label  sm={5}>Language</Label>
                                    <Col sm={7}>
                                        <Input type="select" required name="language" onChange = { (e) => { onChangeHandler( e.target.name, e.target.value  )} }>
                                        <option> -Select a langauge-</option>
                                        <option value='en'>English</option>
                                        <option value='es'>Spanish</option>
                                        <option value='fr'>French</option>
                                        </Input>
                                    </Col>
                                </FormGroup> 
                                <FormGroup row>
                                <Label md={3} sm={3}>Visible</Label> 
                                    <Col md={3} sm={{offset:3,size : 1}} style={{marginLeft : 185}}>
                                         <Input type="checkbox" id="visible" name="visible" 
                                            value = {visible}   
                                            defaultChecked = "true" 
                                            onChange = { (e) => { onChangeHandler( e.target.name, e.target.value  )} }    
                                        /> 
                                          
                                    </Col>
                                </FormGroup>   
                                 <Col md={12} sm={12} className="mt-3">
                                    <FormGroup check>
                                        <Button color="primary" type="submit" className="mt-3 text-right">Submit</Button>                                                                                                                                                                                                                        
                                    </FormGroup >
                                </Col>
                             </Form>   
                            </Col>
                        </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default PostCategories;