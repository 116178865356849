import React from "react";
import ReactDOM from "react-dom";
import CSVReader from "react-csv-reader";
// import "./styles.css";


const parseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
};

const reader =(props) => {
    let { handleUpload } = props;
    return(
        <div className="">
            <CSVReader
                cssClass="react-csv-input text-center"
                label="Select CSV with the premium data "
                onFileLoaded={handleUpload}
                parserOptions={parseOptions}
            />
        </div>
    )
};


export default reader