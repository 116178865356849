import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { PAGINATION_MAX_PAGE_LIMIT } from '../../../inc/constants';
// import { useState } from 'react';

export default class TablePagination extends React.PureComponent{
    state = {
        initialPage : 1,
        currentPage : 1, 
        totalPages : 1 
    }
    static getDerivedStateFromProps(props, state){
        // console.clear();
        //console.log('Math.ceil(this.props.total/this.props.limit)',Math.ceil(props.total/props.limit))
        return {
            totalPages : Math.ceil(props.total/props.limit) 
        }
    }
    onNextPageHandler = (e,p, last) => {
        e.preventDefault();
        const { totalPages } = this.state;
        if(p===0 || p > totalPages) return
        this.setState((prevState)=>{
            let data = { currentPage : p } 
            if(last && totalPages> PAGINATION_MAX_PAGE_LIMIT){ data['initialPage'] = prevState.totalPages-PAGINATION_MAX_PAGE_LIMIT }
            return data
        }, (states) => {
            this.props.onPageChange(p)
        })
    }

    
    renderPagination =  () => {
        
        let { total, page, limit, onPageChange } = this.props;
        let pages = Math.ceil(total/limit);
        // pages = 200;
        let { initialPage } = this.state;
        //console.log('pages---------', this.props);
        // console.log('total---------', pages);
        let paginations = [];
        const changeIntialPage = (p) => {
            this.setState({ initialPage : p })
        }
        let check = 0
        // if(pages === 0) paginations.push(
        // <PaginationItem key={1}>
        //     <PaginationLink first >
        //         {1}
        //     </PaginationLink>
        // </PaginationItem>) 
        let { currentPage, totalPages } = this.state;
        for(let i = initialPage; i <= pages ;i++){
            check++
            //console.log('page---------', i);
            let data = i;
            paginations.push(
                <PaginationItem key={i} active={page === i}>
                    <PaginationLink onClick={(e) => { this.onNextPageHandler(e,data) }} first = {data === 1} >
                        {data}
                    </PaginationLink>
                </PaginationItem>
            )
        if(check > PAGINATION_MAX_PAGE_LIMIT) {
            if(i===pages) break
            paginations.push(
                <PaginationItem key={i+1} active={page === i+1}>
                    <PaginationLink onClick={() => { changeIntialPage(data) } } >
                        {`...`}
                    </PaginationLink>
                </PaginationItem>
            )
            break;
        }
        }
        return(paginations)
     
    
    }


    render(){
        let { total, page, limit } = this.props;
        let { currentPage, totalPages } = this.state;
        const pages = Math.ceil(total/limit);
        console.log(page)
        return(
            <Pagination aria-label="Page navigation">
                {/* <PaginationItem active={page > 1}>
                    <PaginationLink  first href="#" onClick={ (e)=> { this.onNextPageHandler(e, 1) } } />
                </PaginationItem> */}
                <PaginationItem active={page > 1}>
                    <PaginationLink previous href="#" onClick={ (e)=> { this.onNextPageHandler(e, currentPage - 1) } }/>
                </PaginationItem>
                    { this.renderPagination() }
                <PaginationItem active={page < totalPages}>
                    <PaginationLink  onClick={ (e)=> { this.onNextPageHandler(e, currentPage+1) } } next href="#" />
                </PaginationItem>
                {/* <PaginationItem active={page < totalPages}>
                    <PaginationLink last href="#" onClick={ (e)=> { this.onNextPageHandler(e, pages, true) } }/>
                </PaginationItem> */}
            </Pagination>
        )
    }
}