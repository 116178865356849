import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Row, Col, Label, Input, FormGroup } from 'reactstrap';
import { DAYS_ARRAY, MEETING_TYPES, MEETING_TYPE_ONE_TIME, SPOKEN_LANGUAG_ARRAY_MEETING } from './../../inc/constants';
import Multiselect from './../Shared/MultiSelectCommon';
import "../../App.css";

const EditMeetingModal = (props) => {
    const {
        buttonLabel,
        className,
        toggle,
        modal,
        func,
        confirmAction,
        onChangeHandler,
        modalTitle,
        modalText,
        modalFor,
        onDaysChange,
        categories,
        onChangeCategory,
        onChangeLanguage,
        filterCatTitle,
        isFetching,
        onCatChange,
        est_time,
        title, days, time, type, duration, description, createdAt, host, al_host_id,
        wsoId, categoriesMeet, category, spoken_language, spoken_language_array, spoken_language_full,
        display_date,
        meet_date,
        meet_type_e,
        test_meet,mySelectedLang,
        weeklyTimeDurations,
        monDuration,
        tueDuration,
        wedDuration,
        thuDuration,friDuration,satDuration,sunDuration,
        updateWeeklyTimeDurations
    } = props;

    const [enableMon, setEnableMon] = useState(props.monDuration ? false : true);
    const [enableTue, setEnableTue] = useState(props.tueDuration ? false : true);
    const [enableWed, setEnableWed] = useState(props.wedDuration ? false : true);
    const [enableThr, setEnableThr] = useState(props.thuDuration ? false : true);
    const [enableFri, setEnableFri] = useState(props.friDuration ? false : true);
    const [enableSat, setEnableSat] = useState(props.satDuration ? false : true);
    const [enableSun, setEnableSun] = useState(props.sunDuration ? false : true);
    const [monDurationNew, setMonDurationNew] = useState(props.monDuration);
    const [tueDurationNew, setTueDurationNew] = useState(props.tueDuration);
    const [wedDurationNew, setWedDurationNew] = useState(props.wedDuration);
    const [thuDurationNew, setThuDurationNew] = useState(props.thuDuration);
    const [friDurationNew, setFriDurationNew] = useState(props.friDuration);
    const [satDurationNew, setSatDurationNew] = useState(props.satDuration);
    const [sunDurationNew, setSunDurationNew] = useState(props.sunDuration);
    const [daysAnother, setDays] = useState(props.days);

    const onDaysChangeEdit = (e) => {
        let { value, checked }= e.target;
        let newDays = JSON.parse(JSON.stringify(daysAnother)) 
        let index = newDays.indexOf(value); 
        if(index > -1 ) {
            newDays.splice(index, 1)
        }else{
            newDays.push(value);
        }
        let dayName, dayDuration;
        switch (value) {
            case "monday" :
                setEnableMon(checked ? false : true); 
                setMonDurationNew(checked ? monDurationNew: "" )
                dayName = "monDuration"
                dayDuration = checked ? monDurationNew: ""
            break; 
            case "tuesday" :
                setEnableTue(checked ? false : true); 
                setTueDurationNew(checked ? tueDurationNew: "" )
                dayName = "tueDuration"
                dayDuration = checked ? tueDurationNew: ""
            break;
            case "wednesday" :
                setEnableWed(checked ? false : true); 
                setWedDurationNew(checked ? wedDurationNew: "" )
                dayName = "wedDuration"
                dayDuration = checked ? wedDurationNew: ""
            break;
            case "thursday" :
                setEnableThr(checked ? false : true); 
                setThuDurationNew(checked ? thuDurationNew: "" )
                dayName = "thuDuration"
                dayDuration = checked ? thuDurationNew: ""
            break;
            case "friday" :
                setEnableFri(checked ? false : true); 
                setFriDurationNew(checked ? friDurationNew: "" )
                dayName = "friDuratio"
                dayDuration = checked ? friDurationNew: ""
            break;
            case "saturday" :
                setEnableSat(checked ? false : true); 
                setSatDurationNew(checked ? satDurationNew: "" )
                dayName = "satDuration"
                dayDuration = checked ? satDurationNew: ""
            break;
            case "sunday" :
                setEnableSun(checked ? false : true); 
                setSunDurationNew(checked ? sunDurationNew: "" )
                dayName = "sunDuration"
                dayDuration = checked ? sunDurationNew: ""
            break;
        }
        updateWeeklyTimeDurations(newDays,dayName, dayDuration)
        setDays(newDays)
        return

    }
    
    const onChangeDurationHandler = (e) => {
        let { name , value } = e.target;

        let durations = [];
        let dayName, dayDuration;
        let newDays = JSON.parse(JSON.stringify(daysAnother));
        console.log("value", value)
        switch (name) {
            case "monDuration" : 
                setEnableMon( enableMon ); 
                setMonDurationNew(value); 
                //if(value) { 
                   // dayDuration =  value
               // }
            break; 
            case "tueDuration" : 
                setEnableTue( enableTue ); 
                setTueDurationNew(value); 
                //if(value) { 
                   // dayDuration = value 
               // }
            break; 
            case "wedDuration" : 
                setEnableWed( enableWed ); 
                setWedDurationNew(value); 
                //if(value) { 
                   // dayDuration = value 
               // }
            break; 
            case "thuDuration" : 
                setEnableThr( enableThr ); 
                setThuDurationNew(value); 
                //if(value) { 
                    //dayDuration = value 
                //} 
            break; 
            case "friDuration" : 
                setEnableFri( enableFri ); 
                setFriDurationNew(value); 
                //if(value) { 
                   // dayDuration = value 
               // }
            break; 
            case "satDuration" : 
                setEnableSat( enableSat ); 
                setSatDurationNew(value); 
                //if(value) { 
                    //dayDuration = value 
                //} 
            break; 
            case "sunDuration" : 
                setEnableSun( enableSun ); 
                setSunDurationNew(value); 
                //if(value) { 
                    //dayDuration = value 
                //} 
            break; 
        }
        dayName = name;
        dayDuration = value;
        updateWeeklyTimeDurations(newDays,dayName, dayDuration )
        return
    }

    const renderDays = (type) => {
        let allDays = DAYS_ARRAY.map((day, i) => {
            return (
                <FormGroup check inline key={i}>
                    {/*console.log({type})*/}
                    {(type==="weekly" || type==="daily") && weeklyTimeDurations ?
                    <Label check className='margin-Top-Check'>
                        <Input type="checkbox" value={day} checked={ daysAnother.includes(day)} onChange ={ onDaysChangeEdit } />  {`${day.charAt(0).toUpperCase()}${day.slice(1)}`}
                    </Label>
                        :
                     <Label check>
                        <Input type="checkbox" value={day} checked={type!=='weekly' && type!=="daily" && days.includes(day) || type==='daily'} onChange ={ onDaysChange } disabled={type === 'daily'} />  {`${day.charAt(0).toUpperCase()}${day.slice(1)}`}
                    </Label> }

                    {/*<Label check>
                        <Input type="checkbox" value={day} checked={type === 'weekly' && days.includes(day) || type === 'daily'} onChange={onDaysChange} disabled={type === 'daily'} />  {`${day.charAt(0).toUpperCase()}${day.slice(1)}`}
                    </Label> */}
                </FormGroup>
            )
        })
        return allDays
    }

    let ListItem = ({ item }) => (
        <span>
            <span>{item.english_title}</span>
            {/* {" " + item.lastName} */}
        </span>
    );

    //const [spokenLanguageArray, setSpokenLanguageArray] = useState([]);
    var multiSelect=[];
        multiSelect=SPOKEN_LANGUAG_ARRAY_MEETING.map((val)=>
            {
                return(
                       {
                           code: val.code,
                           name : val.name,
                       }
                )
            }
        )
    const ee = spoken_language.split(",")
    return (
        <div style={{ textAlign: 'center' }}>
            {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
            
            <Modal isOpen={modal} fade={true} toggle={toggle} keyboard={false} backdrop={false}>
                <span>
                    <ModalHeader toggle={toggle}>{`Edit Meeting`}</ModalHeader>
                </span>
                <ModalBody>
                    <div>
                        <Form onSubmit={() => { confirmAction() }}>
                            <Row form className="mb-3">
                                <Col md={12} sm={12}>
                                    <Label className="text-muted">Title:</Label>
                                    <Input type="text" name="title" disabled={isFetching} value={title}
                                        onChange={onChangeHandler} required
                                        placeholder="Enter Title"
                                    />
                                </Col>
                            </Row>
                            <Row form className="mb-3">
                                <Col md={12} sm={12}>
                                    <Label className="text-muted">Hosts:</Label>
                                    <Input type="text" name="host" value={host[0].email.mail} disabled={isFetching} placeholder="Host" />
                                </Col>
                            </Row>
                            <Row form className="mb-3">
                                <Col md={6} sm={6}>
                                    <Label htmlFor="typeselect">Type</Label>
                                    <Input type="select" name="type" onChange={onChangeHandler} disabled={isFetching} value={type} id="typeselect">
                                        <option value="">Select Meeting Type</option>
                                        <option value="daily">Daily</option>
                                        <option value="weekly">Weekly</option>
                                        <option value="one_time">One Time</option>
                                    </Input>
                                </Col>
                                <Col md={6} sm={6}>
                                    <Label className="text-muted">WSO ID:</Label>
                                    <Input type="wso_id" name="wsoId" value={wsoId} disabled={isFetching}
                                        onChange={onChangeHandler} required
                                        placeholder="Enter WSO ID"
                                    />
                                </Col>
                            </Row>
                            <Row form className="mb-3">
                                <Col md={6} sm={12}>
                                    <Label htmlFor="typeselect">Start Date</Label>
                                    <Input type="date" onChange={onChangeHandler} value={display_date} name="display_date" />
                                </Col>
                                <Col md={6} sm={12}>
                                    <Label className="text-muted">Meeting Date: </Label>
                                    <Input type="date" disabled={type === MEETING_TYPE_ONE_TIME ? false : true} name="meet_date" value={meet_date}
                                        onChange={onChangeHandler} />
                                </Col>

                            </Row>
                            {(type==="weekly" || type==="daily")?
                                <Row>
                                    <Col md={6} sm={12}>
                                    <Label className="text-muted">Time: <span className="text-muted">{`EST :- ${est_time}`}</span></Label>
                                        <Input type="time" name="time" value ={time} disabled={isFetching}
                                            onChange={ onChangeHandler } required
                                            placeholder = "Enter Country Code" 
                                        />
                                    </Col>
                                    <Col md={6} sm={12}>
                                        <Label className="text-muted">Attendees :</Label>
                                        <Input type="select" name="meet_type_e" id="typeselect" value={meet_type_e} onChange={onChangeHandler}>
                                            <option value={''} >Select</option>
                                            {MEETING_TYPES && MEETING_TYPES.length > 0 && (
                                                MEETING_TYPES.map((m) => {
                                                    return (
                                                        <option key={m.type}  value={m.type}>{m.name}</option>
                                                    )
                                                })
                                            )}
                                        </Input>
                                    </Col>
                                </Row> :
                                <Row>
                                    <Col md={4} sm={6}>
                                    <Label className="text-muted">Time: <span className="text-muted">{`EST :- ${est_time}`}</span></Label>
                                        <Input type="time" name="time" value ={time} disabled={isFetching}
                                            onChange={ onChangeHandler } required
                                            placeholder = "Enter Country Code" 
                                        />
                                    </Col>
                                    <Col md={4} sm={12}>
                                        <Label className="text-muted">Duration:</Label>
                                        <Input type="number" name="duration" value ={duration} disabled={isFetching}
                                            onChange={ onChangeHandler } required
                                            placeholder = "Enter Duration" 
                                        />
                                    </Col>
                                    <Col md={4} sm={12}>
                                        <Label className="text-muted">Attendees :</Label>
                                        <Input type="select" name="meet_type_e" id="typeselect" value={meet_type_e} onChange={onChangeHandler}>
                                            <option value={''} >Select</option>
                                            {MEETING_TYPES && MEETING_TYPES.length > 0 && (
                                                MEETING_TYPES.map((m) => {
                                                    return (
                                                        <option key={m.type}  value={m.type}>{m.name}</option>
                                                    )
                                                })
                                            )}
                                        </Input>
                                    </Col>
                                </Row>}
                            {/*<Row>
                                <Col md={4} sm={6}>
                                    <Label className="text-muted">Time: <span className="text-muted">{`EST :- ${est_time}`}</span></Label>
                                    <Input type="time" name="time" value={time} disabled={isFetching}
                                        onChange={onChangeHandler} required
                                        placeholder="Enter Country Code"
                                    />
                                </Col>
                                <Col md={4} sm={12}>
                                    <Label className="text-muted">Duration:</Label>
                                    <Input type="number" name="duration" value={duration} disabled={isFetching}
                                        onChange={onChangeHandler} required
                                        placeholder="Enter Duration"
                                    />
                                </Col>
                                <Col md={4} sm={12}>
                                    <Label className="text-muted">Attendees :</Label>
                                    <Input type="select" name="meet_type_e" id="typeselect" value={meet_type_e} onChange={onChangeHandler}>
                                        <option value={''} >Select</option>
                                        {MEETING_TYPES && MEETING_TYPES.length > 0 && (
                                            MEETING_TYPES.map((m) => {
                                                return (
                                                    <option key={m.type} value={m.type}>{m.name}</option>
                                                )
                                            })
                                        )}
                                    </Input>
                                </Col>
                            </Row>*/}
                            <Row>
                                <Col md={12} sm={12}>
                                    <Label htmlFor="typeselect">Spoken Language</Label>
                                    <Multiselect
                                        data={multiSelect}
                                        onChangeHandler={onChangeLanguage}
                                        valueField='code'
                                        defaultValue={ee}
                                        textField="name"
                                    />
                                    {/* <Input type="select" name="spoken_language" id="typeselect" value={ spoken_language } onChange={onChangeHandler}>
                                <option value="" ></option>
                                {SPOKEN_LANGUAG_ARRAY_MEETING && SPOKEN_LANGUAG_ARRAY_MEETING.length > 0 && (
                                    SPOKEN_LANGUAG_ARRAY_MEETING.map((lang) => {
                                        return (
                                            <option  key={lang.name} value={lang.code}>{lang.name}</option>
                                        )
                                    })
                                )}
                            </Input> */}
                                </Col>
                                <Col md={12} sm={12}>
                                    <Label className="text-muted margin-Top">Default Duration</Label>
                                    <Input type="number" name="duration" 
                                        value={duration}
                                        required
                                        placeholder="Enter Duration: (in mins)"
                                    />
                                </Col>
                            </Row>
                            <Row>
                            {
                                (type==="weekly" || type==="daily") && weeklyTimeDurations ?  
                                <Col lg={12} md={12} sm={12}>
                                    <FormGroup>
                                        <Row>
                                            <Col lg={4} md={4} sm={4}>
                                                <Label htmlFor="">Days</Label>
                                                <div >
                                                    {renderDays(type)}
                                                </div>
                                            </Col>
                                            <Col lg={8} md={8} sm={8}>
                                                <Label htmlFor="">Duration: (in mins)</Label>
                                                <Input type='number'
                                                    name="monDuration"
                                                    value={monDurationNew}
                                                    className='margin-Top w-75 forResp text-center'
                                                    placeholder='Enter Duration'
                                                    bsSize="sm"
                                                    required
                                                    onChange={ onChangeDurationHandler }
                                                    disabled={enableMon}  
                                                    />
                                                <Input type='number'
                                                    name="tueDuration"
                                                    value={tueDurationNew}
                                                    className='margin-Top w-75 forResp text-center'
                                                    placeholder='Enter Duration'
                                                    bsSize="sm"
                                                    onChange={ onChangeDurationHandler }
                                                    disabled={enableTue}  
                                                    />
                                                <Input type='number'
                                                    name="wedDuration"
                                                    value={wedDurationNew}
                                                    className='margin-Top w-75 forResp text-center'
                                                    placeholder='Enter Duration'
                                                    bsSize="sm"
                                                    onChange={ onChangeDurationHandler }
                                                    disabled={enableWed}
                                                    />
                                                <Input type='number'
                                                    name="thuDuration"
                                                    value={thuDurationNew}
                                                    className='margin-Top w-75 forResp text-center'
                                                    placeholder='Enter Duration'
                                                    bsSize="sm"
                                                    onChange={ onChangeDurationHandler }
                                                    disabled={enableThr} 
                                                    />
                                                <Input type='number'
                                                    name="friDuration"
                                                    value={friDurationNew}
                                                    className='margin-Top w-75 forResp text-center'
                                                    placeholder='Enter Duration'
                                                    bsSize="sm"
                                                    onChange={ onChangeDurationHandler }
                                                    disabled={enableFri} 
                                                    />
                                                <Input type='number'
                                                    name="satDuration"
                                                    value={satDurationNew}
                                                    className='margin-Top w-75 forResp text-center'
                                                    placeholder='Enter Duration'
                                                    bsSize="sm"
                                                    onChange={ onChangeDurationHandler }
                                                    disabled={enableSat} 
                                                    />
                                                <Input type='number'
                                                    name="sunDuration"
                                                    value={sunDurationNew}
                                                    className='margin-Top w-75 forResp text-center'
                                                    placeholder='Enter Duration'
                                                    bsSize="sm"
                                                    onChange={ onChangeDurationHandler }
                                                    disabled={enableSun} 
                                                    />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                              </Col>  
                                : 
                                    <Col md={12} sm={12}>
                                        <Label htmlFor="">Days</Label>
                                        <div>
                                            {renderDays(type)}
                                        </div>
                                    </Col>
                            }
                                
                            </Row>
                            <Row form className="mb-3">
                                <Col md={12} sm={12}>
                                    <Label className="text-muted">Select Categories:</Label>
                                    <Multiselect
                                        data={categories}
                                        onChangeHandler={onChangeCategory}
                                        textField='english_title'
                                        valueField='id'
                                        filter={filterCatTitle}
                                        value={categoriesMeet}
                                        defaultValue={category}
                                        itemComponent={ListItem}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} sm={12} className={'pt-4'}>
                                    <FormGroup check >
                                        <Label check>
                                            <Input type="checkbox" name="test_meet" defaultChecked={test_meet} onChange={onChangeHandler} />
                                            Test Meeting
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="text-right mb-2">
                                <Col md={12} sm={12}>
                                    <Input type="textarea" name="description" value={description} disabled={isFetching}
                                        onChange={onChangeHandler}
                                        placeholder="Enter Description"
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" name={modalFor} onClick={(e) => confirmAction(e)}>{isFetching ? 'Updating...' : 'Update'}</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Close</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default EditMeetingModal;