import {combineReducers, createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
// import {reducer as reduxFormReducer} from 'redux-form';
import {
  sidebarReducer,
  themeReducer,
  customizerReducer,
  loginReducer,
  categoryReducer,
  addAdminReducer,
  adminsListReducer,
  forgotPasswordReducer,
  updateProfileReducer,
  usersListReducer,
  blockUserReducer,
  categoryListReducer,
  hostsReducer,
  meetingReducers,
  listMeetingsReducers,
  deleteMeetingReducers,
  premiumContentReducers,
  premiumContentListReducer,
  virtualTourReducers,
  fetchVirtualTourReducers,
  notificationsReducers,
  dashboardStatsReducers,
  deleteAdminReducers,
  createFaqReducers,
  fetchFaqReducers,
  updateFaqReducers,
  getFAQDetailsReducer,
  deleteFAQReducers,
  updateAdminReducer,
  updateFeelingReducer,
  deleteFeelingReducer,
  deletePremiumContentReducer,
  updatePremiumContentReducers,
  donationReducer,
  listTermsConditions,
  listNotificationsReducers,
  deleteUserReducer,
  updateReactionReducer,
  listLanguagesReducer,
  createConfigurationsReducer,
  fetchConfigrationsReducers,
  deleteNotificationsReducers,
  updateNotificationsReducer,
  requestReportReducers,
  getLangFAQReducer,
  updateFAQSequnceReducer,
  reportContentReducer,
  listReporteeUsers,
  updateReportedContent,
  updatePostCategoryReducer,
  addPostCategoryReducer,
  makeAllPostCategoryVisibleReducer,
  userTrackingListReducer,
  fetchResourcesReducer,
  uploadResourcesReducer,
  updateResourceReducer,
  deleteResourceReducer,
  allFeelingsReducers,
  allReactionsReduces,
  listBannedEmail,
  addBannedEmailReducer,
  removeBannedEmailReducer,
  listFinderMeetings,
  getFinderMeeting,
  updateFinderMeetReducer,
  uploadFinderMeetingsReducer,
  translateFinderMeetsReducer,
  translateSingleMeetReducer,
  premiumContentIssueLogListReducer
} from '../redux/reducers/index';

const reducer = combineReducers({
  // form: reduxFormReducer, // mounted under "form",
  theme: themeReducer,
  sidebar: sidebarReducer,
  customizer: customizerReducer,
  login: loginReducer,
  category:categoryReducer,
  addAdmin:addAdminReducer,
  adminsList:adminsListReducer,
  forgotPassword : forgotPasswordReducer,
  updateProfile : updateProfileReducer,
  appUsersList : usersListReducer,
  appUsersBlock : blockUserReducer,
  categories : categoryListReducer,
  feelings : allFeelingsReducers,
  reactions : allReactionsReduces,
  hosts : hostsReducer,
  createMeeting : meetingReducers,
  listMeetings : listMeetingsReducers,
  deleteMeeting : deleteMeetingReducers,
  premiumContent : premiumContentReducers,
  premiumContentList : premiumContentListReducer,
  virtualTour : virtualTourReducers,
  fetchVirtualTour : fetchVirtualTourReducers,
  notifications : notificationsReducers,
  dashboardStats : dashboardStatsReducers,
  deleteAdmin : deleteAdminReducers,
  createFAQ : createFaqReducers,
  fetchFAQ : fetchFaqReducers,
  updateFAQ : updateFaqReducers,
  FAQDetails : getFAQDetailsReducer,
  deleteFAQ : deleteFAQReducers,
  updateAdminS: updateAdminReducer,
  updateFeeling : updateFeelingReducer,
  deleteFeeling : deleteFeelingReducer  ,
  deleteContent : deletePremiumContentReducer,
  updateContent : updatePremiumContentReducers,
  donationList : donationReducer,
  listTermsConditions : listTermsConditions,
  listNotifications :listNotificationsReducers,
  deleteAppUserReducer : deleteUserReducer,
  updateReaction : updateReactionReducer,
  listLanguages : listLanguagesReducer,
  createConfig : createConfigurationsReducer,
  fetchConfig : fetchConfigrationsReducers,
  deleteNotification : deleteNotificationsReducers,
  updateNotification : updateNotificationsReducer,
  requestReport : requestReportReducers,
  getLangFAQ: getLangFAQReducer,
  updateFAQSequnce : updateFAQSequnceReducer,
  reportContent: reportContentReducer,
  listReporteeUsers : listReporteeUsers,
  updateReportedContent : updateReportedContent,
  updatePostCategory : updatePostCategoryReducer,
  makeAllPostCategoryVisible : makeAllPostCategoryVisibleReducer,
  addPostCategory : addPostCategoryReducer,
  userTrackingList : userTrackingListReducer,
  fetchResources : fetchResourcesReducer,
  uploadResources : uploadResourcesReducer,
  updateResource : updateResourceReducer,
  deleteResource : deleteResourceReducer,
  bannedEmails : listBannedEmail,
  addBannedEmail : addBannedEmailReducer,
  removeBannedEmail : removeBannedEmailReducer,
  finderMeetings : listFinderMeetings,
  finderMeeting : getFinderMeeting,
  updateFinderMeetReducer,
  uploadFinderMeetings:  uploadFinderMeetingsReducer,
  translateFinderMeets : translateFinderMeetsReducer,
  translateSingleMeet : translateSingleMeetReducer,
  premiumContentIssueLogList : premiumContentIssueLogListReducer
});
const store =  createStore(reducer, applyMiddleware(thunk));

export default store;
