import React from 'react';
import { Container, Row, Col, Card, CardBody, Button, Form, FormGroup, Label, Input,CustomInput } from 'reactstrap';
import TableComponent from './../Shared/Table/Table';
import AddFeelingModal from './AddFeelingModal';
import Picker from 'emoji-picker-react'
import notification from '../../helpers/notificationHelper';
import PenIcon from 'mdi-react/PencilIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';

class AllFeelings extends React.Component{
    state={
        headsAg : [],
        modal : false,
        title :'',
        slug : '',
        feelings : [],
        total : 0,
        limit : 10,
        page :1,
        chosenEmoji : '',
        en_title : '',
        es_title : '',
        fr_title : '',
        modalType : '',
        feel_id:'',
        editModal : false,
        deleteModal : false

    }

    componentDidMount(){
        this.props._fetchFeelings();
        let {feelings} = this.props.feelings
        let headsAg = [
            {headerName: "Sr. No", field: "i", width:"80", sortable:true, filter:'text', 
                cellRendererFramework : this.renderIndex 
            },
            {headerName: "English Title", field: "en_title", suppressSizeToFit: true },
            {headerName: "French Title", field: "fr_title", suppressSizeToFit: true  },
            {headerName: "Spanish Title", field: "es_title", suppressSizeToFit: true },
            {headerName: "Emoji", field: "emoji", suppressSizeToFit: true },
            {headerName: "Actions", 
            cellRendererFramework : this.renderAction,
            cellStyle : (params) =>{
              return {textAlign :  'center'}
            }
          },
        ]
        this.setState({  headsAg,feelings })
    }
    renderAction = (record) => {
        let buttons = <span>
                    {/* <EyeIcon onClick={()=>{ this.setData(record.data, 'modal') } } size={'18px'} className="cursor-pointer"/> */}
                    <PenIcon className="cursor-pointer ml-2" size={18} onClick={()=>{ this.setData(record.data, 'editModal') } }/>
                    <DeleteIcon className="cursor-pointer ml-2" size={18} onClick={()=>{ this.setData(record.data, 'deleteModal') } }/>
                </span>;
        return buttons;
    }

    setData = (data, type) => {
        let { _id, es_title, en_title, fr_title, emoji } = data;
        this.setState({
            es_title,
            en_title,
            fr_title,
            chosenEmoji : emoji,
            feel_id : _id
        }, () => {
            this.toggle(type);
        })
    }

    renderIndex=(record) => {
      return record.rowIndex + 1;
    }

    editRecord = (record) => {
        let id = record.id;
        //console.log('record', record)
    }

    getSelectedRows = () => {
        const selectedNodes = this.gridApi.getSelectedNodes()
        const selectedData = selectedNodes.map( node => node.data )
        const selectedDataStringPresentation = selectedData.map( node => node.make + ' ' + node.model).join(', ')
        //alert(`Selected nodes: ${selectedDataStringPresentation}`)
    }

    renderGetDeleteColumn = () =>{
      return <div>Delete</div> 
    }

    static getDerivedStateFromProps(props, state){
        if(props.feelings.feelings && props.feelings.feelings !== state.feelings && !props.feelings.isFetching && props.feelings.isFetched){
            let feels =  props.feelings.feelings.map( feel => feel.en_title && feel ).filter( o => o !== undefined)
            return {
                feelings : feels,
                total : props.feelings.feelings.length
            }
        }
        return {
            categories : [],
            total : 0
        }
    }

    componentDidUpdate(prevProps, prevState){
        let { addFeelings, updateFeeling, deleteFeeling } = this.props;
        if(addFeelings && addFeelings !== prevProps.addFeelings){
            //console.log('going in updated props - 1');
            if(addFeelings && !addFeelings.catCreateError && !addFeelings.creating){
                notification('succes', 'Category Created!')
                return  this.props._fetchFeelings();
            }
            if(addFeelings && addFeelings.catCreateError && !addFeelings.creating){
                //console.log('going in updated props - 2');
                return  notification('err', addFeelings.message)
            }
            
        }
        if(updateFeeling && updateFeeling !== prevProps.updateFeeling){
            //console.log('going in updated props - 1');
            if(updateFeeling && updateFeeling.isFetched && !updateFeeling.err && !updateFeeling.isFetching){
                notification('succes', updateFeeling.message );
                this.props._fetchFeelings();
                return  this.toggle('editModal');
            }
            if(updateFeeling && updateFeeling.err && !updateFeeling.isFetched && !updateFeeling.isFetching){
                //console.log('going in updated props - 2');
                return notification('err', updateFeeling.message)
            }
    
        }
        if(deleteFeeling && deleteFeeling !== prevProps.deleteFeeling){
            //console.log('going in updated props - 1');
            if(deleteFeeling && deleteFeeling.isFetched && !deleteFeeling.err && !deleteFeeling.isFetching){
                notification('succes', deleteFeeling.message );
                this.props._fetchFeelings();
                return  this.toggle('deleteModal');
            }
            if(deleteFeeling && deleteFeeling.err && !deleteFeeling.isFetched && !deleteFeeling.isFetching){
                //console.log('going in updated props - 2');
                return notification('err', deleteFeeling.message)
            }
            
        }
    }

    toggle = (type) => {
        if(typeof type !== 'string') {
            return this.setState({
                modal : false,
                editModal : false,
                deleteModal : false,
            })
        }
        this.setState((prevStates) => {
            return({
                [type] : !prevStates[type],
            }) 
        })
    }

    createFeeling = (e) => {
        e.preventDefault();
        let { name } = e.target
        let { en_title, es_title, fr_title, chosenEmoji, modal } = this.state
        if(name == 'add'){
            if(!(en_title && es_title && fr_title && chosenEmoji)) return notification('err', 'All Fields are mandatory!')
            
            if(! (en_title && es_title && fr_title) ){
                return this.setState({ modal:modal })
            }else{
            this.setState({ modal: !modal })
            //console.log( 'modal', modal )
            return this.props._addFeelings({
                en_title, 
                es_title, 
                fr_title,
                emoji : chosenEmoji.unified
            })
            }
        }
    }

    updateFeeling = (e) => {
        //console.log('update feeling -----')
        e.preventDefault();
        let { name } = e.target
        let { feel_id, en_title, es_title, fr_title, chosenEmoji, modal } = this.state
        if(!(en_title && es_title && fr_title && chosenEmoji)) return notification('err', 'All Fields are mandatory!')
        let data = {
            en_title, es_title, fr_title , emoji : chosenEmoji.unified || chosenEmoji
        }
        this.props._updateFeeling(feel_id, data);
    }

    deleteFeeling = (e) => {
        //console.log('update feeling -----')
        e.preventDefault();
        let { name } = e.target
        let { feel_id } = this.state
        this.props._deleteFeeling(feel_id);
    }

    onChangeHandler= (name, value) => {
      //console.log('name, value', name, value);
      this.setState({ [name] : value })
    }

    renderIsActiveColumn = (data) =>{
    //   console.log("check for the data======================",this.props)
      return <CustomInput type="switch" id={Math.random()*10000} readOnly checked={data.data.isActive} />
    }

    onEmojiClick = (event, emojiObjectvalue) => {
        this.setState({ chosenEmoji : emojiObjectvalue })
    }

    render(){
        let { headsAg, modal, feelings, chosenEmoji, en_title, es_title, fr_title, deleteModal, editModal } = this.state;
        let { feelings : { isFetched } } = this.props;
        return(
            <Container>
                <Row>
                    <Col md={12} >
                    <Card>
                            <CardBody>
                            <h3 className='page-title bb-1 border-gray pb-2'>Post Feelings</h3>
                            <Row className="mt-2">
                                <Col md={12} className="text-right">
                                        <Button color="primary" htmlFor="add" onClick={(e) => { this.toggle('modal') }}>Add +</Button>
                                </Col>
                                <Col md={12} style={{ height: '100vh', width: '600px' }} className="ag-theme-material">
                                        <TableComponent 
                                            rowsData={ feelings } 
                                            headsAg={headsAg}
                                            editRecord={this.editRecord} 
                                            rowSelectable={'multiple'} 
                                            rowsPerPage={20}
                                            isFetched = {isFetched} 
                                            {...this.props} 
                                            noRecordsText = {'No Records Found!'}
                                            fetchingRecordsText = {'Fetching Feelings...'}
                                        />
                                <Col md={12} className="text-right mt-2">
                                    {/* <TablePagination onPageChange={this.onPageChange} {...this.state}/> */}
                                </Col>
                                </Col>
                                <Col md={12}>
                                      <AddFeelingModal
                                        toggle={this.toggle}
                                        customClass = {'test'}
                                        cancelButtonText = {'Cancel'}
                                        cancelAction = {(e)=>this.toggle('modal')}
                                        confirmAction={(e)=>this.createFeeling(e)}
                                        confirmButtonText={'Add'}
                                        modal={modal}
                                        modalTitle={'Add New Feeling'}
                                      >
                                    <Form name = "add" onSubmit={this.createFeeling}>
                                            <FormGroup row >
                                                <Label htmlFor="enTitle" sm={2}>English Title</Label>
                                                <Col sm={10}>
                                                  <Input type="text" name="en_title" id="en_title" value={en_title}  onChange={(e) => {this.onChangeHandler(e.target.name,e.target.value)}} required placeholder="Enter English Title" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label htmlFor="esTitle" sm={2}>Spanish Title</Label>
                                                <Col sm={10}>
                                                  <Input type="text" name="es_title" id="es_title" value={es_title} onChange={(e) => {this.onChangeHandler(e.target.name,e.target.value)}} required placeholder="Enter Spanish Title" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label htmlFor="frTitle" sm={2}>French Title</Label>
                                                <Col sm={10}>
                                                  <Input type="text" name="fr_title" id="fr_title" value={fr_title} onChange={(e) => {this.onChangeHandler(e.target.name,e.target.value)}} required placeholder="Enter French Title" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup>
                                                <div>
                                                {
                                                    chosenEmoji 
                                                        ? (<span>You chose: <span style={{ 'fontSize' : '40px' }}>{chosenEmoji.emoji}</span></span>)
                                                        : <span>No emoji Chosen</span>
                                        
                                                }
                                                    <Picker className="emoji-picker-feelings" onEmojiClick={this.onEmojiClick}/>
                                                </div>
                                            </FormGroup>
                                                <hr />
                                            <div style={{textAlign:'end'}}>
                                                <Button color="primary" type="submit" name = 'add'  >Add </Button>{' '}
                                                <Button color="secondary"  name = 'cancel' onClick={(e)=>this.toggle('modal')}> Cancel</Button>
                                            </div>
                                    </Form>
                                      </AddFeelingModal>

                                        <AddFeelingModal
                                            toggle={this.toggle}
                                            customClass = {'test'}
                                            cancelButtonText = {'Cancel'}
                                            cancelAction = {(e)=>this.toggle('editModal')}
                                            confirmAction={(e)=>this.updateFeeling(e)}
                                            confirmButtonText={'Edit'}
                                            modal={editModal}
                                            modalTitle={'Edit Feeling'}
                                        >
                                            <Form name = "add" onSubmit={this.updateFeeling}>
                                                    <FormGroup row >
                                                        <Label htmlFor="enTitle" sm={2}>English Title</Label>
                                                        <Col sm={10}>
                                                        <Input type="text" name="en_title" id="en_title" value={en_title}  onChange={(e) => {this.onChangeHandler(e.target.name,e.target.value)}} required placeholder="Enter English Title" />
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label htmlFor="esTitle" sm={2}>Spanish Title</Label>
                                                        <Col sm={10}>
                                                        <Input type="text" name="es_title" id="es_title" value={es_title} onChange={(e) => {this.onChangeHandler(e.target.name,e.target.value)}} required placeholder="Enter Spanish Title" />
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label htmlFor="frTitle" sm={2}>French Title</Label>
                                                        <Col sm={10}>
                                                        <Input type="text" name="fr_title" id="fr_title" value={fr_title} onChange={(e) => {this.onChangeHandler(e.target.name,e.target.value)}} required placeholder="Enter French Title" />
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <div>
                                                            {
                                                                chosenEmoji && chosenEmoji.emoji
                                                                    ? (<span>You chose: <span style={{ 'fontSize' : '40px' }}>{chosenEmoji.emoji}</span></span>)
                                                                    : (<span>You chose: <span style={{ 'fontSize' : '40px' }}>{chosenEmoji}</span></span>)
                                                    
                                                            }
                                                            <Picker className="emoji-picker-feelings" onEmojiClick={this.onEmojiClick}/>
                                                        </div>
                                                    </FormGroup>
                                                        <hr />
                                                    <div style={{textAlign:'end'}}>
                                                        <Button color="primary" type="submit" name = 'update'  >Update</Button>{' '}
                                                        <Button color="secondary"  name = 'cancel' onClick={(e)=>this.toggle('editModal')}> Cancel</Button>
                                                    </div>
                                            </Form>
                                        </AddFeelingModal>

                                        <AddFeelingModal
                                            toggle={this.toggle}
                                            customClass = {'test'}
                                            cancelButtonText = {'Cancel'}
                                            cancelAction = {(e)=>this.toggle('editModal')}
                                            confirmAction={(e)=>this.deleteFeeling(e)}
                                            confirmButtonText={'Edit'}
                                            modal={deleteModal}
                                            modalTitle={'Are you Sure ?'}
                                        >
                                            <Form name = "add" onSubmit={this.deleteFeeling}>
                                                <div style={{textAlign:'end'}}>
                                                    <Button color="primary" type="submit" name = 'update'  >Yes</Button>{' '}
                                                    <Button color="secondary"  name = 'cancel' onClick={(e)=>this.toggle('deleteModal')}> Cancel</Button>
                                                </div>
                                            </Form>
                                        </AddFeelingModal>
                                    </Col>
                             </Row>   
                            </CardBody>
                    </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default AllFeelings