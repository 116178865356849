import React from 'react';
import { Card, CardBody, Container, Row, Col, Label, Input, Form, Button, FormGroup, FormText } from 'reactstrap';
import notify from './../../helpers/notificationHelper';
import {SPOKEN_LANGUAG_ARRAY, SPOKEN_LANGUAGE_OBJECT} from './../../inc/constants';
import * as _ from 'lodash';

export default class ReportsExports extends React.Component {

    state = {
        isFetching : false,
        from_date : '',
        to_date : '',
        creation_from : '',
        creation_to : '',
        contribution_from : '',
        contribution_to : ''
    }

    static getDerivedStateFromProps(props, state){
        console.log('props derived states', props);
        console.log('state derived states', state);
        
        if(props.requestReport.isFetching !== state.isFetching){
            return {
                isFetching : props.requestReport.isFetching
            }
        }
        return null
    }

    onClickHandler = (name, value) => {
        this.setState({
            [name] : value
        })
    }

    onChangeHandler = (name, value) => {
        console.log('name', name);
        console.log('value', value);
        this.setState({ [name] : value })
    }


    toggle = () => {
        this.setState((prevState) => {
            return {
                modal : !prevState.modal
            }
        })
    }
    
    componentDidUpdate(prevProps,prevstate){
        let { requestReport  } = this.props;
        if(prevProps.requestReport !== requestReport){
            if(!requestReport.isFetching && requestReport.contentCreated && !requestReport.error){
                notify('success', requestReport.message);
                return this.setState({
                    // isFetching : false,
                    from_date : '',
                    to_date : '',
                    creation_from : '',
                    creation_to : '',
                    contribution_from : '',
                    contribution_to : ''
                })
                }
            }
            if(!requestReport.isFetching && !requestReport.contentCreated && requestReport.error){
                notify('error', requestReport.message)
            }
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }
      
     // On file upload (click the upload button) 
    onSubmitHandler = (type) => { 

        let { from_date, to_date, contribution_to, contribution_from, creation_to, creation_from } = this.state;
        if( type === 'app_users' ){
            if(from_date === '' || to_date === '') return notify('error', 'Please select a valid date range !')
            this.reuqestUserReport();
        }else if( type === 'meetings' ){
            if(creation_from === '' || creation_to === '') return notify('error', 'Please select a valid date range !')
            this.reuqestMeetingsReport();
        }else if( type === 'contributions' ){
            if(contribution_from === '' || contribution_to === '') return notify('error', 'Please select a valid date range !')
            this.reuqestContributionsReport();

        }
    }; 

    reuqestUserReport(){
        let { from_date, to_date } = this.state;
        console.log('from', from_date)
        this.props._exportAppUsers(from_date, to_date);
    }

    reuqestMeetingsReport(){
        let { creation_from, creation_to } = this.state;
        // console.log('from', from_date)
        this.props._exportMeetings( creation_from, creation_to );
    }

    reuqestContributionsReport(){
        let { contribution_from, contribution_to } = this.state;
        // console.log('from', from_date)
        this.props._exportContributions( contribution_from, contribution_to );
    }

    loaderror= (e) => {
        console.log('e', e)
    }
    render(){
        let { isFetching, language, from_date, to_date, contribution_from, contribution_to, creation_from, creation_to } = this.state;
        console.log('props', this.state)
        return (
            <Container  >
                <Row>
                    <Col md={12}>
                        <Card>
                            <CardBody className="bg-white ">
                                <h3 className='page-title bb-1 border-gray mb-4'>App Stats</h3>
                                <Row className="mt-3 bb-1 border-gray ">
                                    <Col md={12} sm={12} className="mb-2 bb-1 border-gray">
                                        <Form onSubmit ={this.onSubmitHandler}>
                                                <Row form className="mb-3">

                                                    <Col md={12} sm={12} className="mb-2">
                                                        <h4>App Users</h4>
                                                    </Col>
                                                    <Col md={12} sm={12} className="">
                                                        <p>Select Joining Date Range</p>
                                                    </Col>
                                                    <Col md={{'offset': 0 , 'size' : 3}} className="text-right">
                                                        <Form className="text-left">
                                                            <FormGroup>
                                                                <Label>From</Label>
                                                                <Input type="date" name="from_date" value={ from_date } onChange={ (e) => {this.onChangeHandler(e.target.name, e.target.value)} } />
                                                            </FormGroup>
                                                        </Form>
                                                    </Col>
                                                    <Col md={{'offset': 0 , 'size' : 3}} className="text-right">
                                                        <Form className="text-left">
                                                            <FormGroup>
                                                                <Label>To</Label>
                                                                <Input type="date" name="to_date" value={ to_date } onChange={ (e) => {this.onChangeHandler(e.target.name, e.target.value)} } />
                                                            </FormGroup>
                                                        </Form>
                                                    </Col>
                                                    <Col md={{'offset': 0 , 'size' : 2}} className="text-right">
                                                        <Form className="text-center">
                                                            <FormGroup>
                                                                {/* <Button type="button" color="primary" onClick = { ()=> { onApplyFilter() }}>Apply</Button> */}
                                                            </FormGroup>
                                                        </Form>
                                                    </Col>
                                                    <Col md={12} sm={12} className="text-right mt-3">
                                                        {/* <Label className="text-muted">Enter title:</Label> */}
                                                        {/* <Button color="primary" disabled={ isFetching ? true : false } type="button" onClick={ this.onSubmitHandler }>{ 'View Existing' }</Button> */}
                                                        <Button color="primary" disabled={ isFetching ? true : false } type="button" onClick={ (e) => { this.onSubmitHandler('app_users') } }>{ isFetching ? 'Requesting...' : 'Export' }</Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                    </Col>
                                    <Col md={12} sm={12} className="mb-2 bb-1 border-gray pt-2">
                                        <Form onSubmit ={this.onSubmitHandler}>
                                                <Row form className="mb-3">

                                                    <Col md={12} sm={12} className="mb-2">
                                                        <h4>Meetings</h4>
                                                    </Col>
                                                    <Col md={12} sm={12} className="">
                                                        <p>Select Meeting Creation Date Range</p>
                                                    </Col>
                                                    <Col md={{'offset': 0 , 'size' : 3}} className="text-right">
                                                        <Form className="text-left">
                                                            <FormGroup>
                                                                <Label>From</Label>
                                                                <Input type="date" name="creation_from" value={ creation_from } onChange={ (e) => {this.onChangeHandler(e.target.name, e.target.value)} } />
                                                            </FormGroup>
                                                        </Form>
                                                    </Col>
                                                    <Col md={{'offset': 0 , 'size' : 3}} className="text-right">
                                                        <Form className="text-left">
                                                            <FormGroup>
                                                                <Label>To</Label>
                                                                <Input type="date" name="creation_to" value={ creation_to } onChange={ (e) => {this.onChangeHandler(e.target.name, e.target.value)} } />
                                                            </FormGroup>
                                                        </Form>
                                                    </Col>
                                                    <Col md={{'offset': 0 , 'size' : 2}} className="text-right">
                                                        <Form className="text-center">
                                                            <FormGroup>
                                                                {/* <Button type="button" color="primary" onClick = { ()=> { onApplyFilter() }}>Apply</Button> */}
                                                            </FormGroup>
                                                        </Form>
                                                    </Col>
                                                    <Col md={12} sm={12} className="text-right mt-3">
                                                        {/* <Label className="text-muted">Enter title:</Label> */}
                                                        {/* <Button color="primary" disabled={ isFetching ? true : false } type="button" onClick={ this.onSubmitHandler }>{ 'View Existing' }</Button> */}
                                                        <Button color="primary" disabled={ isFetching ? true : false } type="button" onClick={ () => { this.onSubmitHandler('meetings') } }>{ isFetching ? 'Requesting...' : 'Export' }</Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                    </Col>
                                    <Col md={12} sm={12} className="mb-2 pt-2">
                                        <Form onSubmit ={this.onSubmitHandler}>
                                                <Row form className="mb-3">

                                                    <Col md={12} sm={12} className="mb-2">
                                                        <h4>Contributions</h4>
                                                    </Col>
                                                    <Col md={12} sm={12} className="">
                                                        <p>Select Contributions Date Range</p>
                                                    </Col>
                                                    <Col md={{'offset': 0 , 'size' : 3}} className="text-right">
                                                        <Form className="text-left">
                                                            <FormGroup>
                                                                <Label>From</Label>
                                                                <Input type="date" name="contribution_from" value={ contribution_from } onChange={ (e) => {this.onChangeHandler(e.target.name, e.target.value)} } />
                                                            </FormGroup>
                                                        </Form>
                                                    </Col>
                                                    <Col md={{'offset': 0 , 'size' : 3}} className="text-right">
                                                        <Form className="text-left">
                                                            <FormGroup>
                                                                <Label>To</Label>
                                                                <Input type="date" name="contribution_to" value={ contribution_to } onChange={ (e) => {this.onChangeHandler(e.target.name, e.target.value)} } />
                                                            </FormGroup>
                                                        </Form>
                                                    </Col>
                                                    <Col md={{'offset': 0 , 'size' : 2}} className="text-right">
                                                        <Form className="text-center">
                                                            <FormGroup>
                                                                {/* <Button type="button" color="primary" onClick = { ()=> { onApplyFilter() }}>Apply</Button> */}
                                                            </FormGroup>
                                                        </Form>
                                                    </Col>
                                                    <Col md={12} sm={12} className="text-right mt-3">
                                                        {/* <Label className="text-muted">Enter title:</Label> */}
                                                        {/* <Button color="primary" disabled={ isFetching ? true : false } type="button" onClick={ this.onSubmitHandler }>{ 'View Existing' }</Button> */}
                                                        <Button color="primary" disabled={ isFetching ? true : false } type="button" onClick={ () => { this.onSubmitHandler('contributions') } }>{ isFetching ? 'Requesting...' : 'Export' }</Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>            
        )
    }
}