// import { ADDADMIN, ERROR, FORGETPASSWORD } from '../actions/ADDADMIN_action';
import { FETCHNOTIFICATIONSERROR, FETCHNOTIFICATIONSREQUEST, FETCHNOTIFICATIONSSUCCESS } from './../actions/actionTypes'
 
const initialState = {
    isFetched: false,
    isFetching : false,
    err : false,
    message:'',
    data: [],
    total : 0,
  };
  
  // receiving response sent by action according to type of action
  export default function allCategoriesReduces(state = initialState, action) {
      //console.log(action,'action')
      let { payload } = action
    switch (action.type) {
        case FETCHNOTIFICATIONSREQUEST:
          return { 
            isFetched: false,
            isFetching : true,
            err : false,
            message:'',
            data: [],
            total : 0
           };
          break;
        case FETCHNOTIFICATIONSSUCCESS:
            //console.log('========in switch', payload.categories)
        return { 
            isFetched: true,
            isFetching : false,
            err : false,
            message:'',
            data: payload.data,
            total : payload.count
        };
        break;
  
        case FETCHNOTIFICATIONSERROR:
        return { 
            isFetched: false,
            isFetching : false,
            err : true,
            message: payload.message,
            data: [],
            total : 0
        };
        break;
      default:
        return state;
    }
  }
