import React, { useState, useEffect } from 'react';
import { Container, Card, CardBody, CustomInput, Row, Col, Button } from 'reactstrap';
import TableComponent from './../Shared/Table/Table';
import TablePagination from './../Shared/Table/TablePagination';
import moment from 'moment';
import { SPOKEN_LANGUAGE_OBJECT } from './../../inc/constants';
import EyeIcon from 'mdi-react/EyeIcon';
import PenIcon from 'mdi-react/PencilIcon';
import DeleteIcon from 'mdi-react/CancelIcon';
import notification from '../../helpers/notificationHelper';
import DeleteModal from './DeleteModal';
// import EditModal from './EditModal';
const EditModal = React.lazy(() => import('./EditModal'));

// import DeleteModal from './DeleteModal';
// import ViewModal from './ViewModal';
// import EditModal from './EditModal';

const ListNotifications = (props) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [selectedRecord, selectRecord] = useState('');
  const [total, setTotal] = useState(10);
  const [contentId, setContentId] = useState('');
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [language, setLanguage] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [status, setStatus] = useState('');
  const [content, setContent] = useState([]);
  const [isFetched, setFetched] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalView, setModalView] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  
  const {
    buttonLabel,
    className,
    toggle,
    modal,
    func,
    confirmAction,
    modalTitle,
    modalText,
    modalFor,
  } = props;

  useEffect(() => {
    //   console.log('props in use effect', props);
          props._fetchNotifications(page, limit);
    //   setContent(props.premiumContentList)
  }, [page])

  useEffect(() => {
    if(!props.deleteNotification.isFetching && props.deleteNotification.isFetched && !props.deleteNotification.err ){
        toggleDeleteModal();
        props._fetchNotifications(page, limit);
        notification('success', props.deleteNotification.message)
    }
    if(!props.deleteNotification.isFetching && props.deleteNotification.isFetched && props.deleteNotification.err ){
        notification('error', props.deleteNotification.message);
    }
  }, [props.deleteNotification])

  useEffect(() => {
    if(!props.updateNotification.isFetching && props.updateNotification.isFetched && !props.updateNotification.err ){
        toggleEditModal();
        props._fetchNotifications(page, limit);
        notification('success', props.updateNotification.message)
    }
    if(!props.updateNotification.isFetching && props.updateNotification.isFetched && props.updateNotification.err ){
        notification('error', props.updateNotification.message);
    }
}, [props.updateNotification])
  
  useEffect(() => {
    //console.log('props in use effect', props);
      //   props.fetchContent(page, limit);
      if(!props.premiumContentList.isFetching && props.premiumContentList.isFetched && props.premiumContentList.data !== content ){
          setContent(props.premiumContentList.data);
          setFetched(props.premiumContentList.isFetched);
        //   setTotal(props.premiumContentList.count);
      }
    
  //   setContent(props.premiumContentList)
}, [props.premiumContentList])

let renderIndex=(record) => {
    let index = record.rowIndex + 1 ;
    //console.log(page);
    if(page > 1){
        index  = (page - 1)*limit + record.rowIndex + 1
    }
    return index;
}

let editRecord = (record) => {
    let id = record.id;
    //console.log('record', record)
}

let  onPageChange = (pagenum) => {
    //console.log('page num', page)
    setPage(pagenum);
    // props.fetchUsers(page, limit);
}


let setData =(data, type) => {
    let { _id, title, message, date, time, status } = data;
    // alert(moment(date).format('L'))
    setContentId(_id);
    setTitle(title);
    setMessage(message);   
    setLanguage(language);   
    setDate(moment(date).format('L'));
    setTime(time);
    setStatus(status);
    if(type==='deleteModal') return setModalDelete(true);
    if(type==='viewModal') return setModalView(true);
    if(type==='editModal') return setModalEdit(true);
}

let renderLanguage = (data) => {
    //console.log('data in language', data)
    // return 'English';
    let lang = data.data.language || 'en'
    return SPOKEN_LANGUAGE_OBJECT[lang]
}

const renderDate = (data) => {
    return moment(data.data.date).format('MM-DD-YYYY')
}

const renderAction = (record) => {
    //console.log(record.data)
    let buttons = <span>
                <PenIcon className="cursor-pointer ml-2" size={18} onClick={()=>{ setData(record.data, 'editModal') } }/>               
            { record.data && (record.data.type === 'scheduled' && record.data.status === 'running') && (<DeleteIcon className="cursor-pointer ml-2" size={18} onClick={()=>{ setData(record.data, 'deleteModal') } }/> ) }
                {/* <DeleteIcon className="cursor-pointer ml-2" size={18} onClick={()=>{ setData(record.data, 'deleteModal') } }/> */}
            </span>;
    return buttons;
}

const toggleDeleteModal = () => {
    setModalDelete(!modalDelete)
}

const toggleViewModal = () => {
    setModalView(!modalView)
}

const toggleEditModal = () => {
    setModalEdit(!modalEdit)
}

let renderTime = (data) => {
    //console.log('data.data.time',data.data.time);
    if(data.data.time === undefined) return 'NA' 
    return moment(data.data.time,'HH:mm').format('HH:mm');
}

const headsAg = [
    {headerName: "Id", field: "_id", width:"80", sortable:true, filter:'text', 
        cellRendererFramework : renderIndex ,
        // checkboxSelection : true
    },
    {headerName: "Title", field: "title", sortable:true, filter:'text'},
    {headerName: "Message", field: "message", sortable:true, filter:'text'},
    {headerName: "Status", field: "status", sortable:true, filter:'text'},
    {
        headerName: "Language", field: "language", 
        filter:'text', cellRendererFramework : renderLanguage
    },
    // // {headerName: "Source Material", field: "source_material", sortable:true, filter:'text'},
    // {headerName: "Page/Year", field: "page_year"},
    {headerName: "Date", field: "date", sortable:true, cellRendererFramework : renderDate},
    {headerName: "Time", field: "time", sortable:true, cellRendererFramework : renderTime},
    {
        headerName: "Actions", 
        cellRendererFramework : renderAction, 
        sortable:true, 
        filter:'text'
    },
]

let onDeleteHandler = (e) => {
    e.preventDefault()
    props._deleteNotification(contentId)
}

const onChangeHandler = (name, value) => {
    // console.log('name', name);
    // console.log('value', value);
    if(name==='title') return setTitle(value)
    if(name==='message') return setMessage(value)
    if(name==='date') return setDate(value)
    if(name==='time') return setTime(value)
}

const onUpdatehandler = (e) => {
    e.preventDefault();
    let data = {
        title,
        message,
        date,
        time
    }
    if(status === 'completed') {
        data['date'] =''
        data['time'] =''
    }
    //console.log(data, data)
    props._updateNotification(contentId, data);
}

  //console.log('data modal meeting', content);
  let { data } = props;
  return (
    <Container  style={{textAlign:''}}>
          <Row>
            <Col md={12}>
                <Card>
                    <CardBody className="bg-white ">
                        <h3 className='page-title bb-1 border-gray mb-4'>Notifications</h3>
                        <Row className="mt-12">
                            <Col md={12} className="text-right">
                                    <Button color="primary" href="/notifications/create" onClick={toggle} >Add +</Button>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col style={{ height: '90vh', width: '600px' }} className="ag-theme-material">
                            <div style={{height : '100%', width : '100%'}}>
                                <TableComponent 
                                    rowsData={ content } 
                                    // onCellClicked={ onCellClicked }
                                    headsAg={headsAg}
                                    // editRecord={editRecord} 
                                    isFetched ={isFetched}
                                    rowSelectable={'multiple'} 
                                    rowsPerPage={10}
                                    checkboxSelection={true}
                                    // noRecordsText = {norecordstext}
                                    fetchingRecordsText = {'Fetching List...'}
                                    noRecordsText = {'No Notifications Found!'}
                                    {...props} 

                                />
                            </div>
                            </Col>
                            <Col md={12} className="mt-2">
                                <TablePagination onPageChange={onPageChange} page={page} limit={limit} total={total}/>
                            </Col>
                            <DeleteModal 
                                modal={modalDelete} 
                                toggle={ toggleDeleteModal }
                                modalTitle={ 'Are You Sure ?' }
                                confirmAction = { onDeleteHandler } 
                            />
                            <React.Suspense fallback={''}>
                                {/* <OtherComponent /> */}
                                <EditModal 
                                        modal={modalEdit} 
                                        toggle={ toggleEditModal }
                                        modalTitle={ 'Details' }
                                        title={ title }
                                        message={ message }
                                        status={ status }
                                        date={ date }
                                        time={ time }
                                        language={ language }
                                        confirmAction = { onUpdatehandler }
                                        onChangeHandler= { onChangeHandler }
                                        onUpdatehandler ={ onUpdatehandler }
                                />
                            </React.Suspense>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
          </Row>
    </Container>
  );
}

export default ListNotifications;