import * as Actions from "./actionTypes";
import * as reactionFuncs from "./../../api/categoryApis";

export function getAllCategoriesRequest() {
  return {
    type: Actions.GETALLREACTIONSREQUEST,
    payload: {},
  };
}

export function getAllCategoriesSuccess(data) {
  return {
    type: Actions.GETALLREACTIONSUCCESS,
    payload: {
      reactions: data.data,
    },
  };
}

export function getAllCategoriesError(message) {
  return {
    type: Actions.GETALLREACTIONSERROR,
    reactions: { message },
  };
}

export function createCategoryRequest() {
  return {
    type: Actions.CREATECATEGORYREQUEST,
    payload: {},
  };
}

export function createCategorySuccess(data) {
  return {
    type: Actions.CREATECATEGORYSUCCESS,
    payload: data,
  };
}

export function createCategoryError(message) {
  return {
    type: Actions.CREATECATEGORYERROR,
    payload: { message },
  };
}
export function updatereactionRequest() {
  return {
    type: Actions.UPDATEREACTIONREQUEST,
    payload: {},
  };
}

export function updatereactionSuccess(data) {
  return {
    type: Actions.UPDATEREACTIONSUCCESS,
    payload: data,
  };
}

export function updatereactionError(message) {
  return {
    type: Actions.UPDATEREACTIONERROR,
    payload: { message },
  };
}

export function deletereactionRequest() {
  return {
    type: Actions.DELETEREACTIONREQUEST,
    payload: {},
  };
}

export function deletereactionSuccess(data) {
  return {
    type: Actions.DELETEREACTIONSUCCESS,
    payload: data,
  };
}

export function deletereactionError(message) {
  return {
    type: Actions.DELETEREACTIONERROR,
    payload: { message },
  };
}

export const addReactions = (data) => {
  return (dispatch) => {
    dispatch(createCategoryRequest());
    reactionFuncs
      .craeteNewReaction(data)
      .then((res) => {
        console.log("responseData*****************************", res);
        if (res.status === 200)
          return dispatch(createCategorySuccess(res.data));
        if (res.status === 400)
          return dispatch(createCategoryError(res.data.message));
        if (res.status === 401)
          return dispatch(createCategoryError(res.data.message));
        if (res.status === 409)
          return dispatch(createCategoryError(res.data.message));
      })
      .catch((err) => {
        console.log("err", err);
        return dispatch(createCategoryError(err.message));
      });
  };
};

export const listAllReactions = (page, limit) => {
  return (dispatch) => {
    dispatch(getAllCategoriesRequest());
    reactionFuncs
      .getAllReactions(page, limit)
      .then((res) => {
        if (res.status === 200)
          return dispatch(getAllCategoriesSuccess(res.data));
        if (res.status === 400)
          return dispatch(getAllCategoriesError(res.data.message));
        if (res.status === 401)
          return dispatch(getAllCategoriesError(res.data.message));
        if (res.status === 409)
          return dispatch(getAllCategoriesError(res.data.message));
      })
      .catch((err) => {
        console.log("err", err);
        return dispatch(getAllCategoriesError(err.message));
      });
  };
};

export const updateReaction = (id, data) => {
  return (dispatch) => {
    dispatch(updatereactionRequest());
    reactionFuncs
      .updateReactionData(id, data)
      .then((res) => {
        console.log("responseData*****************************", res);
        if (res.status === 200)
          return dispatch(updatereactionSuccess(res.data));
        if (res.status === 400)
          return dispatch(updatereactionError(res.data.message));
        if (res.status === 401)
          return dispatch(updatereactionError(res.data.message));
        if (res.status === 409)
          return dispatch(updatereactionError(res.data.message));
      })
      .catch((err) => {
        console.log("err", err);
        return dispatch(updatereactionError(err.message));
      });
  };
};

export const removeReaction = (id) => {
  return (dispatch) => {
    dispatch(deletereactionRequest());
    reactionFuncs
      .removeReaction(id)
      .then((res) => {
        console.log("responseData*****************************", res);
        if (res.status === 200)
          return dispatch(deletereactionSuccess(res.data));
        if (res.status === 400)
          return dispatch(deletereactionError(res.data.message));
        if (res.status === 401)
          return dispatch(deletereactionError(res.data.message));
        if (res.status === 409)
          return dispatch(deletereactionError(res.data.message));
      })
      .catch((err) => {
        console.log("err", err);
        return dispatch(deletereactionError(err.message));
      });
  };
};
