import React, {PureComponent} from 'react';
import { connect } from 'react-redux';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Redirect } from 'react-router-dom';
import { Input, Button } from 'reactstrap'
import notification from '../../helpers/notificationHelper';
import { Link } from 'react-router-dom';
import { emailValidator } from './../../helpers/validationHelpers'
import { loginAction, checkLoginAction } from '../../redux/actions/loginActions';

class LogInForm extends PureComponent {
  
    state = {
      showPassword: false,
      username:'',
      password:'',
      alert:false,
      message:'',
      isFetching : false
    };
    
  
  showPassword = (e,show) => {
    //console.log('e,show', e,show)
    e.preventDefault();
    if(!show) return
    this.setState({
      showPassword: !this.state.showPassword
    })
  }

  onChangeHandler = (name,value) =>{
    //console.log('name',name,'value', value)
    this.setState({ [name] : value });
  }

  handleLogin = (e) =>{
    if(e && e.key !=='Enter') return
    if( e ) e.preventDefault();
    // console.log('e.keyCode',e.key);
    let { username, password } = this.state;
    if(!emailValidator(username))return notification('error', 'Please enter a valid email address.')
    this.props._login(username, password);
  }
  
  componentDidMount(){
    //console.log(this.props,'this.props')
  }

  static getDerivedStateFromProps(props, state){
    if(props.login.loginRequest !== state.isFetching ){
      return {
        isFetching : props.login.loginRequest
      }
    }
  }

  componentDidUpdate(prevProps){
    let { login } = this.props;
    //console.log('nextProps  ------------------',login)
    if(login !== prevProps.login){
      if(login.authDone ){
        let { isLoggedIn } = login;
        if(!isLoggedIn){
          return notification('error',login.err)
        }else{
          return notification('User Logged In')
        }
      }
    }
  }
  
  render() {
    //console.log('props login', this.state)
    const { authDone, isLoggedIn} = this.props;
    let { username, password, isFetching } = this.state;
    
    return (  
      <React.Fragment>
        <form className='form' >
          <div className='form__form-group'>
            <label className='form__form-group-label'>Username</label>
            <div className='form__form-group-field'>
              <div className='form__form-group-icon'>
                <AccountOutlineIcon/>
              </div>
              <Input
                name='username'
                type='text'
                placeholder='Email'
                value={ username }
                onChange={ (e)=>{ this.onChangeHandler(e.target.name, e.target.value) } }
              />
            </div>
          </div>
          <div className='form__form-group'>
            <label className='form__form-group-label'>Password</label>
            <div className='form__form-group-field'>
              <div className='form__form-group-icon'>
                <KeyVariantIcon/>
              </div>
              <Input
                name='password'
                type={this.state.showPassword ? 'text' : 'password'}
                placeholder='Password'
                value={ password }
                onKeyPress = { (e) => { this.handleLogin(e) } } 
                onChange={ (e)=>{ this.onChangeHandler(e.target.name, e.target.value) } }              
              />
              <button className={`form__form-group-button${this.state.showPassword ? ' active' : ''}`}
                      onClick={(e) => this.showPassword(e,'show')}><EyeIcon/></button>
            </div>
            <div className='account__forgot-password'>
              <Link to='/forgot-password'>Forgot a password?</Link>
            </div>
          </div>
          <div className='form__form-group'>
          </div>
          <Button className='btn btn-primary account__btn account__btn--small' onClick={ () => { this.handleLogin() } }>
            {isFetching ? 'Signin in ...' : 'Sign In' }
          </Button>
        </form>
        {(authDone && isLoggedIn) && (<Redirect to="/" />)}
      </React.Fragment>

    )
  }
}

const mapStateToProps = (state) => {
  //console.log('allStates', state)
  const login = state.login;
  return { login };
}

const mapDispatchToProps = (dispatch, props) => {
    return {
      _login: (username, password) => {
        dispatch(loginAction(username, password));
      },
      _checkLogin : () => {
        dispatch(checkLoginAction())
      }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(LogInForm);
