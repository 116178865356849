import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';
import { ToastContainer } from 'react-toastify';
import ErrorBoundary from '../containers/errorBoundary/ErrorBoundary';
class MainWrapper extends PureComponent {

  render() {
    let wrapperClass = classNames({
      'wrapper': true,
      'wrapper--full-width': this.props.sidebar.collapse,
      'squared-corner-theme': this.props.customizer.squaredCorners,
      'blocks-with-shadow-theme': this.props.customizer.withBoxShadow,
      'top-navigation': this.props.customizer.topNavigation
    });
    
    const theme = this.props.theme.className;
    return (
      <ErrorBoundary>
        <div className={theme}>
          <div className={wrapperClass}>
            <ToastContainer pauseOnFocusLoss={false} autoClose={4000} />
            {this.props.children}
          </div>
        </div>
      </ErrorBoundary>
    )
  }
}

export default connect(state => {
  return {
    theme: state.theme,
    sidebar: state.sidebar,
    customizer: state.customizer
  }
})(MainWrapper);