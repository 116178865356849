import { wrappedFetch, wrappedGet } from './urlFuncs';
import { CONFIG_URL, VIRTUAL_TOUR } from '../inc/constants';
import { API_URL } from '../inc/config'

export const createConfig = (type, data) =>{
  let body = { type, data }
  return wrappedFetch(`${API_URL}${CONFIG_URL}`,'POST',body); 
}

export const fetchConfig = (page, limit) =>{
  return wrappedGet(`${API_URL}${CONFIG_URL}`, 'GET');
}
