import * as Actions from '../actions/actionTypes';

const initialState = {
    creating: false,
    catCreated: false,
    catCreateError:false,
    catData:'',
    msg : ''
  };
  
  // receiving response sent by action according to type of action
  export default function loginReducers(state = initialState, action) {
      //console.log(action,'action')
    switch (action.type) {
      case Actions.CREATECATEGORYREQUEST:
        return { 
            creating: true,
            catCreated: false,
            catCreateError:false,
            catData:'' ,
            msg : ''

        };
        break;
  
      case Actions.CREATECATEGORYSUCCESS:
        return { 
            creating: false,
            catCreated: true,
            catCreateError:false,
            catData:action.payload,
            msg : action.payload.message
            
          };
          break;
          
          case Actions.CREATECATEGORYERROR:
          return { 
            creating: false,
            catCreated: false,
            catCreateError:true,
            catData:action.payload,
            msg : action.payload.message
         };
        break;
      
      case Actions.CREATECATEGORYCLEAR:
        return { 
            creating: false,
            catCreated: false,
            catCreateError:false,
            catData:''
         };
        break;

      default:
        return state;
    }
  }
  