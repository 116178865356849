import * as postCategories from './../../api/postCategories'
import * as Actions from './actionTypes';

export function fetchCategoriesList() {
  return {
    type: Actions.GETALLCATEGORIESSREQUEST,
    payload:{}
  };
}

export function getAllPostCategoriesSuccess(data) {
    return {
      type: Actions.GETALLCATEGORIESSUCCESS,
      payload : data,
    };
}

export function  getAllPostCategoriesError(message) {
    return {
      type: Actions.GETALLCATEGORIESSERROR,
      payload : { message },
    };
}

export function  createCategoryRequest() {
    return {
      type: Actions.ADDPOSTCATEGORYREQUEST,
      payload:{}
    };
  }

export function createCategorySuccess(data) {
    return {
      type: Actions.ADDPOSTCATEGORYSUCCESS,
      payload : data,
    };
}
   
export function  createCategoryError(message) {
      return {
        type: Actions.ADDPOSTCATEGORYERROR,
        payload : { message },
      };
}

export function  updatePostCategoryRequest() {
  return {
    type: Actions.UPDATEPOSTCATEGORYREQUEST,
    payload:{}
  };
}
 
export function updatePostCategorySuccess(data) {
  return {
    type: Actions.UPDATEPOSTCATEGORYSUCCESS,
    payload : data,
  };
}
 
export function updatePostCategoryError(message) {
    return {
      type: Actions.UPDATEPOSTCATEGORYERROR,
      payload : { message },
    };
}

export function  makeAllCategoryRequest() {
  return {
    type: Actions.MAKECATEGORYVISIBLEREQUEST,
    payload:{}
  };
}
 
export function makeAllCategorySuccess(data) {
  return {
    type: Actions.MAKECATEGORYVISIBLESUCCESS,
    payload : data,
  };
}
 
export function makeAllCategoryError(message) {
    return {
      type: Actions.MAKECATEGORYVISIBLEERROR,
      payload : { message },
    };
}

export const createCategory = (data) =>{
    return (dispatch) => {
        dispatch(createCategoryRequest());
        console.log('category data', data);
        postCategories.createCategory(data).then((res)=>{
            console.log('responseData*****************************', res)
            if(res.status === 200) return dispatch(createCategorySuccess(res.data));
            if(res.status === 400) return dispatch(createCategoryError(res.data.message));
            if(res.status === 401) return dispatch(createCategoryError(res.data.message));
            if(res.status === 409) return dispatch(createCategoryError(res.data.message));
  
        }).catch((err)=>{
            console.log('err', err)
            return dispatch(createCategoryError(err.message));
        })
    }
  }

export const fetchPostCategories = (page, limit) =>{
    return (dispatch) => {
        postCategories.fetchCategoriesList(page, limit).then((result)=>{
            console.log('responseData*****************************', result)
            if(result.status === 200) return dispatch(getAllPostCategoriesSuccess(result.data));
            if(result.status === 400) return dispatch(getAllPostCategoriesError(result.data.message));
            if(result.status === 401) return dispatch(getAllPostCategoriesError(result.data.message));
            if(result.status === 409) return dispatch(getAllPostCategoriesError(result.data.message));
        }).catch((err)=>{
            console.log('err', err)
            return dispatch(getAllPostCategoriesError(err.message));
        })
    }
  }  

  //action creator to udpate notifications
  export const updateCategory = (data) =>{
    return (dispatch) => {
        dispatch(fetchCategoriesList());
        postCategories.updateCategory(data).then((res)=>{
            console.log('responseData*****************************', res)
            if(res.status === 200) return dispatch(updatePostCategorySuccess(res.data));
            if(res.status === 400) return dispatch(updatePostCategoryError(res.data.message));
            if(res.status === 401) return dispatch(updatePostCategoryError(res.data.message));
            if(res.status === 409) return dispatch(updatePostCategoryError(res.data.message));
  
        }).catch((err)=>{
            console.log('err', err)
            return dispatch(updatePostCategoryError(err.message));
        })
    }
  }
  
    //action creator to udpate notifications
    export const makeAllCategoryVisible = () =>{
      return (dispatch) => {
          dispatch(fetchCategoriesList());
          postCategories.makeAllCategoryVisible().then((res)=>{
              console.log('responseData*****************************', res)
              if(res.status === 200) return dispatch(makeAllCategorySuccess(res.data));
              if(res.status === 400) return dispatch(makeAllCategoryError(res.data.message));
              if(res.status === 401) return dispatch(makeAllCategoryError(res.data.message));
              if(res.status === 409) return dispatch(makeAllCategoryError(res.data.message));
    
          }).catch((err)=>{
              console.log('err', err)
              return dispatch(makeAllCategoryError(err.message));
          })
      }
    }

    //action creator to udpate notifications
    export const updateCategoryVisibilty = (data) =>{
      return (dispatch) => {
          dispatch(fetchCategoriesList());
          postCategories.updateCategoryVisibilty(data).then((res)=>{
              console.log('responseData*****************************', res)
              if(res.status === 200) return dispatch(makeAllCategorySuccess(res.data));
              if(res.status === 400) return dispatch(makeAllCategoryError(res.data.message));
              if(res.status === 401) return dispatch(makeAllCategoryError(res.data.message));
              if(res.status === 409) return dispatch(makeAllCategoryError(res.data.message));
    
          }).catch((err)=>{
              console.log('err', err)
              return dispatch(makeAllCategoryError(err.message));
          })
      }
    }
