// import { ADDADMIN, ERROR, FORGETPASSWORD } from '../actions/ADDADMIN_action';
import {CREATEMEETINGSUCCESS, CREATEMEETINGREQUEST, CREATEMEETINGERROR } from './../actions/actionTypes'

const initialState = {
    requestDone: false,
    isCreated: false,
    isFetching:false,
    err : false,
    message:'',
  };
  
  // receiving response sent by action according to type of action
  export default function addAdminReducers(state = initialState, action) {
      //console.log(action,'action')
      let {payload} = action
    switch (action.type) {

        case CREATEMEETINGREQUEST:
          return { 
            requestDone: false,
            isCreated: false,
            isFetching: true,
            err : false,
            message: payload.message,
           };
          break;
        case CREATEMEETINGSUCCESS:
        return { 
            requestDone: true,
            isCreated: true,
            isFetching: false,
            err : false,
            message: payload.message,
        };
        break;
  
        case CREATEMEETINGERROR:
        return { 
            requestDone: true,
            isCreated: false,
            isFetching: false,
            err : true,
            message: payload.message
        };
        break;
  
      
      default:
        return state;
    }
  }
  