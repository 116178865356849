import { connect } from "react-redux";
import { makeUserTester } from "../../api/userApis";
import AllUsers from '../../components/Users/AllUsers';
import { listLanguages } from "../../redux/actions/meetingActions";
import { getAllUsers,blockUser, searchUser, makeHostUser, deleteUser, makeTester } from '../../redux/actions/usersActions';
 
const mapStateToProps = state => {
  //console.log('state in container', state);
  return {
    users: state.appUsersList ,
    userBlocked: state.appUsersBlock ,
    deleteUser : state.deleteAppUserReducer
  };
};
 
const mapDispatchToProps = (dispatch) => {
  return {
    fetchUsers: (page, skip, active, subscribed,tester, blocked, sortBy, join_date_sort, sort, sortOrder) => {
      return dispatch(getAllUsers(page, skip, active, subscribed, tester, blocked, sortBy, join_date_sort, sort, sortOrder));
    },
    _blockUser: (data) => {
      return dispatch(blockUser(data));
    },
    _searchUser : (searchInfo) => {
      return dispatch(searchUser(searchInfo));
    }
    ,
    _hostUser : (data) => {
      return dispatch(makeHostUser(data));
    },
    _deleteUser : (data) => {
      return dispatch(deleteUser(data));
    },
    _fetchActiveLanguages : () =>{
      return dispatch(listLanguages());
    },
    _makeUserATester : (data) => {
      return dispatch(makeTester(data));
    },
  };
};
 
const AllUsersContainer = connect(mapStateToProps, mapDispatchToProps)(
  AllUsers
);
 
export default AllUsersContainer;