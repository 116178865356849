import React from 'react';
import { Multiselect } from 'react-widgets';


export default function MultiSelectCommon(props){
    let { data, onChangeHandler, textField, filter, defaultValue  } = props;
    return (
        <Multiselect
            data={data}
            onChange = {onChangeHandler}
            textField={textField}
            valueField='id'
            filter={filter}
            defaultValue={defaultValue}
            {...props}
    />
    )
} 