import { connect } from "react-redux";
import Dashboard from '../../../components/Dashboard/Dashboard'
import { getAllDashboardStats } from '../../../redux/actions/dashboardActions';
import { withRouter } from 'react-router-dom';

const mapStateToProps = state => {
  // console.clear()
  //console.log('state in container', state);
  return {
    stats : state.dashboardStats 
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    _fetchStats: () => {
      return dispatch(getAllDashboardStats());
    }
  };
};


const DashboardContainer = connect(mapStateToProps, mapDispatchToProps)(withRouter(Dashboard));

export default DashboardContainer;
