import React from 'react';
import { Route } from 'react-router-dom';
import Layout from '../../containers/_layout/Layout';
import { routes } from './routes';
import { ADMIN_PERMISSIONS } from './../../inc/constants';

const  hasSubArray = (master, sub) => {
    return master.some(r=> sub.indexOf(r) >= 0)
}

const MainRoutes = (props) => {
        let { checked, isAuthenticated, permissions, ...rest } = props;
        //console.log('props in routes', props);
        let allPermissions = ADMIN_PERMISSIONS.map( ( perm ) => { 
            //console.log('permissions.includes( perm.id )', permissions.includes( perm.id.toString() ))
            if( permissions.includes( 'ALL' ) ) return 'ALL' 
            if( permissions.includes( perm.id.toString() ) ) return perm.name 
        }).filter((perm) => { return perm !== undefined })
        
        //console.log('allPermissions', allPermissions);

        const elements = routes.map((item, index) => {
            if( !item.authRoute ) return null
            //console.log('hassubsarya', hasSubArray(allPermissions, item.permissions ))
            //console.log('item.permissions', item.permissions )
            if( !hasSubArray(allPermissions, item.permissions ) && !allPermissions.includes('ALL') )  return null
            const { path, exact } = item
            const routeProps = { path, exact }
            // console.log('routeProps', routeProps)
            // console.log('component', item.component)
            return (<Route key={index} {...routeProps} render={ props=> <item.component {...props} />} />)
        })
        return(
            <React.Fragment>
                <Layout/>
                <div className='container__wrap'>
                    {elements}
                </div>
            </React.Fragment>
        )   
}

export default MainRoutes