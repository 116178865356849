import React, { useState } from 'react';
import { Container, Row, Col,Table,Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const MeetingDurationModal = (props) => {
  const {
    toggle,
    modal,
     days, 
     duration,
     weeklyTimeDurations 
  } = props;

  //console.log('data modal meeting', props);
  return (
    <div  style={{textAlign:'center'}}>
      {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
      <Modal isOpen={modal} fade={true} toggle={toggle} keyboard={false} backdrop={false}>
          <span>
            <ModalHeader toggle={toggle}>{"Weekly Duration Details"}</ModalHeader>
          </span>
        <ModalBody>
            <div>
                <Container>
                    <Row>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Days</th>
                                    <th>Duration</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                              weeklyTimeDurations &&
                              weeklyTimeDurations.map((val)=>{
                                return (<tr><th>{val.day}</th>
                                         <th>{val.duration}</th>
                                        </tr>)
                                
                              })
                            }
                            </tbody>
                        </Table>
                    </Row>
                </Container>
            </div>
            {/* <div>
                <h2 className="text-center">{title}</h2>
                <p className="text-center"><i><strong>{`@${est_time} for ${duration} mins`}</strong></i></p>
                <p><i><strong>{`Hosted by :- ${host && host.length > 0 && (host[0].email.mail)}`}</strong></i></p>
                <p><i><strong>{`Spoken Language :- ${spoken_language && (spoken_language)}`}</strong></i></p>
                <div className="mb-3">Days : <br />
                  {days && days.length > 0 && (
                    <div>
                      <span className={days.includes('sunday') ? `daysCircle daysHighlight` : 'daysCircle'} >S</span>
                      <span className={days.includes('monday') ? `daysCircle daysHighlight` : 'daysCircle'} >M</span>
                      <span className={days.includes('tuesday') ? `daysCircle daysHighlight` : 'daysCircle'} >T</span>
                      <span className={days.includes('wednesday') ? `daysCircle daysHighlight` : 'daysCircle'} >W</span>
                      <span className={days.includes('thursday') ? `daysCircle daysHighlight` : 'daysCircle'} >T</span>
                      <span className={days.includes('friday') ? `daysCircle daysHighlight` : 'daysCircle'} >F</span>
                      <span className={days.includes('saturday') ? `daysCircle daysHighlight` : 'daysCircle'} >S</span>
                    </div>
                  )}
                </div>
                <div className="mb-3">
                  <div className="mb-2">Categories : <br /></div>
                  <div>
                    {category && category.length > 0 && (
                    category.map((cat, index) => {
                      console.log('cat', cat)
                      return(
                        <React.Fragment key={index}>
                          <span className="category-tags">{cat.english_title}</span>
                          {index > 4 && (<br />)}
                        </React.Fragment>
                      )
                    })
                    )}
                  </div>
                </div>
                <p>Description : <br /> {description}</p>
            </div> */}
        </ModalBody>
        <ModalFooter>
          {/* <Button color="primary" name ={modalFor} onClick={(e)=>confirmAction(e)}>Yes{' '}</Button>{' '} */}
          <Button color="secondary" onClick={toggle}>Close</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default MeetingDurationModal;