import React, { useState } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  FormText,
  Label,
  Input,
  Button,
} from "reactstrap";

const EditFinderMeeting = (props) => {
  const {
    toggle,
    modal,
    name,
    datetime,
    timezone,
    notes,
    tags,
    formats,
    types,
    updateFinderMeeting,
    onChangeHandler,
  } = props;

  return (
    <div style={{ textAlign: "center" }}>
      <Modal
        isOpen={modal}
        fade={true}
        toggle={toggle}
        keyboard={false}
        backdrop={false}
      >
        <span>
          <ModalHeader toggle={toggle}>{"Meeting Details"}</ModalHeader>
        </span>
        <ModalBody>
          <Form>
            <FormGroup row>
              <Label htmlFor="exampleEmail" sm={2}>
                Notes
              </Label>
              <Col sm={10}>
                <Input
                  type="textarea"
                  value={notes}
                  name="notes"
                  onChange={onChangeHandler}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label htmlFor="exampleEmail" sm={2}>
                Tags
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  value={tags}
                  name="tags"
                  onChange={onChangeHandler}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label htmlFor="exampleEmail" sm={2}>
                Formats
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  value={formats}
                  name="formats"
                  onChange={onChangeHandler}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label htmlFor="exampleEmail" sm={2}>
                Types
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  value={types}
                  name="types"
                  onChange={onChangeHandler}
                />
              </Col>
            </FormGroup>
            <FormGroup check row>
              <Col
                sm={{
                  offset: 2,
                  size: 10,
                }}
              >
                <Button
                  type="button"
                  color="primary"
                  onClick={updateFinderMeeting}
                >
                  Update
                </Button>
              </Col>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default EditFinderMeeting;
