import { ADDBANNEDERROR, ADDBANNEDREQUEST, ADDBANNEDSUCCESS } from '../actions/actionTypes'
 
const initialState = {
    requestDone: false,
    isFetched: false,
    isFetching : false,
    err : false,
    message:'',
    email: {}
  };
  
  // receiving response sent by action according to type of action
  export default function addBannedEmailReducer(state = initialState, action) {
    let { payload } = action
    switch (action.type) {
        case ADDBANNEDREQUEST:
          return { 
            requestDone: false,
            isFetched: false,
            isFetching : true,
            err : false,
            message:'',
            email: {},
           };
          break;
        case ADDBANNEDSUCCESS:
        return { 
            requestDone: true,
            isFetched: true,
            isFetching : false,
            err : false,
            message:payload.message.message,
            email: payload.data,
        };
        break;
  
        case ADDBANNEDERROR:
        return { 
            requestDone: true,
            isFetched: false,
            isFetching : false,
            err : true,
            message: payload.message,
            email : {}
        };
        break;
        
      default:
        return state;
    }
  }
