import React, { PureComponent } from "react";
import { Collapse } from "reactstrap";
import PropTypes from "prop-types";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";

export default class SidebarCategory extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    // icon: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      collapse: false,
    };
  }

  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }

  render() {
    let { Icon } = this.props;
    //console.log("here in sidebar cotegory");
    return (
      <div
        className={`sidebar__category-wrap${
          this.state.collapse ? " sidebar__category-wrap--open" : ""
        }`}
      >
        <li className="sidebar__link sidebar__category" onClick={this.toggle}>
          {/* {this.props.icon ? <span className={`sidebar__link-icon lnr lnr-${this.props.icon}`}/> : ''} */}
          {Icon && <Icon className="icons-sidebar" width="20" height="20" />}
          <p className="sidebar__link-title">{this.props.title}</p>
          <span className=" lnr lnr-chevron-right" />
          <ChevronRightIcon className=" sidebar__category-icon" />
        </li>
        <Collapse
          isOpen={this.state.collapse}
          className="sidebar__submenu-wrap"
        >
          <ul className="sidebar__submenu">
            <div>{this.props.children}</div>
          </ul>
        </Collapse>
      </div>
    );
  }
}
