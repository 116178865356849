import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Button,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { emailValidator } from "../../helpers/validationHelpers";
import notificaiton from "../../helpers/notificationHelper";

const AddCategory = (props) => {
  // render(){
  let {
    modal,
    toggle,
    modalTitle,
    CustomClass,
    buttonLabel,
    cancelAction,
    confirmAction,
    confirmButtonText,
    cancelButtonText,
  } = props;
  return (
    <Form>
      <Modal isOpen={modal} toggle={toggle} className={CustomClass}>
        <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
        <ModalBody>{props.children}</ModalBody>
        {/* <ModalFooter>
                     <Button color="primary"  name = 'add' onClick={(e)=>confirmAction(e)}>{confirmButtonText}</Button>{' '}
                     <Button color="secondary" name = 'cancel' onClick={(e)=>cancelAction(e)}>{cancelButtonText}</Button> 
                    </ModalFooter> */}
      </Modal>
    </Form>
  );
};

export default AddCategory;
