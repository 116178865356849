import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Row, Col, Label, Input, CustomInput } from 'reactstrap';
import { DAYS_ARRAY, ADMIN_PERMISSIONS } from './../../inc/constants';
import Multiselect from './../Shared/MultiSelectCommon';

const EditAdminUsersModal = (props) => {
  const {
    toggle,
    modal,
    confirmAction,
    onChangeHandler,
    modalFor,
    isFetching,
    firstname, lastname, username, email, country_code, phone, password, role
  } = props;

  //console.log('data modal meeting', props);
  //console.log('permissions in modal', permissions);

    // let renderPermissions = () => {
    //     console.log('permissions-----', permissions)
    //     let allPermissions = ADMIN_PERMISSIONS.map((permission, i) => {
    //         return (
    //             <Col md={3} sm={12} key={i}>
    //                 <CustomInput className="mb-3" type="checkbox" id={i} defaultChecked={ permissions.includes(permission.id.toString() ) } onClick={ () => { props.onAddPermissions(permission.id) } } label={permission.value} />
    //             </Col>
    //         )
    //     })
    //     return allPermissions;
        
    // }
  return (
    <div  style={{textAlign:'center'}}>
      {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
      <Modal isOpen={modal} className="edituser-modal" fade={true} toggle={toggle} keyboard={false} backdrop={false}>
          <span>
            <ModalHeader toggle={toggle}>{`Edit User`}</ModalHeader>
          </span>
        <ModalBody>
            <div>
                <Form onSubmit ={()=>{confirmAction()}}>
                    <Row form className="mb-3">
                        <Col md={6} sm={12}>
                            <Label className="text-muted">Firstname:</Label>
                            <Input type="text" name="firstname" disabled={isFetching} value={firstname} 
                                onChange={ onChangeHandler } required 
                                placeholder = "Enter Firstname" 
                            />
                        </Col>
                        <Col md={6} sm={12}>
                            <Label className="text-muted">Lastname:</Label>
                            <Input type="text" name="lastname" value={lastname} 
                                disabled={isFetching} 
                                onChange={ onChangeHandler }
                                placeholder = "Enter Lastname" />
                        </Col>
                    </Row>
                    <Row form className="mb-3">
                        <Col md={6} sm={12}>
                            <Label className="text-muted">Username:</Label>
                            <Input type="text" name="username" disabled={isFetching} value={username} 
                                onChange={ onChangeHandler } required 
                                placeholder = "Enter Username" 
                            />
                        </Col>
                        <Col md={2} sm={12}>
                                <Label className="text-muted">Country Code:</Label>
                                <Input type="text" name="country_code" value ={country_code}
                                    onChange={ onChangeHandler } required
                                    placeholder = "Enter Country Code" 
                                />
                            </Col>
                            <Col md={4} sm={12}>
                                <Label className="text-muted">Phone:</Label>
                                <Input type="tel" name="phone" value ={phone}
                                    onChange={ onChangeHandler } required
                                    placeholder = "Enter Phone" 
                                />
                        </Col>
                    </Row>
                    <Row form className="mb-3">
                        <Col md={6} sm={12}>
                            <Label className="text-muted">Email:</Label>
                            <Input type="email" name="email" value ={email}
                                disabled
                                onChange={ onChangeHandler } required
                                placeholder = "Enter Email" 
                            />
                        </Col>
                        <Col md={6} sm={12}>
                            <Label className="text-muted">Password:</Label>
                            <Input type="password" name="password" value ={password}
                                onChange={ onChangeHandler } required
                                placeholder = "Enter Password" 
                            />
                        </Col>
                        
                    </Row>
                    {/* <Row form className="mb-3">
                            <Col md={12}>
                                <Label className="text-muted">Permissions:</Label>
                            </Col>
                            {renderPermissions()}
                    </Row> */}
                    {
                       (localStorage.getItem('UserRole') === "superadmin") &&                       
                       <Row form className="mb-3">
                       <Col md={12}>
                       <Label className="text-muted">Role:</Label>
                           <Input type="select" name="role" value={role} id="typeselec1t" onChange={onChangeHandler}>
                           <option value="" >--select day-</option>
                           <option value="superadmin" >Super Admin</option>
                           <option value="admin" >Admin</option>
                           <option value="view-only" >View Only</option>
                           </Input>
                       </Col>
                    </Row>
                    }

                </Form>
            </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" name ={modalFor} onClick={(e)=>confirmAction(e)}>{isFetching ? 'Updating...' : 'Update'}</Button>{' '}
          <Button color="secondary" onClick={toggle}>Close</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default EditAdminUsersModal;