import { connect } from "react-redux";
import Donations from '../../components/Donations/Donations';
import { getAlldonationsStats } from '../../redux/actions/donationsActions';
 
const mapStateToProps = state => {
  //console.log('state in container', state);
  return {
    doinationsList: state.donationList,
  };
};
 
const mapDispatchToProps = (dispatch) => {
  return {
    _fetchDonations: (page, skip, amountSort, dateSort, from_date, to_date) => {
      return dispatch(getAlldonationsStats(page, skip, amountSort, dateSort, from_date, to_date));
    }
  };
};
 
const AllUsersContainer = connect(mapStateToProps, mapDispatchToProps)(
  Donations);
 
export default AllUsersContainer;