import React, { useState } from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ViewFinderMeeting = (props) => {
    const {
        toggle,
        modal,
        name,
        datetime,
        timezone,
        notes,
        tags,
        formats,
        types,
        autoFocus
    } = props;

    //console.log('data modal meeting', name); 
    return(
        <div  style={{textAlign:'center'}}>
            <Modal isOpen={modal} autoFocus={autoFocus} fade={true} toggle={toggle} keyboard={false} backdrop={false}>
                <span>
                    <ModalHeader toggle={toggle}>{'Meeting Details'}</ModalHeader>
                </span>
                <ModalBody>
                    <h2 className="text-center">{name}</h2>
                    <p><i><strong>{`Date-Time :- ${datetime}`}</strong></i></p>
                    <p><i><strong>{`Timezone :- ${timezone}`}</strong></i></p>
                    <p><i><strong>{`notes :- ${notes}`}</strong></i></p>
                    <p><i><strong>{`tags :- ${tags}`}</strong></i></p>
                    <p><i><strong>{`formats :- ${formats}`}</strong></i></p>
                    <p><i><strong>{`types :- ${types}`}</strong></i></p>
                </ModalBody>
            </Modal>
           
        </div>
    )
}

export default ViewFinderMeeting