import * as Actions from './actionTypes';
import * as contentFuncs from './../../api/premiumContentApi';

export function  createContentRequest() {
    return {
      type: Actions.CREATECONTENTREQUEST,
      payload:{}
    };
  }
   
  export function createContentSuccess(data) {
    return {
      type: Actions.CREATECONTENTSUCCESS,
      payload : data,
    };
  }
   
  export function  createContentError(message) {
    return {
      type: Actions.CREATECONTENTERROR,
      payload : { message },
    };
  }

  export function  fetchContentRequest() {
    return {
      type: Actions.FETCHCONTENTREQUEST,
      payload:{}
    };
  }
    
  export function fetchContentSuccess(data) {
    return {
      type: Actions.FETCHCONTENTSUCCESS,
      payload : data,
    };
  }
    
  export function  fetchContentError(message) {
      return {
        type: Actions.FETCHCONTENTERROR,
        payload : { message },
      };
  }

  export function  fetchIssueLogRequest() {
    return {
      type: Actions.FETCHCONTENTREQUEST,
      payload:{}
    };
  }
    
  export function fetchIssueLogSuccess(data) {
    return {
      type: Actions.FETCHCONTENTSUCCESS,
      payload : data
    };
  }
    
  export function  fetchIssueLogError(message) {
      return {
        type: Actions.FETCHCONTENTERROR,
        payload : { message },
      };
  }

  export function  deleteContentRequest() {
    return {
        type: Actions.DELETECONTENTREQUEST,
        payload:{}
    };
}
    
export function deleteContentSuccess(data) {
    return {
        type: Actions.DELETECONTENTSUCCESS,
        payload : data,
    };
}
    
export function  deleteContentError(message) {
      return {
        type: Actions.DELETECONTENTERROR,
        payload : { message },
    };
}
   
export function  updateContentRequest() {
  return {
      type: Actions.UPDATECONTENTREQUEST,
      payload:{}
  };
}
  
export function updateContentSuccess(data) {
  return {
      type: Actions.UPDATECONTENTSUCCESS,
      payload : data,
  };
}
  
export function  updateContentError(message) {
    return {
      type: Actions.UPDATECONTENTERROR,
      payload : { message },
  };
}
   


export const postPremiumContent = (data) =>{
  return (dispatch) => {
      dispatch(createContentRequest());
      console.log('premium content data', data)
      contentFuncs.craetePremiumContent(data).then((res)=>{
          console.log('responseData*****************************', res)
          if(res.status === 200) return dispatch(createContentSuccess(res.data));
          if(res.status === 400) return dispatch(createContentError(res.data.message));
          if(res.status === 401) return dispatch(createContentError(res.data.message));
          if(res.status === 409) return dispatch(createContentError(res.data.message));

      }).catch((err)=>{
          console.log('err', err)
          return dispatch(createContentError(err.message));
      })
  }
}

export const fetchPremiumContent = (page, limit, fDate, tDate, lang, dateSort) =>{
  return (dispatch) => {
      dispatch(createContentRequest());
      console.log('premium content data', page, limit, fDate, tDate, lang, dateSort)
      contentFuncs.fetchContentList(page, limit, fDate, tDate, lang, dateSort).then((res)=>{
          console.log('responseData*****************************', res)
          if(res.status === 200) return dispatch(fetchContentSuccess(res.data));
          if(res.status === 400) return dispatch(fetchContentError(res.data.message));
          if(res.status === 401) return dispatch(fetchContentError(res.data.message));
          if(res.status === 409) return dispatch(fetchContentError(res.data.message));

      }).catch((err)=>{
          console.log('err', err)
          return dispatch(createContentError(err.message));
      })
  }
}

export const fetchPremiumContentIssueLog = (page, limit, filter) =>{
  return (dispatch) => {
      dispatch(fetchIssueLogRequest());
      contentFuncs.fetchPremiumContentIssuesApi(page, limit, filter).then(async (res)=>{
          if(res.status === 200) {
            return dispatch(fetchIssueLogSuccess(res.data));
          }
          if(res.status === 400) return dispatch(fetchIssueLogError(res.data.message));
          if(res.status === 401) return dispatch(fetchIssueLogError(res.data.message));
          if(res.status === 409) return dispatch(fetchIssueLogError(res.data.message));

      }).catch((err)=>{
          console.log('err', err)
          return dispatch(fetchIssueLogError(err.message));
      })
  }
}

export const updatePremiumContent = (id, data) =>{
  return (dispatch) => {
      dispatch(updateContentRequest());
      console.log('premium content data', id, data)
      contentFuncs.updatePremiumContentApi(id, data).then((res)=>{
          console.log('responseData*****************************', res)
          if(res.status === 200) return dispatch(updateContentSuccess(res.data));
          if(res.status === 400) return dispatch(updateContentError(res.data.message));
          if(res.status === 401) return dispatch(updateContentError(res.data.message));
          if(res.status === 409) return dispatch(updateContentError(res.data.message));

      }).catch((err)=>{
          console.log('err', err)
          return dispatch(updateContentError(err.message));
      })
  }
}

export const deletePremiumContent = (id) =>{
  return (dispatch) => {
      dispatch(deleteContentRequest());
      console.log('premium content data',id)
      contentFuncs.deletePremiumContentApi(id).then((res)=>{
        console.log('responseData*****************************', res)
        if(res.status === 200) return dispatch(deleteContentSuccess(res.data));
        if(res.status === 400) return dispatch(deleteContentError(res.data.message));
        if(res.status === 401) return dispatch(deleteContentError(res.data.message));
        if(res.status === 409) return dispatch(deleteContentError(res.data.message));

      }).catch((err)=>{
        console.log('err', err)
        return dispatch(deleteContentError(err.message));
      })
  }
}