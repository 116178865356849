import React, { useState, useEffect } from 'react';
import { Container, Card, CardBody, Label, Row, Col, FormGroup, Form, Input, Button } from 'reactstrap';
import TableComponent from './../Shared/Table/Table';
import TablePagination from './../Shared/Table/TablePagination';
import moment from 'moment';
import { SPOKEN_LANGUAGE_OBJECT, SPOKEN_LANGUAG_ARRAY, SPOKEN_LANGUAG_ARRAY_MEETING } from './../../inc/constants';
import EyeIcon from 'mdi-react/EyeIcon';
import PenIcon from 'mdi-react/PencilIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import notification from '../../helpers/notificationHelper';
import DeleteModal from './DeleteModal';
import ViewModal from './ViewModal';
import EditModal from './EditModal';

const MeetingDetailsModal = (props) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [selectedRecord, selectRecord] = useState('');
  const [total, setTotal] = useState(10);
  const [contentId, setContentId] = useState('');
  const [title, setTitle] = useState('');
  const [contentUpdate, setContentUpdate] = useState('');
  const [language, setLanguage] = useState('');
  const [date, setDate] = useState('');
  const [content, setContent] = useState([]);
  const [isFetched, setFetched] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalView, setModalView] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [filterDate, setfilterDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [filterLang, setFilterLang] = useState('');
  const [dateSort, setDateSort] = useState('new');
  
  const {
    buttonLabel,
    className,
    toggle,
    modal,
    func,
    confirmAction,
    modalTitle,
    modalText,
    modalFor,
  } = props;

  useEffect(() => {
    //   console.log('props in use effect', props);
        fetchData(page, limit, filterDate, toDate, filterLang);
    //   setContent(props.premiumContentList)
  }, [ page ])
  
    useEffect(() => {
        fetchData(1, limit, filterDate, toDate, filterLang, dateSort);
    }, [filterLang, dateSort])

  useEffect(() => {
      let { deleteContent } = props
      if(deleteContent.isFetched && !deleteContent.err && !deleteContent.isFetching ) {
            fetchData(page, limit, filterDate, toDate, filterLang);
            setModalDelete(!modalDelete);
            return notification('succes', deleteContent.message)
      }
      if(!deleteContent.isFetched && deleteContent.err && !deleteContent.isFetching ) {
            fetchData(page, limit, filterDate, toDate, filterLang);
            setModalDelete(!modalDelete);
            return notification('error', deleteContent.message)
    }
  }, [props.deleteContent])

  useEffect(() => {
    let { updateContent } = props
    if(updateContent.isFetched && !updateContent.err && !updateContent.isFetching ) {
        fetchData(page, limit, filterDate, toDate, filterLang);
        setModalEdit(!modalEdit);
        return notification('success', updateContent.message)
    }
    if(!updateContent.isFetched && updateContent.err && !updateContent.isFetching ) {
        fetchData(page, limit, filterDate, toDate, filterLang);
        setModalEdit(!modalEdit);
        return notification('error', updateContent.message)
}
}, [props.updateContent])
  
useEffect(() => {
    console.log('props in use effect', props);
      //   props.fetchContent(page, limit);
      if(!props.premiumContentList.isFetching && props.premiumContentList.contentData !== content ){
          setContent(props.premiumContentList.contentData);
          setFetched(props.premiumContentList.fetched);
          setTotal(props.premiumContentList.count);
      }
  //   setContent(props.premiumContentList)
}, [props])

const fetchData = (pageP, limitP, filterDateP, toDateP, filterLangP, ) => {
    props.fetchContent(pageP, limitP, filterDateP, toDateP, filterLangP, dateSort);
}
let renderIndex=(record) => {
    let index = record.rowIndex + 1 ;
    console.log(page);
    if(page > 1){
        index  = (page - 1)*limit + record.rowIndex + 1
    }
    return index;
}

let editRecord = (record) => {
    let id = record.id;
    console.log('record', record)
}

let  onPageChange = (pagenum) => {
    console.log('page num', page)
    setPage(pagenum);
    // props.fetchUsers(page, limit);
}


let setData =(data, type) => {
    let { _id, content, title, date, language } = data;
    setTitle(title);
    setContentId(_id);
    setContentUpdate(content);   
    setLanguage(language);   
    setDate(date);
    if(type==='deleteModal') return setModalDelete(true);
    if(type==='viewModal') return setModalView(true);
    if(type==='editModal') return setModalEdit(true);
}

let renderLanguage = (data) => {
    return SPOKEN_LANGUAGE_OBJECT[data.data.language]
}

let renderDate = (data) => {
    return moment(data.data.date).tz("America/New_York").format('MM-DD-YYYY')
}

let renderAction = (record) => {
    let buttons = <span>
            <EyeIcon onClick={()=>{ setData(record.data, 'viewModal') } } size={'18px'} className="cursor-pointer"/>
            <PenIcon className="cursor-pointer ml-2" size={18} onClick={()=>{ setData(record.data, 'editModal') } }/>
            <DeleteIcon className="cursor-pointer ml-2" size={18} onClick={()=>{ setData(record.data, 'deleteModal') } }/>
        </span>;
    return buttons;
}

let toggleDeleteModal = () => {
    setModalDelete(!modalDelete)
}

let toggleViewModal = () => {
    setModalView(!modalView)
}

let toggleEditModal = () => {
    setModalEdit(!modalEdit)
}

const headsAg = [
    {headerName: "Id", field: "id_number", width:"80", sortable:true, filter:'text', 
        cellRendererFramework : renderIndex ,
        // checkboxSelection : true
    },
    {headerName: "Title", field: "title", sortable:true, filter:'text'},
    {headerName: "Content", field: "content", sortable:true, filter:'text'},
    {
        headerName: "Language", field: "language", 
        filter:'text', cellRendererFramework : renderLanguage
    },
    // {headerName: "Source Material", field: "source_material", sortable:true, filter:'text'},
    // {headerName: "Page/Year", field: "page_year"},
    {headerName: "Date", field: "date", sortable:true, cellRendererFramework : renderDate},
    {
        headerName: "Actions", 
        cellRendererFramework : renderAction, 
        sortable:true, 
        filter:'text'
    },
]

let onDeleteHandler = (e) => {
    e.preventDefault()
    props._deleteContent(contentId)
}

let onChangeHandler = (name, value) => {
    console.log('name', name);
    console.log('value', value);
    if(name==='title') return setTitle(value);
    else if(name==='content') return setContentUpdate(value);
    else if(name==='date') return setDate(value);
    else if(name==='language') return setLanguage(value);
    else if(name==='filterDate') return setfilterDate(value);
    else if(name==='toDate') return setToDate(value);
}

let onUpdatehandler = (e) => {
    e.preventDefault()
    let data = {
        title,
        content : contentUpdate,
        date,
        language
    }
    props._updateContent(contentId, data);
}

let onApplyFilter = () => {
    if(filterDate === '' || toDate === '') return notification( 'error', 'Please select the date range' )
    if( moment(filterDate).isAfter(moment(toDate))) return notification('error', 'Start Date should be less than the end date !') 
    props.fetchContent(page, limit, filterDate, toDate, filterLang)
}

  //console.log('data modal meeting', content);
  let { data } = props;
  return (
    <Container  style={{textAlign:''}}>
          <Row>
            <Col md={12}>
                <Card>
                    <CardBody className="bg-white ">
                        <Row className='page-title bb-1 border-gray '>
                            <Col md={6}>
                                <h3 >Premium Content</h3>
                            </Col>
                            <Col md={{'offset': 12 , 'size' : 3}} style={{float : 'right'}} className="text-right">
                                <Button color="primary" href="/subscribed-content/issue-log" >Issue Log</Button>
                            </Col>
                            <Col md={{'offset': 0 , 'size' : 3}} style={{float : 'right'}} className="text-right">
                                <Button color="primary" href="/subscribed-content" >Add Content +</Button>
                            </Col>
                        </Row>
                        <Row className='page-title bb-1 border-gray '>
                            <Col md={9}>
                                
                            </Col>
                            <Col md={{'offset': 12 , 'size' : 3}} style={{float : 'right'}} className="pt-2 text-right">
                            <Form className="">
                            <FormGroup row>
                                <Label>Contribution Date :- </Label>
                                <Input type="select" name="dateSort" value={ dateSort } onChange={(e) => { setDateSort(e.target.value) }}>
                                    <option value="new" >Newest First</option>                                             
                                    <option value="old" >Old First</option>
                                </Input>
                            </FormGroup >
                            </Form>
                            </Col>
                        </Row>    
                        <Row className='page-title bb-1 border-gray pb-2 mt-2'>
                            <Col md={{'offset': 1 , 'size' : 3}} className="text-right">
                                    <Form className="text-right">
                                        <FormGroup>
                                            <Input type="select" name="filterLang" value={ filterLang } onChange={ (e) => { setFilterLang(e.target.value) } } >
                                                <option value="">Select Language</option>
                                                { SPOKEN_LANGUAG_ARRAY && SPOKEN_LANGUAG_ARRAY.map((l) => {
                                                    return <option value={l.value}>{l.name}</option>
                                                }) }
                                            </Input>
                                        </FormGroup>
                                    </Form>
                                </Col>
                                <Col md={{'offset': 0 , 'size' : 3}} className="text-right">
                                    <Form className="text-right">
                                        <FormGroup>
                                            <Input type="date" name="filterDate" value={ filterDate } onChange={ (e) => {onChangeHandler(e.target.name, e.target.value)} } />
                                        </FormGroup>
                                    </Form>
                                </Col>
                                <Col md={{'offset': 0 , 'size' : 3}} className="text-right">
                                    <Form className="text-right">
                                        <FormGroup>
                                            <Input type="date" name="toDate" value={ toDate } onChange={ (e) => {onChangeHandler(e.target.name, e.target.value)} } />
                                        </FormGroup>
                                    </Form>
                                </Col>
                                <Col md={{'offset': 0 , 'size' : 2}} className="text-right">
                                    <Form className="text-center">
                                        <FormGroup>
                                            <Button type="button" color="primary" onClick = { ()=> { onApplyFilter() }}>Apply</Button>
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>
                        <Row className="mt-3">
                            <Col style={{ height: '90vh', width: '600px' }} className="ag-theme-material">
                            <div style={{height : '100%', width : '100%'}}>
                                <TableComponent 
                                    rowsData={ content } 
                                    // onCellClicked={ onCellClicked }
                                    headsAg={headsAg}
                                    editRecord={editRecord} 
                                    isFetched ={isFetched}
                                    rowSelectable={'multiple'} 
                                    rowsPerPage={10}
                                    checkboxSelection={true}
                                    // noRecordsText = {norecordstext}
                                    fetchingRecordsText = {'Fetching List...'}
                                    noRecordsText = {'No Content Found!'}
                                    {...props} 

                                />
                            </div>
                            </Col>
                            <Col md={12} className="mt-2">
                                <TablePagination onPageChange={onPageChange} page={page} limit={limit} total={total}/>
                            </Col>
                            <Col>
                                <DeleteModal 
                                    modal={modalDelete} 
                                    toggle={ toggleDeleteModal }
                                    modalTitle={ 'Are You Sure ?' }
                                    confirmAction = { onDeleteHandler } 
                                />
                                <ViewModal 
                                    modal={modalView} 
                                    toggle={ toggleViewModal }
                                    modalTitle={ 'Details' }
                                    title={ title }
                                    content={ contentUpdate }
                                    date={ date }
                                    language={ language }
                                    confirmAction = { onDeleteHandler }
                                />
                                <EditModal 
                                    modal={modalEdit} 
                                    toggle={ toggleEditModal }
                                    modalTitle={ 'Details' }
                                    title={ title }
                                    content={ contentUpdate }
                                    date={ date }
                                    language={ language }
                                    confirmAction = { onUpdatehandler }
                                    onChangeHandler= { onChangeHandler }
                                    onUpdatehandler ={ onUpdatehandler }
                                />
                            </Col>

                        </Row>
                    </CardBody>
                </Card>
            </Col>
          </Row>
    </Container>
  );
}

export default MeetingDetailsModal;