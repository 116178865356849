import { connect } from "react-redux";
import SubscribedContent from '../../components/SubscribedContent/SubscribedContent';
import { postPremiumContent } from '../../redux/actions/premiumContentActiions';
 
const mapStateToProps = state => {
  //console.log('state in container', state);
  return {
    premiumContent : state.premiumContent 
  };
};
 
const mapDispatchToProps = (dispatch) => {
  return {
    _postPremiumContent : (data) => {
      return dispatch(postPremiumContent(data));
    }
  };
};
 
const SubscribedContentContainer = connect(mapStateToProps, mapDispatchToProps)(
    SubscribedContent
);
 
export default SubscribedContentContainer;