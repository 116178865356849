export const LOGIN_URL = "/me/login"; //login user url
export const LOGOUT_URL = "/me/logout"; //login user url
export const CHECK_LOGIN_URL = "/me/check-login"; //check if user is logged in url
export const ME_GET_PROFILE = "/me/profile"; //get user profile
export const GET_ALL_ADMIN_USERS = "/users"; //get all admin users list
export const GET_ALL_APP_USERS = "/users/app-users"; //get all admin users list
export const ADD_ADMIN_USER = "/users/create-admin";
export const RESET_PASSWORD_URL = "/users/forgot-password";
export const UPDATE_PROFILE_URL = "/me/profile";
export const BLOCK_USER_URL = "/users/block-user";
export const HOST_USER_URL = "/users/make-host";
export const SEARCH_ADMIN_URL = "/users/search";
export const LIST_CATEGORIES_URL = "/meetings/category";
export const ADD_CATEGORIES_URL = "/meetings/category";
export const LIST_BANNED_EMAILS_URL = "/users/banned-email";
export const ADD_BANNED_EMAIL_URL = "/users/add-banned-email";
export const REMOVE_BANNED_EMAIL_URL = "/users/delete-banned-email";
export const LIST_HOSTS_URL = "/meetings/list-hosts";
export const CREATE_NEW_MEETING = "/meetings/";
export const LIST_ALL_MEETING = "/meetings/";
export const VALID_URLS_ARRAY = [
  "/dashboard",
  "/login",
  "/forgot-password",
  "/logout",
  "/category/add",
  "/category/all",
  "/admin/users/add",
  "/banned-emails",
  '/add-banned-emails',
  "/admin/users",
  "/users/add",
  "/users",
  "/account/my-profile",
  "/ForgotPasswordContainer",
  "/meetings/add",
  "/meetings",
  "/virtual-tour",
  "/notifications",
  "/subscribed-content",
  "/subscribed-content/list",
  "/subscribed-content/issue-log",
  "/feelings",
  "/reactions",
  "/faq/add",
  "/faq",
  "/faq/edit",
  "/donations",
  "/terms-conditions",
  "/terms-conditions/list",
  "/notifications/create",
  "/configurations",
  "/reports-exports",
  "/objectionable-content",
  "/reported-content",
  "/al-anon-post-categories",
  "/al-anon-post-categories/create",
  "/user-tracking-list",
  "/resources",
  "/admin-meeting",
  "/upload-finder-meetings",
  "/set-translation",
  "/translate-single-meeting"
];

export const VIEW_ONLY_URLS = [
  "/dashboard",
  "/login",
  "/forgot-password",
  "/logout",
  "/banned-emails",
  "/users",
  "/account/my-profile",
  "/ForgotPasswordContainer",
  "/meetings",
  "/reports-exports",
  "/admin-meeting",
]
export const DAYS_ARRAY = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];
export const SEARCH_USER_URL = "/users/app-users/search";
export const POST_PREMIUM_CONTENT_URL = "/susbcription/content";
export const VIRTUAL_TOUR = "/feelings/virtual-tour";
export const NOTIFICATIONS = "/notifications";
export const DASHBOARD_STATS = "/dashboard";
export const FEELINGS_URL = "/feelings";
export const GET_REACTIONS_URL = "/reactions/get-reactions";
export const CREATE_REACTIONS_URL = "/reactions/create-reaction";
export const UPDATE_REACTIONS_URL = "/reactions/update-reaction";
export const REMOVE_REACTIONS_URL = "/reactions/remove-reaction";
export const DELETE_ADMIN_USER = "/users/";
export const UPDATE_ADMIN_USER = "/users/update-admin/";
export const DELETE_APP_USER = '/users/app-users/'
export const SPOKEN_LANGUAG_ARRAY = [
  {
    name: "English",
    value: "en",
  },
  {
    name: "French",
    value: "fr",
  },
  {
    name: "Spanish",
    value: "es",
  },
];

export const SPOKEN_LANGUAG_ARRAY_MEETING = [
  { code: "ab", name: "Abkhaz", nativeName: "аҧсуа" },
  { code: "aa", name: "Afar", nativeName: "Afaraf" },
  { code: "af", name: "Afrikaans", nativeName: "Afrikaans" },
  { code: "ak", name: "Akan", nativeName: "Akan" },
  { code: "sq", name: "Albanian", nativeName: "Shqip" },
  { code: "am", name: "Amharic", nativeName: "አማርኛ" },
  { code: "ar", name: "Arabic", nativeName: "العربية" },
  { code: "an", name: "Aragonese", nativeName: "Aragonés" },
  { code: "hy", name: "Armenian", nativeName: "Հայերեն" },
  { code: "as", name: "Assamese", nativeName: "অসমীয়া" },
  { code: "av", name: "Avaric", nativeName: "авар мацӀ, магӀарул мацӀ" },
  { code: "ae", name: "Avestan", nativeName: "avesta" },
  { code: "ay", name: "Aymara", nativeName: "aymar aru" },
  { code: "az", name: "Azerbaijani", nativeName: "azərbaycan dili" },
  { code: "bm", name: "Bambara", nativeName: "bamanankan" },
  { code: "ba", name: "Bashkir", nativeName: "башҡорт теле" },
  { code: "eu", name: "Basque", nativeName: "euskara, euskera" },
  { code: "be", name: "Belarusian", nativeName: "Беларуская" },
  { code: "bn", name: "Bengali", nativeName: "বাংলা" },
  { code: "bh", name: "Bihari", nativeName: "भोजपुरी" },
  { code: "bi", name: "Bislama", nativeName: "Bislama" },
  { code: "bs", name: "Bosnian", nativeName: "bosanski jezik" },
  { code: "br", name: "Breton", nativeName: "brezhoneg" },
  { code: "bg", name: "Bulgarian", nativeName: "български език" },
  { code: "my", name: "Burmese", nativeName: "ဗမာစာ" },
  { code: "ca", name: "Catalan; Valencian", nativeName: "Català" },
  { code: "ch", name: "Chamorro", nativeName: "Chamoru" },
  { code: "ce", name: "Chechen", nativeName: "нохчийн мотт" },
  {
    code: "ny",
    name: "Chichewa; Chewa; Nyanja",
    nativeName: "chiCheŵa, chinyanja",
  },
  { code: "zh", name: "Chinese", nativeName: "中文 (Zhōngwén), 汉语, 漢語" },
  { code: "cv", name: "Chuvash", nativeName: "чӑваш чӗлхи" },
  { code: "kw", name: "Cornish", nativeName: "Kernewek" },
  { code: "co", name: "Corsican", nativeName: "corsu, lingua corsa" },
  { code: "cr", name: "Cree", nativeName: "ᓀᐦᐃᔭᐍᐏᐣ" },
  { code: "hr", name: "Croatian", nativeName: "hrvatski" },
  { code: "cs", name: "Czech", nativeName: "česky, čeština" },
  { code: "da", name: "Danish", nativeName: "dansk" },
  { code: "dv", name: "Divehi; Dhivehi; Maldivian;", nativeName: "ދިވެހި" },
  { code: "nl", name: "Dutch", nativeName: "Nederlands, Vlaams" },
  { code: "en", name: "English", nativeName: "English" },
  { code: "eo", name: "Esperanto", nativeName: "Esperanto" },
  { code: "et", name: "Estonian", nativeName: "eesti, eesti keel" },
  { code: "ee", name: "Ewe", nativeName: "Eʋegbe" },
  { code: "fo", name: "Faroese", nativeName: "føroyskt" },
  { code: "fj", name: "Fijian", nativeName: "vosa Vakaviti" },
  { code: "fi", name: "Finnish", nativeName: "suomi, suomen kieli" },
  { code: "fr", name: "French", nativeName: "français, langue française" },
  {
    code: "ff",
    name: "Fula; Fulah; Pulaar; Pular",
    nativeName: "Fulfulde, Pulaar, Pular",
  },
  { code: "gl", name: "Galician", nativeName: "Galego" },
  { code: "ka", name: "Georgian", nativeName: "ქართული" },
  { code: "de", name: "German", nativeName: "Deutsch" },
  { code: "el", name: "Greek, Modern", nativeName: "Ελληνικά" },
  { code: "gn", name: "Guaraní", nativeName: "Avañeẽ" },
  { code: "gu", name: "Gujarati", nativeName: "ગુજરાતી" },
  { code: "ht", name: "Haitian; Haitian Creole", nativeName: "Kreyòl ayisyen" },
  { code: "ha", name: "Hausa", nativeName: "Hausa, هَوُسَ" },
  { code: "he", name: "Hebrew (modern)", nativeName: "עברית" },
  { code: "hz", name: "Herero", nativeName: "Otjiherero" },
  { code: "hi", name: "Hindi", nativeName: "हिन्दी, हिंदी" },
  { code: "ho", name: "Hiri Motu", nativeName: "Hiri Motu" },
  { code: "hu", name: "Hungarian", nativeName: "Magyar" },
  { code: "ia", name: "Interlingua", nativeName: "Interlingua" },
  { code: "id", name: "Indonesian", nativeName: "Bahasa Indonesia" },
  {
    code: "ie",
    name: "Interlingue",
    nativeName: "Originally called Occidental; then Interlingue after WWII",
  },
  { code: "ga", name: "Irish", nativeName: "Gaeilge" },
  { code: "ig", name: "Igbo", nativeName: "Asụsụ Igbo" },
  { code: "ik", name: "Inupiaq", nativeName: "Iñupiaq, Iñupiatun" },
  { code: "io", name: "Ido", nativeName: "Ido" },
  { code: "is", name: "Icelandic", nativeName: "Íslenska" },
  { code: "it", name: "Italian", nativeName: "Italiano" },
  { code: "iu", name: "Inuktitut", nativeName: "ᐃᓄᒃᑎᑐᑦ" },
  { code: "ja", name: "Japanese", nativeName: "日本語 (にほんご／にっぽんご)" },
  { code: "jv", name: "Javanese", nativeName: "basa Jawa" },
  {
    code: "kl",
    name: "Kalaallisut, Greenlandic",
    nativeName: "kalaallisut, kalaallit oqaasii",
  },
  { code: "kn", name: "Kannada", nativeName: "ಕನ್ನಡ" },
  { code: "kr", name: "Kanuri", nativeName: "Kanuri" },
  { code: "ks", name: "Kashmiri", nativeName: "कश्मीरी, كشميري‎" },
  { code: "kk", name: "Kazakh", nativeName: "Қазақ тілі" },
  { code: "km", name: "Khmer", nativeName: "ភាសាខ្មែរ" },
  { code: "ki", name: "Kikuyu, Gikuyu", nativeName: "Gĩkũyũ" },
  { code: "rw", name: "Kinyarwanda", nativeName: "Ikinyarwanda" },
  { code: "ky", name: "Kirghiz, Kyrgyz", nativeName: "кыргыз тили" },
  { code: "kv", name: "Komi", nativeName: "коми кыв" },
  { code: "kg", name: "Kongo", nativeName: "KiKongo" },
  {
    code: "ko",
    name: "Korean",
    nativeName: "한국어 (韓國語), 조선말 (朝鮮語)",
  },
  { code: "ku", name: "Kurdish", nativeName: "Kurdî, كوردی‎" },
  { code: "kj", name: "Kwanyama, Kuanyama", nativeName: "Kuanyama" },
  { code: "la", name: "Latin", nativeName: "latine, lingua latina" },
  {
    code: "lb",
    name: "Luxembourgish, Letzeburgesch",
    nativeName: "Lëtzebuergesch",
  },
  { code: "lg", name: "Luganda", nativeName: "Luganda" },
  {
    code: "li",
    name: "Limburgish, Limburgan, Limburger",
    nativeName: "Limburgs",
  },
  { code: "ln", name: "Lingala", nativeName: "Lingála" },
  { code: "lo", name: "Lao", nativeName: "ພາສາລາວ" },
  { code: "lt", name: "Lithuanian", nativeName: "lietuvių kalba" },
  { code: "lu", name: "Luba-Katanga", nativeName: "" },
  { code: "lv", name: "Latvian", nativeName: "latviešu valoda" },
  { code: "gv", name: "Manx", nativeName: "Gaelg, Gailck" },
  { code: "mk", name: "Macedonian", nativeName: "македонски јазик" },
  { code: "mg", name: "Malagasy", nativeName: "Malagasy fiteny" },
  { code: "ms", name: "Malay", nativeName: "bahasa Melayu, بهاس ملايو‎" },
  { code: "ml", name: "Malayalam", nativeName: "മലയാളം" },
  { code: "mt", name: "Maltese", nativeName: "Malti" },
  { code: "mi", name: "Māori", nativeName: "te reo Māori" },
  { code: "mr", name: "Marathi (Marāṭhī)", nativeName: "मराठी" },
  { code: "mh", name: "Marshallese", nativeName: "Kajin M̧ajeļ" },
  { code: "mn", name: "Mongolian", nativeName: "монгол" },
  { code: "na", name: "Nauru", nativeName: "Ekakairũ Naoero" },
  { code: "nv", name: "Navajo, Navaho", nativeName: "Diné bizaad, Dinékʼehǰí" },
  { code: "nb", name: "Norwegian Bokmål", nativeName: "Norsk bokmål" },
  { code: "nd", name: "North Ndebele", nativeName: "isiNdebele" },
  { code: "ne", name: "Nepali", nativeName: "नेपाली" },
  { code: "ng", name: "Ndonga", nativeName: "Owambo" },
  { code: "nn", name: "Norwegian Nynorsk", nativeName: "Norsk nynorsk" },
  { code: "no", name: "Norwegian", nativeName: "Norsk" },
  { code: "ii", name: "Nuosu", nativeName: "ꆈꌠ꒿ Nuosuhxop" },
  { code: "nr", name: "South Ndebele", nativeName: "isiNdebele" },
  { code: "oc", name: "Occitan", nativeName: "Occitan" },
  { code: "oj", name: "Ojibwe, Ojibwa", nativeName: "ᐊᓂᔑᓈᐯᒧᐎᓐ" },
  {
    code: "cu",
    name:
      "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic",
    nativeName: "ѩзыкъ словѣньскъ",
  },
  { code: "om", name: "Oromo", nativeName: "Afaan Oromoo" },
  { code: "or", name: "Oriya", nativeName: "ଓଡ଼ିଆ" },
  { code: "os", name: "Ossetian, Ossetic", nativeName: "ирон æвзаг" },
  { code: "pa", name: "Panjabi, Punjabi", nativeName: "ਪੰਜਾਬੀ, پنجابی‎" },
  { code: "pi", name: "Pāli", nativeName: "पाऴि" },
  { code: "fa", name: "Persian", nativeName: "فارسی" },
  { code: "pl", name: "Polish", nativeName: "polski" },
  { code: "ps", name: "Pashto, Pushto", nativeName: "پښتو" },
  { code: "pt", name: "Portuguese", nativeName: "Português" },
  { code: "qu", name: "Quechua", nativeName: "Runa Simi, Kichwa" },
  { code: "rm", name: "Romansh", nativeName: "rumantsch grischun" },
  { code: "rn", name: "Kirundi", nativeName: "kiRundi" },
  { code: "ro", name: "Romanian, Moldavian, Moldovan", nativeName: "română" },
  { code: "ru", name: "Russian", nativeName: "русский язык" },
  { code: "sa", name: "Sanskrit (Saṁskṛta)", nativeName: "संस्कृतम्" },
  { code: "sc", name: "Sardinian", nativeName: "sardu" },
  { code: "sd", name: "Sindhi", nativeName: "सिन्धी, سنڌي، سندھی‎" },
  { code: "se", name: "Northern Sami", nativeName: "Davvisámegiella" },
  { code: "sm", name: "Samoan", nativeName: "gagana faa Samoa" },
  { code: "sg", name: "Sango", nativeName: "yângâ tî sängö" },
  { code: "sr", name: "Serbian", nativeName: "српски језик" },
  { code: "gd", name: "Scottish Gaelic; Gaelic", nativeName: "Gàidhlig" },
  { code: "sn", name: "Shona", nativeName: "chiShona" },
  { code: "si", name: "Sinhala, Sinhalese", nativeName: "සිංහල" },
  { code: "sk", name: "Slovak", nativeName: "slovenčina" },
  { code: "sl", name: "Slovene", nativeName: "slovenščina" },
  { code: "so", name: "Somali", nativeName: "Soomaaliga, af Soomaali" },
  { code: "st", name: "Southern Sotho", nativeName: "Sesotho" },
  { code: "es", name: "Spanish; Castilian", nativeName: "español, castellano" },
  { code: "su", name: "Sundanese", nativeName: "Basa Sunda" },
  { code: "sw", name: "Swahili", nativeName: "Kiswahili" },
  { code: "ss", name: "Swati", nativeName: "SiSwati" },
  { code: "sv", name: "Swedish", nativeName: "svenska" },
  { code: "ta", name: "Tamil", nativeName: "தமிழ்" },
  { code: "te", name: "Telugu", nativeName: "తెలుగు" },
  { code: "tg", name: "Tajik", nativeName: "тоҷикӣ, toğikī, تاجیکی‎" },
  { code: "th", name: "Thai", nativeName: "ไทย" },
  { code: "ti", name: "Tigrinya", nativeName: "ትግርኛ" },
  {
    code: "bo",
    name: "Tibetan Standard, Tibetan, Central",
    nativeName: "བོད་ཡིག",
  },
  { code: "tk", name: "Turkmen", nativeName: "Türkmen, Түркмен" },
  { code: "tl", name: "Tagalog", nativeName: "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔" },
  { code: "tn", name: "Tswana", nativeName: "Setswana" },
  { code: "to", name: "Tonga (Tonga Islands)", nativeName: "faka Tonga" },
  { code: "tr", name: "Turkish", nativeName: "Türkçe" },
  { code: "ts", name: "Tsonga", nativeName: "Xitsonga" },
  { code: "tt", name: "Tatar", nativeName: "татарча, tatarça, تاتارچا‎" },
  { code: "tw", name: "Twi", nativeName: "Twi" },
  { code: "ty", name: "Tahitian", nativeName: "Reo Tahiti" },
  { code: "ug", name: "Uighur, Uyghur", nativeName: "Uyƣurqə, ئۇيغۇرچە‎" },
  { code: "uk", name: "Ukrainian", nativeName: "українська" },
  { code: "ur", name: "Urdu", nativeName: "اردو" },
  { code: "uz", name: "Uzbek", nativeName: "zbek, Ўзбек, أۇزبېك‎" },
  { code: "ve", name: "Venda", nativeName: "Tshivenḓa" },
  { code: "vi", name: "Vietnamese", nativeName: "Tiếng Việt" },
  { code: "vo", name: "Volapük", nativeName: "Volapük" },
  { code: "wa", name: "Walloon", nativeName: "Walon" },
  { code: "cy", name: "Welsh", nativeName: "Cymraeg" },
  { code: "wo", name: "Wolof", nativeName: "Wollof" },
  { code: "fy", name: "Western Frisian", nativeName: "Frysk" },
  { code: "xh", name: "Xhosa", nativeName: "isiXhosa" },
  { code: "yi", name: "Yiddish", nativeName: "ייִדיש" },
  { code: "yo", name: "Yoruba", nativeName: "Yorùbá" },
  { code: "za", name: "Zhuang, Chuang", nativeName: "Saɯ cueŋƅ, Saw cuengh" },
];

export const SPOKEN_LANGUAGE_OBJECT = {
  en: "English",
  fr: "French",
  es: "Spanish",
};
export const UTS_EST_OFFSET = "-240";
export const ADMIN_PERMISSIONS = [
    {
      id : 0,
      name : 'CREATE_ADMIN',
      value : 'Create Admin' 
    },
    {
      id : 1,
      name : 'LIST_ADMIN',
      value : 'Admin List' 
    },
    {
      id : 2,
      name : 'APP_USERS',
      value : 'Users List' 
    },
    {
      id : 3,
      name : 'CATEGORY',
      value : 'Categories' 
    },
    {
      id : 4,
      name : 'CREATE_MEETINGS',
      value : 'Create Meetings' 
    },
    {
      id : 5,
      name : 'LIST_MEETINGS',
      value : 'Meetings List' 
    },
    {
      id : 6,
      name : 'DONATIONS',
      value : 'Contributions' 
    },
    {
      id : 7,
      name : 'VIRTUAL_TOUR',
      value : 'Virtual Tour' 
    },
    {
      id : 8,
      name : 'PREMIUM_CONTENT',
      value : 'Premium Content' 
    },
    {
      id : 9,
      name : 'ALL',
      value : 'ALL' 
    },
    {
      id : 10,
      name : 'FAQ',
      value : 'FAQ' 
    },
    {
      id :11,
      name : 'TAC',
      value : 'Terms And Conditions' 
    },
    {
      id :11,
      name : 'FAQ_LIST',
      value : 'FAQ List' 
    },
    {
      id :12,
      name : 'CONFIGURATIONS',
      value : 'Global Settings' 
    },
    {
      id :13,
      name : 'REPORTS',
      value : 'App stats' 
    },
]
export const FAQ_API_URL = '/feelings/faq';
export const GET_DONATIONS_URL='/donations';
export const TERMS_AND_CONDITIONS_URL = '/feelings/terms-conditions';
export const MEETING_TYPES = [
    {
        type : 'alanon',
        name : 'Al-Anon'
    },
    {
        type : 'alateen',
        name : 'Alateen'
    }
];

export const MEETING_TYPE_OBJECT  = {
    'alanon' : 'Al-Anon',
    'alateen' : 'Al-Ateen'
}
export const MEETING_TYPE_AL_ANON = 'alanon';
export const MEETING_TYPE_AL_ATEEN = 'alateen';
export const PAGINATION_MAX_PAGE_LIMIT =25
export const LIST_LANGUAGES_URL = '/meetings/active-languages';
export const MEETING_TYPE_DAILY = 'daily';
export const MEETING_TYPE_WEEKLY = 'weekly';
export const MEETING_TYPE_ONE_TIME = 'one_time';
export const MEETING_TYPE_FUTURE = 'future_meet';
export const MEET_TYPES_ARRAY = [
    { 
        type : MEETING_TYPE_DAILY,
        name : 'Daily'    
    },
    { 
        type : MEETING_TYPE_WEEKLY,
        name : 'Weekly'    
    },
    { 
        type : MEETING_TYPE_ONE_TIME,
        name : 'One Time'    
    }
]

export const CONFIG_URL = '/feelings/configurations';
export const GENERATE_USER_REPORTS = "/reports/users";
export const GENERATE_MEETINGS_REPORTS = "/reports/meetings";
export const GENERATE_CONTRIBUTIONS_REPORTS = "/reports/contributions";
export const MAKE_TEST_USER_URL = '/users/make-tester/'
export const LIST_OBJECTIONABLE_CONTENT_URL = "/objectionable-content/"
export const LIST_REPORTED_CONTENT_URL = "/report"
export const LIST_REPORTEE_USERS_URL = "/report/users/"
export const CHANGE_REPORTED_CONTENT_STATUS = "/report/"
export const UPLOAD_OBJECTIONABLE_CONTENT_URL = "/objectionable-content/"
export const LIST_POST_CATEGORIES_URL = "/newsfeed-categories/"
export const USER_TRACKING_URL = "/user-details-tracker/"
export const LIST_RESOURCES_URL = "/resources/"
export const GET_FINDER_MEETINGS = "/admin-meeting"
export const GET_FINDER_MEETINGS_DETAILS = "/admin-meeting-details"
export const UPDATE_FINDER_MEETINGS_DETAILS = "/update-finder-meeting"
export const UPLOAD_FINDER_MEETINGS = "/upload-finder-meetings"
export const TRANSLATE_FINDER_MEETINGS = "/set-translation"
export const TRANSLATE_SINGLE_MEETING = "/translate-single-meeting"