import React from "react";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Label,
  Input,
  Form,
  Button,
  FormGroup,
  CustomInput,
} from "reactstrap";
import CSVParser from "./../Shared/CSVParser";
import notify from "./../../helpers/notificationHelper";

export default class SubscribedContent extends React.Component {
  state = {
    isFetching: false,
    content: [],
  };
  componentDidMount() {
    // this.props.fetchUsers(1, 10);
  }

  static getDerivedStateFromProps(props, state) {
    console.log("props derived states", props);
    console.log("state derived states", state);

    if (props.premiumContent.isFetching !== state.isFetching) {
      return {
        isFetching: props.premiumContent.isFetching,
      };
    }
    return null;
  }

  onClickHandler = (value) => {
    this.setState({
      selected: value,
    });
  };

  toggle = () => {
    this.setState((prevState) => {
      return {
        modal: !prevState.modal,
      };
    });
  };

  handleUpload = (data, fileInfo) => {
    console.dir(data);
    console.dir("fileInfo", fileInfo);
    if (data === "" || data.length < 1) {
      return notify("error", "Please Upload a non Empty File");
    }
    this.setState({
      content: data,
    });
  };

  componentDidUpdate(prevProps, prevstate) {
    let { premiumContent } = this.props;
    if (prevProps.premiumContent !== premiumContent) {
      if (
        !premiumContent.isFetching &&
        premiumContent.contentCreated &&
        !premiumContent.error
      ) {
        notify("success", premiumContent.message);
      }
      if (
        !premiumContent.isFetching &&
        !premiumContent.contentCreated &&
        premiumContent.error
      ) {
        notify("error", premiumContent.message);
      }
    }
  }

  onSubmitHandler = () => {
    let { content } = this.state;
    this.props._postPremiumContent(content);
  };

  render() {
    let { isFetching } = this.state;
    console.log("props", this.props);
    return (
      <Container>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody className="bg-white ">
                <Row className="page-title mt-12 bb-1 border-gray mb-4">
                  <Col>
                  <h3 className="">
                    Subscribed Content
                  </h3>
                  </Col>
                    <Col md={6} className="text-right">
                            <Button color="primary" href="/subscribed-content/list" >Back</Button>
                    </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={12}>
                    <Form onSubmit={this.onSubmitHandler}>
                      <Row form className="mb-3">
                        <Col md={12} sm={12} className="text-center">
                          {/* <Label className="text-muted">Enter title:</Label> */}
                          <CSVParser handleUpload={this.handleUpload} />
                        </Col>
                        <Col md={12} sm={12} className="text-center">
                          {/* <Label className="text-muted">Enter title:</Label> */}
                          <Button
                            color="primary"
                            disabled={isFetching ? true : false}
                            type="button"
                            onClick={this.onSubmitHandler}
                          >
                            {isFetching ? "Saving..." : "Submit"}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
