import { wrappedFetch, wrappedGet } from './urlFuncs';
import { ADD_BANNED_EMAIL_URL, LIST_BANNED_EMAILS_URL, REMOVE_BANNED_EMAIL_URL } from './../inc/constants';
import {API_URL} from './../inc/config';

export const addNewBannedEmail = (data) => {
  return wrappedFetch(`${API_URL}${ADD_BANNED_EMAIL_URL}`,'POST', data);
}

export const listBannedEmail = () => {
    return wrappedGet(`${API_URL}${LIST_BANNED_EMAILS_URL}`,'GET')
}

export const removeBannedEmail = (data) => {
  return wrappedFetch(`${API_URL}${REMOVE_BANNED_EMAIL_URL}`,'POST', data);
}