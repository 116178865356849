import { 
//create CONFIG
    CREATECONFIGREQUEST , CREATECONFIGSUCCESS , CREATECONFIGERROR , 
    FETCHCONFIGREQUEST ,FETCHCONFIGSUCCESS ,FETCHCONFIGERROR, CREATECATEGORYERROR 

} from './actionTypes';
  import * as configurationsFuncs from './../../api/configurations';
  
  
  
  export function  createConfigRequest() {
      return {
        type: CREATECONFIGREQUEST,
        payload:{}
      };
    }
     
    export function createConfigSuccess(data) {
      return {
        type: CREATECONFIGSUCCESS,
        payload : data,
      };
    }
     
    export function  createConfigError(message) {
        return {
          type: CREATECONFIGERROR,
          payload : { message },
        };
    }
  
    export function  fetchConfigRequest() {
      return {
        type: FETCHCONFIGREQUEST,
        payload:{}
      };
    }
     
    export function fetchConfigSuccess(data) {
      return {
        type: FETCHCONFIGSUCCESS,
        payload : data,
      };
    }
     
    export function fetchConfigError(message) {
        return {
          type: FETCHCONFIGERROR,
          payload : { message },
        };
    }
  
  export const createConfigurations = ( type, data ) =>{
    return (dispatch) => {
        dispatch(createConfigRequest());
        console.log('premium content data', type, data)
        configurationsFuncs.createConfig(type, data).then((res)=>{
            console.log('responseData*****************************', res)
            if(res.status === 200) return dispatch(createConfigSuccess(res.data));
            if(res.status === 400) return dispatch(createConfigError(res.data.message));
            if(res.status === 401) return dispatch(createConfigError(res.data.message));
            if(res.status === 409) return dispatch(createConfigError(res.data.message));
  
        }).catch((err)=>{
            console.log('err', err)
            return dispatch(createConfigError(err.message));
        })
    }
  }
  
  
  export const fetchConfigrations = () =>{
    return (dispatch) => {
        dispatch(fetchConfigRequest());
        configurationsFuncs.fetchConfig().then((res)=>{
            console.log('responseData*****************************', res)
            if(res.status === 200) return dispatch(fetchConfigSuccess(res.data));
            if(res.status === 400) return dispatch(fetchConfigError(res.data.message));
            if(res.status === 401) return dispatch(fetchConfigError(res.data.message));
            if(res.status === 409) return dispatch(fetchConfigError(res.data.message));
  
        }).catch((err)=>{
            console.log('err', err)
            return dispatch(fetchConfigError(err.message));
        })
    }
  }
  