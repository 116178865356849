import * as Actions from './actionTypes';
import { getDashboardStats } from './../../api/dashboardApi';

function  fetchDashboardRequest() {
  return {
    type: Actions.FETCHDASHBOARDSTATSREQUEST,
    payload:{}
  };
}
     
function fetchDashboardSuccess(data) {
  return {
    type: Actions.FETCHDASHBOARDSTATSSUCCESS,
    payload : data,
  };
}
     
function  fetchDashboardError(message) {
    return {
      type: Actions.FETCHDASHBOARDSTATSERROR,
      payload : { message },
    };
}

function authError(message) {
    return {
      type: Actions.LOGINERROR,
      payload : { message },
    };
}


export const getAllDashboardStats = (page, limit, searchkey) =>{
  return (dispatch) => {
      dispatch(fetchDashboardRequest());
      getDashboardStats().then((res)=>{
          console.log('new DASHBOARDSTATS', res);
          if(res.status === 200) return dispatch(fetchDashboardSuccess(res.data));
          if(res.status === 400) return dispatch(fetchDashboardError(res.data.message));
          if(res.status === 401) return dispatch(authError(res.data.message));
          if(res.status === 409) return dispatch(authError(res.data.message));
      }).catch((err)=>{
          console.log('err', err)
          return dispatch(fetchDashboardError(err.message));
      })
  }
}
