import React from 'react';
import {connect} from 'react-redux';
import Topbar from './topbar/Topbar';
import Sidebar from './sidebar/Sidebar';
import {logOutAction}  from './../../redux/actions/loginActions'

let Layout = (props)=> {
  //console.log('props in topbar', props)
  return (
    <div>
        {/* <Customizer/> */}
        <Topbar {...props}/>
        <Sidebar {...props}/>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    customizer: state.customizer,
    user: state.login
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    _logout : () => {
      return dispatch(logOutAction());
    },
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(Layout);