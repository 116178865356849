// import { ADDADMIN, ERROR, FORGETPASSWORD } from '../actions/ADDADMIN_action';
import { UPDATEPROFILECLEAR, UPDATEPROFILEERROR, UPDATEPROFILEREQUEST, UPDATEPROFILESUCCESS } from './../actions/actionTypes'

const initialState = {
    requestDone: false,
    isupdated: false,
    isFetching:false,
    err : false,
    message:'',
  };
  
  // receiving response sent by action according to type of action
  export default function updateProfileReducer(state = initialState, action) {
      //console.log(action,'action')
      let { payload } = action
    switch (action.type) {

      case UPDATEPROFILECLEAR:
          return { 
            requestDone: false,
            isupdated: false,
            isFetching:false,
            err : false,
            message:'',
           };
      break;
      case UPDATEPROFILEREQUEST:
          return { 
            requestDone: false,
            isupdated: false,
            isFetching:true,
            err : false,
            message:'',
           };
          break;
      case UPDATEPROFILESUCCESS:
        return { 
            requestDone: true,
            isupdated: true,
            isFetching:false,
            err : false,
            message:payload.message,
        };
        break;
  
        case UPDATEPROFILEERROR:
        return { 
            requestDone: true,
            isupdated: false,
            isFetching:false,
            err : true,
            message: payload.message,
        };
        break;
  
      default:
        return state;
    }
  }
  