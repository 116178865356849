import { connect } from "react-redux";
import * as _ from "lodash";
import * as actions from "../../redux/actions/actionTypes";
import { listAllFeelings, addFeelings, updateFeeling, removeFeeling } from '../../redux/actions/feelingsActions';
import AllFeelings from '../../components/Feelings/ListFeelings'

const mapStateToProps = state => {
  // console.clear()
  //console.log('state in container', state);
  return {
    feelings: state.feelings, 
    addFeelings: state.category ,
    updateFeeling : state.updateFeeling,
    deleteFeeling : state.deleteFeeling,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    _fetchFeelings: () => {
      return dispatch(listAllFeelings());
    },
    _addFeelings : (titleData) =>{
      return dispatch(addFeelings(titleData))
    },
    _updateFeeling : (id, data) => {
      return dispatch(updateFeeling( id, data ))
    },
    _deleteFeeling : (id) => {
      return dispatch( removeFeeling(id) )
    }

  };
};


const FeelingsContainer = connect(mapStateToProps, mapDispatchToProps)(AllFeelings);

export default FeelingsContainer;
