import * as Actions from './actionTypes';
import { getdonationsStats } from './../../api/donationsApi';

function  fetchdonationsRequest() {
  return {
    type: Actions.FETCHDONATIONSTATSREQUEST,
    payload:{}
  };
}
     
function fetchdonationsSuccess(data) {
  return {
    type: Actions.FETCHDONATIONSSTATSSUCCESS,
    payload : data,
  };
}
     
function  fetchdonationsError(message) {
    return {
      type: Actions.FETCHDONATIONSSTATSERROR,
      payload : { message },
    };
}

function authError(message) {
    return {
      type: Actions.LOGINERROR,
      payload : { message },
    };
}


export const getAlldonationsStats = (page, limit, amountSort, dateSort, from_date, to_date) =>{
  return (dispatch) => {
      dispatch(fetchdonationsRequest());
      getdonationsStats(page, limit, amountSort, dateSort, from_date, to_date).then((res)=>{
          console.log('new donationsSTATS', res);
          if(res.status === 200) return dispatch(fetchdonationsSuccess(res.data));
          if(res.status === 400) return dispatch(fetchdonationsError(res.data.message));
          if(res.status === 401) return dispatch(authError(res.data.message));
          if(res.status === 409) return dispatch(authError(res.data.message));
      }).catch((err)=>{
          console.log('err', err)
          return dispatch(fetchdonationsError(err.message));
      })
  }
}
