import axios from 'axios'
import _ from 'lodash'
import { AUTHORIZATION_HEADER, AUTHTOKEN } from './../inc/config'
export const CancelToken = axios.CancelToken;
// const source = CancelToken.source();
const fetchClient = () => {
  let instance = axios.create({
    validateStatus: function (status) {
        return (status >= 200 && status <= 204) || status === 401 || status === 400 || status === 409;
    },
})
  instance.interceptors.request.use((config) => {
    //console.log('config', config);
    const token = localStorage.getItem(AUTHTOKEN)
    //console.log('localStorage.getItem(AUTHORIZATION)', token)
    if (!_.isEmpty(token)) {
      config.headers[AUTHORIZATION_HEADER] = token
      // config.cancelToken = source.token
      // console.log('config', config)
    }
    return config
  })

  instance.interceptors.response.use((response) => {
    try{
      //console.log('response in axios config', response)
      if (response.data[AUTHTOKEN]) {
        // console.log(response.data['user-api-key'])
        const token = response.data[AUTHTOKEN];
        const loggedUserRole = response.data.user.role[0]
        localStorage.setItem(AUTHTOKEN, token)
        localStorage.setItem("UserRole", loggedUserRole)
      }
      return response
    }catch(err){
      console.log('error in axios config', err )
    }
  })
  // source.cancel('Operation canceled by the user.');
  return instance
}

export default fetchClient()
