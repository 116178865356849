import { GETFINDERMEETINGERROR, GETFINDERMEETINGREQUEST, GETFINDERMEETINGSUCCESS } from './../actions/actionTypes'
 
const initialState = {
    requestDone: false,
    isFetched: false,
    isFetching : false,
    err : false,
    message:'',
    finderMeeting: {},
    total : 0,
  };
  
  // receiving response sent by action according to type of action
  export default function getFinderMeeting(state = initialState, action) {
    let { payload } = action
    switch (action.type) {
        case GETFINDERMEETINGREQUEST:
          return { 
            requestDone: false,
            isFetched: false,
            isFetching : true,
            err : false,
            message:'',
            finderMeeting: {},
            total : 0
           };
          break;
        case GETFINDERMEETINGSUCCESS:
        return { 
            requestDone: true,
            isFetched: true,
            isFetching : false,
            err : false,
            message:'',
            finderMeeting: payload.data,
        };
        break;
  
        case GETFINDERMEETINGERROR:
        return { 
            requestDone: true,
            isFetched: false,
            isFetching : false,
            err : true,
            message: payload.message,
            finderMeeting : {}
        };
        break;
        
      default:
        return state;
    }
  }
