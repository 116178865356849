import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { AUTHTOKEN } from './../inc/config';
import { VALID_URLS_ARRAY, VIEW_ONLY_URLS } from './../inc/constants';
import MainWrapper from './MainWrapper';
import AuthRoutes from './Routing/AuthRoutes'
import UnAuthRoutes from './Routing/UnAuthRoutes'
import MainRoutes from './Routing/MainRoutes';
import NotFound404 from './../containers/default_page/404/404'
import { connect } from 'react-redux';
import { checkLoginAction } from './../redux/actions/loginActions';

class Router extends Component {

  state = {
        isAuthenticated: false,
        checked:false,
        permissions : []
      }

  componentDidMount(){
    let authToken = localStorage.getItem(AUTHTOKEN); 
    window.addEventListener('storage', () => {
      console.log('localstorage modified',window.localStorage.getItem('user-auth-token'));
      authToken = window.localStorage.getItem(AUTHTOKEN);
      return this.props._checkAuth(authToken);  
    });
    //console.log(AUTHTOKEN, authToken)
    //console.log('let authToken = localStorage.getItem', localStorage.getItem('user-auth-token'))
    if( authToken !== null && authToken !== undefined ){
      return this.props._checkAuth(authToken);
    }else{
      this.setState({
        checked:true,
        isAuthenticated: false,
      })
    }
  }
  
  static getDerivedStateFromProps(props, state){
    //console.log('nextProps login', props);
    let checked = false;
    let isAuthenticated = false
    let permissions = []
    if(props.loginStates.authDone){
      checked = true;
      if( props.loginStates.isLoggedIn){
        isAuthenticated = true;
        permissions = (!props.loginStates.user.permissions || props.loginStates.user.permissions.length === 0) ? ['ALL'] : props.loginStates.user.permissions
        //console.log('permissions', permissions)
      }
      return { checked, isAuthenticated, permissions }
    }
    return null;
  }

  render(){
    const { match, location } = this.props;
    const allowRedirect = match && match.url !== location && location.pathname;
    let data = this.state;
    //console.log('location.pathname', location)
    //console.log('match', match)
    const validRoute = VALID_URLS_ARRAY.includes(location.pathname)
    if(!validRoute){
      return (
        <Redirect
          to={{
            pathname: "/dashboard"
          }}
      />
      )
    }
    console.log("location.pathname", location.pathname)
    const viewOnlyRoutes = VIEW_ONLY_URLS.includes(location.pathname)
    console.log("viewOnlyRoutes", viewOnlyRoutes)
    if(!viewOnlyRoutes && window.localStorage.getItem('UserRole') === "view-only"){
      return (
        <Redirect
          to={{
            pathname: "/dashboard"
          }}
      />
      )
    }
    return(
      <MainWrapper>
        <main>
              <Switch>
                <Route exact path='/login' {...this.state} render={(props)=> {return <UnAuthRoutes {...data} {...props}  /> } }/>
                <Route exact path='/forgot-password' {...this.state} render={(props)=> {return <UnAuthRoutes {...data} {...props}  /> } }/>
                <AuthRoutes path='/' {...this.state} ComponentRender={MainRoutes}/>
                <Route component={ NotFound404 } />
                {allowRedirect && <Redirect to={`${match.url}`} />}
              </Switch>
        </main>
      </MainWrapper>
    )
  }
}

let mapStatetoProps = (state) => {
  return { loginStates : state.login }
}

let mapDispatchToProps = (dispatch) =>{
  return {
    _checkAuth : (token) => {
      dispatch(checkLoginAction(token))
    }
  }
}

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(Router))