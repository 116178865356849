import { wrappedFetch, wrappedGet } from './urlFuncs';
import { POST_PREMIUM_CONTENT_URL, GENERATE_USER_REPORTS, GENERATE_MEETINGS_REPORTS, GENERATE_CONTRIBUTIONS_REPORTS } from './../inc/constants';
import { API_URL } from './../inc/config';

export const fetchContentList = (page, limit, fDate, tDate, lang) =>{
return wrappedGet(`${API_URL}${POST_PREMIUM_CONTENT_URL}?page=${page}&limit=${limit}&fDate=${fDate}&tDate=${tDate}&lang=${lang}`, 'GET');
}

export const generateUserReport = (from , to) =>{
  let data = {
    from : from,
    to : to
  }
  return wrappedFetch(`${API_URL}${GENERATE_USER_REPORTS}`, 'POST',data);
}

export const generateMeetingReport = (from , to) =>{
  let data = {
    from : from,
    to : to
  }
  return wrappedFetch(`${API_URL}${GENERATE_MEETINGS_REPORTS}`, 'POST',data);
}

export const generateContributionsReport = (from , to) =>{
  let data = {
    from : from,
    to : to
  }
  return wrappedFetch(`${API_URL}${GENERATE_CONTRIBUTIONS_REPORTS}`, 'POST',data);
}


