import * as Actions from './actionTypes';
import * as contentFuncs from './../../api/premiumContentApi';



export function  createContentRequest() {
    return {
      type: Actions.CREATECONTENTREQUEST,
      payload:{}
    };
  }
   
export function createContentSuccess(data) {
  return {
    type: Actions.CREATECONTENTSUCCESS,
    payload : data,
  };
}
  
export function  createContentError(message) {
  return {
    type: Actions.CREATECONTENTERROR,
    payload : { message },
  };
}

export function  fetchContentRequest() {
  return {
    type: Actions.FETCHTCREQUEST,
    payload:{}
  };
}
  
export function fetchContentSuccess(data) {
  return {
    type: Actions.FETCHTCSUCCESS,
    payload : data,
  };
}
  
export function  fetchContentError(message) {
    return {
      type: Actions.FETCHTCERROR,
      payload : { message },
    };
}

export function  deleteContentRequest() {
  return {
      type: Actions.DELETECONTENTREQUEST,
      payload:{}
  };
}
    
export function deleteContentSuccess(data) {
    return {
        type: Actions.DELETECONTENTSUCCESS,
        payload : data,
    };
}
    
export function  deleteContentError(message) {
      return {
        type: Actions.DELETECONTENTERROR,
        payload : { message },
    };
}
  
   


export const postTCContent = (data) =>{
  return (dispatch) => {
      dispatch(createContentRequest());
      console.log('premium content data', data)
      contentFuncs.updateTermsAndConditions(data).then((res)=>{
          console.log('responseData*****************************', res)
          if(res.status === 200) return dispatch(createContentSuccess(res.data));
          if(res.status === 400) return dispatch(createContentError(res.data.message));
          if(res.status === 401) return dispatch(createContentError(res.data.message));
          if(res.status === 409) return dispatch(createContentError(res.data.message));

      }).catch((err)=>{
          console.log('err', err)
          return dispatch(createContentError(err.message));
      })
  }
}

export const fetchTCContent = () =>{
  return (dispatch) => {
      dispatch(fetchContentRequest());
      console.log('premium content data',)
      contentFuncs.fetchTConditions().then((res)=>{
          console.log('responseData*****************************', res)
          if(res.status === 200) return dispatch(fetchContentSuccess(res.data));
          if(res.status === 400) return dispatch(fetchContentError(res.data.message));
          if(res.status === 401) return dispatch(fetchContentError(res.data.message));
          if(res.status === 409) return dispatch(fetchContentError(res.data.message));

      }).catch((err)=>{
          console.log('err', err)
          return dispatch(fetchContentError(err.message));
      })
  }
}
