import * as Actions from '../actions/actionTypes';

const initialState = {
    isFetching : false,
    err : false,
    isFetched : false,
    message : ''
  };

  // receiving response sent by action according to type of action
  export default function uploadResourcesReducer(state = initialState, action) {
    //console.log(action,'action')
  switch (action.type) {
    case Actions.UPLOADRESOURCESREQUEST:
      return { 
          isFetching : true,
          err : false,
          isFetched : false,
          message : 'fetching...'
      };
      break;

    case Actions.UPLOADRESOURCESSUCCESS:
      return { 
          isFetching : false,
          err : false,
          isFetched : true,
          message : action.payload.message
          
        };
        break;
        
    case Actions.UPLOADRESOURCESERROR:
        return { 
            isFetching : false,
            err : true,
            isFetched : false,
            message : action.payload.message
        };
      break;
    
    default:
      return state;
  }
}  