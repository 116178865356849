// import { ADDADMIN, ERROR, FORGETPASSWORD } from '../actions/ADDADMIN_action';
import { APPUSERSLISTCLEAR, APPUSERSLISTERROR, APPUSERSLISTREQUEST, APPUSERSLISTSUCCESS ,APPUSERSEARCHREQUEST,APPUSERSEARCHSUCCESS,APPUSERSEARCHERROR} from './../actions/actionTypes'
 
const initialState = {
    requestDone: false,
    isFetched: false,
    isFetching : false,
    err : false,
    message:'',
    users: [],
    total : 0,
  };
  
  // receiving response sent by action according to type of action
  export default function addAdminReducers(state = initialState, action) {
      //console.log(action,'action')
      let { payload } = action
    switch (action.type) {
        case APPUSERSLISTCLEAR:
          return { 
            requestDone: false,
            isFetched: false,
            isFetching : false,
            err : false,
            message:'',
            users: [],
            total :0
           };
          break;
        case APPUSERSLISTREQUEST:
          return { 
            requestDone: false,
            isFetched: false,
            isFetching : true,
            err : false,
            message:'',
            users: [],
            total : 0
           };
          break;
        case APPUSERSLISTSUCCESS:
            //console.log('in switch', payload.data)
        return { 
            requestDone: true,
            isFetched: true,
            isFetching : false,
            err : false,
            message:'',
            users: payload.data,
            total : payload.count
        };
        break;
  
        case APPUSERSLISTERROR:
        return { 
            requestDone: true,
            isFetched: false,
            isFetching : false,
            err : true,
            message: payload.message,
            users: [],
            total : 0
        };
        break;
        case APPUSERSEARCHREQUEST:
          return { 
            requestDone: false,
            isFetched: false,
            isFetching : true,
            err : false,
            message:'',
            users: [],
            total : 0
           };
          break;
        case APPUSERSEARCHSUCCESS:
            //console.log('in switch', payload.data)
        return { 
            requestDone: true,
            isFetched: true,
            isFetching : false,
            err : false,
            message:'',
            users: payload.data,
            total : payload.count
        };
        break;
  
        case APPUSERSEARCHERROR:
        return { 
            requestDone: true,
            isFetched: false,
            isFetching : false,
            err : true,
            message: payload.message,
            users: [],
            total : 0
        };
        break;
      default:
        return state;
    }
  }
