import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  Form,
  FormGroup,
} from "reactstrap";
import TableComponent from "./../Shared/Table/Table";
import notification from "../../helpers/notificationHelper";
import EyeIcon from "mdi-react/EyeIcon";
import PenIcon from "mdi-react/PencilIcon";
import ViewFinderMeeting from "./ViewFinderMeeting";
import EditFinderMeeting from "./EditFinderMeeting";
import UploadFinderMeeting from "./UploadFinderMeeting";
import { Translate, Info } from "@material-ui/icons";
import { GET_FINDER_MEETINGS_DETAILS } from "../../inc/constants";
import { FINDER_URL } from "../../inc/config";
import TablePagination from "./../Shared/Table/TablePagination";
import { Grid } from "@material-ui/core";

class ListFinderMeetings extends React.Component {
  state = {
    headsAg: [],
    meetings: [],
    total: 0,
    limit: 10,
    page: 1,
    modalView: false,
    modalEdit: false,
    modalUpload: false,
    name: "",
    datetime: "",
    timezone: "",
    notes: "",
    tags: "",
    formats: "",
    types: "",
    meetingId: "",
    language: "",
    meeting: {},
    modal: "",
    autofocus: false,
    isMeetingFetched: false,
    files: "",
    search: "",
    configs : {}
  };



  componentDidMount() {
    let { page, limit, search } = this.state;
    this.props._fetchfindermeetings(page, limit, search);

    let headsAg = [
      {
        headerName: "Sr. No",
        field: "i",
        width: "80",
        sortable: true,
        filter: "text",
        cellRendererFramework: this.renderIndex,
      },
      { headerName: "Name", field: "name", suppressSizeToFit: true },
      { headerName: "Date-Time", field: "times", suppressSizeToFit: true },
      { headerName: "Timezone", field: "timezone", suppressSizeToFit: true },
      {
        headerName: "Actions",
        cellRendererFramework: this.renderAction,
        cellStyle: (params) => {
          return { textAlign: "center" };
        },
      },
    ];
    this.setState({ headsAg });
  }

  renderIndex = (record) => {
    return record.rowIndex + 1;
  };

  renderAction = (record) => {
    let buttons = (
      <span>
        <EyeIcon
          onClick={() => {
            this.viewDataInAnotherLanguage(record.data);
          }}
          size={"18px"}
          className="cursor-pointer"
        >
          In another language
        </EyeIcon>
        <Info
          onClick={() => {
            this.viewData(record.data);
          }}
          fontSize="small"
          className="cursor-pointer"
        />
        <PenIcon
          className="cursor-pointer ml-2"
          size={18}
          onClick={() => {
            this.editData(record.data);
          }}
        />
        <Translate
          onClick={() => {
            this.translateData(record.data);
          }}
          size={"18px"}
        ></Translate>
      </span>
    );
    return buttons;
  };

  static getDerivedStateFromProps(props, state) {
    console.log("props", props.findermeetings.configs);
    if (
      props.findermeetings.finderMeetings &&
      props.findermeetings.finderMeetings !== state.meetings &&
      !props.findermeetings.isFetching &&
      props.findermeetings.isFetched
    ) {
      let meets = props.findermeetings.finderMeetings;
      let total = props.findermeetings.total;
      let configs = props.findermeetings.configs.configs
      return {
        meetings: meets,
        total,
        configs
      };
    }

    if (
      props.updateFinderMeetReducer.isFetched &&
      !props.updateFinderMeetReducer.isFetching
    ) {
      //notification("success", "Meeting updated")
      return { isMeetingFetched: false, modalEdit: false };
    }

    if (
      props.uploadFinderMeetings.isFetched &&
      !props.uploadFinderMeetings.isFetching
    ) {
      //notification("success", "Meetings uploaded")
      return { isMeetingFetched: false, modalUpload: false };
    }

    if (state.meetings.length) {
      return {
        meetings: state.meetings,
      };
    }
    return {
      meetings: [],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    let {
      translateSingleMeet,
      translateFinderMeets,
      updateFinderMeetReducer,
      finderMeeting,
    } = this.props;

    if (
      translateSingleMeet.isFetched &&
      !translateSingleMeet.isFetching &&
      !finderMeeting.isFetched
    ) {
      notification("success", translateSingleMeet.message);
    }

    if (
      translateFinderMeets.isFetched &&
      !translateFinderMeets.isFetching &&
      !finderMeeting.isFetched
    ) {
      notification("success", translateFinderMeets.message);
    }

    if (!translateFinderMeets.isFetched && translateFinderMeets.err) {
      notification("error", translateFinderMeets.message);
    }
  }

  translateData = (data) => {
    let { _id } = data;
    if (!this.state.language) {
      notification("error", "Select a language first !");
    } else {
      this.props._translateSingleFinderMeeting({
        meetingId: _id,
        language: this.state.language,
      });
    }
  };

  viewData = (data) => {
    let { name, times, timezone, notes, tags, formats, types } = data;
    this.setState({
      modalView: true,
      name,
      datetime: times,
      timezone,
      notes,
      tags,
      formats,
      types,
    });
  };

  editData = (data) => {
    console.log("editData");
    let { _id } = data;
    let { finderMeeting } = this.props.finderMeeting;
    if (Object.keys(finderMeeting).length) {
      if (_id === finderMeeting.meetingId) {
        console.log("LLLLLLLLLLLLL");
      }
    } else {
      if (!this.state.language) {
        notification("error", "Select a language first !");
      } else {
        this.setState({ modal: "edit", meetingId: _id });
        fetch(
          FINDER_URL +
            GET_FINDER_MEETINGS_DETAILS +
            "?language=" +
            this.state.language +
            "&meetingId=" +
            _id
        )
          .then((x) => x.json())
          .then((result) => {
            if (result.data) {
              let { tags, formats, types, notes, name } = result.data;
              this.setState({
                name,
                notes,
                tags,
                formats,
                types,
                modalEdit: true,
              });
            } else {
              notification(
                "error",
                "no translation for meeting in selected language"
              );
            }
          });
        //this.props._fetchfindermeetingDetails(_id,this.state.language)
      }
    }
  };

  viewDataInAnotherLanguage = (data) => {
    console.log("viewDataInAnotherLanguage");
    let { _id } = data;
    if (!this.state.language) {
      notification("error", "Select a language first !");
    } else {
      this.setState({ modal: "view", autofocus: true });
      fetch(
        FINDER_URL +
          GET_FINDER_MEETINGS_DETAILS +
          "?language=" +
          this.state.language +
          "&meetingId=" +
          _id
      )
        .then((x) => x.json())
        .then((result) => {
          if (result.data) {
            let { tags, formats, types, notes, name } = result.data;
            this.setState({
              name,
              notes,
              tags,
              formats,
              types,
              modalView: true,
            });
          } else {
            notification(
              "error",
              "no translation for meeting in selected language"
            );
          }
        });
    }
  };

  uploadFinderMeetingsModal = () => {
    this.setState({ modalUpload: true });
  };

  setLanguage = (e) => {
    console.log("t", e.target.value);
    this.setState({ language: e.target.value });
  };

  toggleViewModal = (e) => {
    this.setState((prevState) => {
      return {
        modalView: !prevState.modalView,
      };
    });
  };

  toggleEditModal = (e) => {
    this.setState((prevState) => {
      return {
        modalEdit: !prevState.modalEdit,
      };
    });
  };

  toggleUploadModal = (e) => {
    this.setState((prevState) => {
      return {
        modalUpload: !prevState.modalUpload,
      };
    });
  };

  onChangeHandler = (e) => {
    let { name, value } = e.target;
    let stateValues = {};
    switch (name) {
      case "notes":
        stateValues.notes = value;
        break;

      case "tags":
        stateValues.tags = value;
        break;

      case "formats":
        stateValues.formats = value;
        break;

      default:
        stateValues.types = value;
        break;
    }
    console.log("stateValues", stateValues);
    this.setState(stateValues);
  };

  updateFinderMeeting = (e) => {
    e.preventDefault();
    let { tags, formats, notes, types, meetingId } = this.state;
    //this.setState({isMeetingFetched : false, language : "", modalEdit :false })
    this.props._updatefindermeeting({
      meetingId,
      tags,
      formats,
      notes,
      types,
    });
  };

  handleChange = (e) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = (e) => {
      console.log("e.target.result", e.target.result);
      this.setState({ files: e.target.result });
    };
  };

  uploadMeetings = (e) => {
    e.preventDefault();
    let { files } = this.state;
    this.props._uploadfindermeetings({ meetings: JSON.parse(files) });
  };

  translateFinderMeetings = () => {
    if (!this.state.language) {
      notification("error", "Select a language first !");
    } else {
      this.props._translatefindermeetings({ language: this.state.language });
    }
  };

  onPageChange = (pagenum) => {
    console.log("ONPAGECHANGE");
    console.log("page num: ", pagenum);
    // setPage(pagenum);
    this.setState({ page: pagenum });
    this.props._fetchfindermeetings(
      pagenum,
      this.state.limit,
      this.state.search
    );
    // props.fetchUsers(page, limit);
  };

  searchHandler = (e) => {
    this.setState({ search: e.target.value });
    this.props._fetchfindermeetings(
      this.state.page,
      this.state.limit,
      e.target.value
    );
  };

  render() {
    let {
      headsAg,
      meetings,
      modalView,
      datetime,
      timezone,
      name,
      notes,
      tags,
      formats,
      types,
      modalEdit,
      meetingId,
      autofocus,
      language,
      modalUpload,
      page,
      limit,
      total,
      configs
    } = this.state;
    const languages = {
      "bg" : "Bulgarian",
      "cs" : "Czech",
      "en" : "English",
      "es" : "Spanish",
      "fa" : "Farsi(Perisan)",
      "fr" : "French",
      "hi" : "Hindi",
      "hu" : "Hungarian",
      "is" : "Icelandic",
      "it" : "Italian",
      "ja" : "Japanense",
      "nl" : "Dutch",
      "pl" : "Polish",
      "pt" : "Portuguese",
      "ru" : "Russian",
      "tr" : "Turkish"
    }

    return (
      <Container>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <h3 className="page-title bb-1 border-gray pb-2">
                  Finder Meetings
                </h3>
                <Row className="mt-2">
                  <div
                    className="flex-wrap"
                    style={{ width: "100%", gap: "14px" }}
                  >
                    <div style={{ height: "100%" }}>
                      <Input
                        bsSize="sm"
                        value={language}
                        // className="mb-3"
                        onChange={this.setLanguage}
                        id="lang"
                        name="language"
                        type="select"
                        style={{ margin: "0", height: "100%" }}
                      >
                        <option value="">--select language--</option>
                        <option value="bg">Bulgarian</option>
                        <option value="cs">Czech</option>
                        <option value="en">English</option>
                        <option value="es">Spanish</option>
                        <option value="fa">Farsi(Perisan)</option>
                        <option value="fr">French</option>
                        <option value="hi">Hindi</option>
                        <option value="hu">Hungarian</option>
                        <option value="is">Icelandic</option>
                        <option value="it">Italian</option>
                        <option value="ja">Japanense</option>
                        <option value="nl">Dutch</option>
                        <option value="pl">Polish</option>
                        <option value="pt">Portuguese</option>
                        <option value="ru">Russian</option>
                        <option value="tr">Turkish</option>
                      </Input>
                    </div>

                    {/* <Col md={{ 'size' : 3 }} className="text-left pt-2"> */}
                    <div style={{ height: "100%" }}>
                      <Form className="text-left" style={{ height: "100%" }}>
                        <FormGroup style={{ margin: "0", height: "100%" }}>
                          <Input
                            type="search"
                            name="search"
                            id="exampleEmail"
                            placeholder="Search meetings"
                            onChange={(e) => this.searchHandler(e)}
                            style={{ height: "100%" }}
                          />
                        </FormGroup>
                      </Form>
                    </div>
                    {/* </Col> */}
                    <div>
                      <Button
                        color="primary"
                        htmlFor="add"
                        onClick={this.uploadFinderMeetingsModal}
                        style={{ margin: "0", height: "100%" }}
                      >
                        {" "}
                        Upload New File
                      </Button>
                    </div>
                    <div>
                      <Button
                        color="primary"
                        htmlFor="add"
                        onClick={this.translateFinderMeetings}
                        style={{ margin: "0", height: "100%" }}
                      >
                        Translate
                      </Button>
                    </div>
                  </div>
                  <Col
                    md={12}
                    style={{ height: "100vh", width: "600px" }}
                    className="ag-theme-material"
                  >
                    <div style={{marginTop : "2%"}} >
                     <span>
                     <b>
                      {
                        configs && configs.languages && configs.languages.length ?  "Translated Languages : " + configs.languages.map((l)=>{return languages[l]}).toString() : "No language translated"
                      }
                    </b>
                    </span> 
                    <br></br><br></br>
                    <span>
                      <p>
                      Last Translation Status :
                      {configs && configs.status === "inprogress"  ? "Translation in progress" : ""}
                      {configs && configs.status === "completed"  ? "Translation already completed" : ""}
                    </p>
                    </span>
                    </div>
                    <TableComponent
                      rowsData={meetings}
                      headsAg={headsAg}
                      rowSelectable={"multiple"}
                      rowsPerPage={20}
                      isFetched={true}
                      {...this.props}
                      noRecordsText={"No Records Found!"}
                      fetchingRecordsText={"Fetching Meetings..."}
                    />
                    <Col md={12} className="text-right mt-2">
                      <div style={{ height: "100%" }}>
                        <TablePagination
                          onPageChange={this.onPageChange}
                          page={page}
                          limit={limit}
                          total={total}
                        />
                      </div>
                    </Col>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <EditFinderMeeting
              modal={modalEdit}
              toggle={this.toggleEditModal}
              name={name}
              datetime={datetime}
              timezone={timezone}
              notes={notes}
              tags={tags}
              formats={formats}
              types={types}
              meetingId={meetingId}
              onChangeHandler={this.onChangeHandler}
              updateFinderMeeting={this.updateFinderMeeting}
            />
            <ViewFinderMeeting
              modal={modalView}
              toggle={this.toggleViewModal}
              name={name}
              datetime={datetime}
              timezone={timezone}
              notes={notes}
              tags={tags}
              formats={formats}
              types={types}
              autofocus={autofocus}
            />
            <UploadFinderMeeting
              modal={modalUpload}
              toggle={this.toggleUploadModal}
              uploadMeetings={this.uploadMeetings}
              handleChange={this.handleChange}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ListFinderMeetings;
