// import { ADDADMIN, ERROR, FORGETPASSWORD } from '../actions/ADDADMIN_action';
import {ADMINLISTREQUEST, ADMINLISTSUCCESS, ADMINLISTCLEAR, ADDADMINERROR, SEARCHREQUEST, SEARCHSUCCESS, SEARCHERROR } from './../actions/actionTypes'

const initialState = {
    requestDone: false,
    isFetched: false,
    isFetching : false,
    err : false,
    message:'',
    users: [],
    total : 0
  };
  
  // receiving response sent by action according to type of action
  export default function addAdminReducers(state = initialState, action) {
      //console.log(action,'action')
      let { payload } = action
    switch (action.type) {
        case ADMINLISTCLEAR:
          return { 
            requestDone: false,
            isFetched: false,
            isFetching : false,
            err : false,
            message:'',
            users: []
           };
          break;
        case ADMINLISTREQUEST:
          return { 
            requestDone: false,
            isFetched: false,
            isFetching : true,
            err : false,
            message:'',
            users: []
           };
          break;
        case ADMINLISTSUCCESS:
            //console.log('in switch', payload)
        return { 
            requestDone: true,
            isFetched: true,
            isFetching : false,
            err : false,
            message:'',
            users: payload.data,
            total : payload.count
        };
        break;
  
        case ADDADMINERROR:
        return { 
            requestDone: true,
            isFetched: false,
            isFetching : false,
            err : true,
            message: payload.message,
            users: []
        };
        break;

        case SEARCHREQUEST:
          return { 
            requestDone: false,
            isFetched: false,
            isFetching : true,
            err : false,
            message:'',
            users: []
           };
          break;
        case SEARCHSUCCESS:
        return { 
            requestDone: true,
            isFetched: true,
            isFetching : false,
            err : false,
            message:'',
            users: payload.data,
            total : payload.count
        };
        break;
  
        case SEARCHERROR:
        return { 
            requestDone: true,
            isFetched: false,
            isFetching : false,
            err : true,
            message: payload.message,
            users: []
        };
        break;
  
      
      default:
        return state;
    }
  }
  