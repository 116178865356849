import React from 'react';
import { Container, Row, Col, Card, CardBody, CustomInput, Button, FormGroup,Form, Input, Label } from 'reactstrap';
import TableComponent from './../Shared/Table/Table';
import TablePagination from './../Shared/Table/TablePagination';
import moment from 'moment';
import notify from './../../helpers/notificationHelper';
import fx from 'money';

class Donations extends React.Component{
    state={
        headsAg : [],
        isFetching : false,
        page : 1,
        limit : 100,
        donations : [],
        selectedRecord : '',
        total:0,
        totalamout : 0,
        search:'',
        amountSort : 'desc',
        dateSort : 'new',
        isFetched : false,
        from_date : '',
        to_date : '',
        filter_type : '',
        fromdate : '',
        todate : ''
    }
    
    componentDidMount(){
        this.fetchDonations();
        let headsAg = [
            {headerName: "Sr. No", field: "i", width:"120", sortable:true, filter:'text', 
                cellRendererFramework : this.renderIndex 
            },
            {headerName: "First Name", field: "user.firstname", sortable:true, filter:'text', width : 170, height : 50 },
            {headerName: "Last Name", field: "user.lastname", sortable:true, filter:'text', width : 170 },
            // {headerName: "Username", field: "username", sortable:true, filter:'text'},
            {headerName: "Email", field: "user.email.mail", sortable:true, filter:'text' , width : 170},
            {headerName: "Amount", field: "amount", sortable:true, filter:'text', width : 170, 
                cellRendererFramework : this.renderAmount
            },

            {
                headerName: "Contributed On", field: "createdAt", sortable:true, 
                cellRendererFramework : this.renderDate, 
                filter:'text'
            },
        ]
        let data = {
            headsAg
        }
        this.setState({  headsAg })
    }
    
    static getDerivedStateFromProps(props, state){
        console.log("***check fpr the props and state",props,state);
        if(props.doinationsList && props.doinationsList.data !== state.donations && props.doinationsList.isFetching && !props.doinationsList.isFetched){
            return {
               isFetching : true,
               isFetched : false
            }
        }
        if(props.doinationsList && props.doinationsList.data !== state.donations && !props.doinationsList.isFetching && props.doinationsList.isFetched){

                return {
                    donations : props.doinationsList.data,
                    // total : props.users.total,
                    page : state.page,
                    isFetching : false,
                    isFetched : true,
                    totalamout : props.doinationsList.totalAmount
                }
        }else{
            return null
        }
        return null
    }

    componentDidUpdate(prevProps, prevState){
        // console.log('updated props', prevProps);
        // console.log('updated props', this.props);
        let { userBlocked, users } = this.props;
        let { page, limit, active, subscribed, from_date, to_date } = this.state; 
        if(prevState.from_date !== from_date && prevState.to_date !== to_date){
            // console.log('from_date', from_date);
            // console.log('to_date', to_date);
            this.fetchDonations();
        }
        if(userBlocked && userBlocked !== prevProps.userBlocked ){
            if(userBlocked && userBlocked.updated && userBlocked.requestDone && !userBlocked.err){
                this.fetchAllUsers();
                this.setState((prevState) => {
                    return({
                        modal : !prevState.modal
                    })
                },() =>{
                    return notify('success', userBlocked.message)
                })
            }
            if(userBlocked && !userBlocked.updated && userBlocked.requestDone && userBlocked.err){
                // this.fetchAllUsers();
                this.setState({
                    modal : false
                }, () => {
                    return notify('error', userBlocked.message);
                })
            }
        }
        
       
    }

    toggleModal = () => {
        this.setState((prevState) => {
            return({
                modal : !prevState.modal
            })
        })
    }

    renderIndex=(record) => {
        let { page, limit } = this.state;
        let index = record.rowIndex + 1 ;
        if(page > 1){
            index  = (page - 1)*limit + record.rowIndex + 1
        }
        return index;
    }

    renderDate = (data) => {
        return moment(data.data.createdAt).format('MM-DD-YYYY')
    }

    getSelectedRows = () => {
        const selectedNodes = this.gridApi.getSelectedNodes()
        const selectedData = selectedNodes.map( node => node.data )
        const selectedDataStringPresentation = selectedData.map( node => node.make + ' ' + node.model).join(', ')
        alert(`Selected nodes: ${selectedDataStringPresentation}`)
    }
    
    onPageChange = (page) => {
        // console.clear()
        let { limit } = this.state;
        this.setState({page,limit})
        this.props._fet(page, limit);
    }

    searchHandler = (e) =>{

        let { value } = e.target
        this.setState({search:value,onSearch:true},()=>{
            let { search, page, limit, active, subscribed } = this.state
            let searchInfo = {
                searchKey : search,
                page,
                limit,
                active,
                subscribed
            }
            search =="" ? this.fetchAllUsers():this.searchAllUsers(searchInfo)
            
        })


    }
    
    searchAllUsers = () => {
        let { search, page, limit, active, subscribed } = this.state
        let searchInfo = {
            searchKey : search,
            page,
            limit,
            active,
            subscribed
        }
        this.props._searchUser(searchInfo)
    }

    onChangeHandler =(e) =>{
        let { name, value } = e.target;
        let fromdate , todate;
        let data = { [name] : value }
        if(name === 'fromdate' || name === "todate"){
            // console.log('value fromdate', value);
            // console.log('value', value);
            if(name === 'fromdate') {
                data['from_date'] =  moment.tz(value,'UTC').format()
            }
            if(name === 'todate') {
                data['to_date'] =  moment.tz(value,'UTC').endOf('day').format()
            }
        }
        this.setState(data, () => {
            let { page, limit, amountSort, dateSort } = this.state;
            this.fetchDonations();
        })
    }

    onDateFilterHandler =(e) =>{
        
        let from_date, to_date;
        const { value } = e.target ;
        switch(value){
            case "this_month":
                // console.log(moment.startOf('month').utc().format())
                from_date = moment().startOf('month').utc().format();
                to_date = moment().endOf('month').utc().format();
                break;
            case "last_month":
                from_date = moment().subtract('1', 'month').startOf('month').utc().format();
                to_date = moment().subtract('1', 'month').endOf('month').utc().format();
                break;
            case "this_week":
                from_date = moment().startOf('week').utc().format();
                to_date = moment().endOf('week').utc().format();
                break;
            
            case "last_week":
                from_date = moment().subtract('1', 'week').startOf('week').utc().format();
                to_date = moment().subtract('1', 'week').endOf('week').utc().format();
                break;
            case "date_range":
                from_date = '';
                to_date = '';
                break;
        
        }

        // console.log('from_date',from_date);
        // console.log('to_date', to_date);
        const data = { 
            filter_type : value,
        }
        if(value !== 'date_range') {
            data['from_date'] = from_date
            data['to_date'] = to_date
        }
        this.setState(data)
        
    }


    fetchDonations = () => {
        let { page, limit, amountSort, dateSort, from_date, to_date } = this.state; 
        // let { doinationsList: { users, total } } = this.props; 
        this.props._fetchDonations(page, limit, amountSort, dateSort, from_date, to_date);
    }

    renderAmount=(record) => {
        let { data : { amount } } = record ;
        let dollars = amount / 100;
        dollars = dollars.toLocaleString("en-US", {style:"currency", currency:"USD"});
        return dollars;
    }

    render(){
        // console.log('this.props allusers', this.props.doinationsList);
        // console.log('props======================================',this.props)
        let { headsAg, donations, amountSort, dateSort, isFetching, isFetched, fromdate, todate, filter_type } = this.state;
        let norecordstext 
        isFetching ? norecordstext ='fetching users...' : norecordstext = 'No Users Found!'
        // console.clear()
        //console.log(this.state);
        return (
            <Container>
                <Row>
                    <Col md={12} >
                        <Card>
                            <CardBody>
                                <Row className='page-title bb-1 border-gray pb-2'>
                                    <Col md={6}>
                                        <h3 >Contributions</h3>
                                    </Col>
                                    <Col md={{'offset': 0, 'size' : 3}} className="pt-2">
                                        <Form className="">
                                            <FormGroup row>
                                                <Label sm={12}>Amount :- </Label>
                                                <Col sm={10}>
                                                    <Input type="select" name="amountSort" value={ amountSort } onChange={this.onChangeHandler}>
                                                        <option value="desc" >Descending</option>                                            
                                                        <option value="asc" >Ascending</option>
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                    <Col md={{'offset': 0 , 'size' : 3}} className="pt-2">
                                    <Form className="">
                                            <Label>Contribution Date :- </Label>
                                            <Input type="select" name="dateSort" value={ dateSort } onChange={this.onChangeHandler}>
                                                <option value="new" >Newest First</option>                                            
                                                <option value="old" >Old First</option>
                                            </Input>
                                        </Form>
                                    </Col>
                                </Row>
                                <Row style={{marginTop:10}}>
                                        <Col md={{'offset': 0 , 'size' : 3}} className="text-right">
                                            <Form className="text-right">
                                                <FormGroup>
                                                    <Input type="select" name="filter_by" value={ filter_type } onChange={this.onDateFilterHandler}>
                                                        <option value="">Select Duration</option>                                            
                                                        <option value="this_month" >This Month</option>                                            
                                                        <option value="last_month" >Last Month</option>                                            
                                                        <option value="this_week" >This Week</option>
                                                        <option value="last_week" >Last Week</option>
                                                        <option value="date_range" >Select Range</option>                                            
                                                    </Input>
                                                </FormGroup>
                                            </Form>
                                        </Col>
                                        <Col md={{'offset': 0 , 'size' : 3}} className="text-right">
                                            <Form className="text-right">
                                                <FormGroup>
                                                    <Input type="date" name="fromdate" value={ fromdate } disabled ={ filter_type !== 'date_range' } onChange={ (e) => {this.onChangeHandler(e)} } />
                                                </FormGroup>
                                            </Form>
                                        </Col>
                                        <Col md={{'offset': 0 , 'size' : 3}} className="text-right">
                                            <Form className="text-right">
                                                <FormGroup>
                                                    <Input type="date" name="todate" value={ todate } disabled ={ filter_type !== 'date_range' } onChange={ (e) => {this.onChangeHandler(e)} } />
                                                </FormGroup>
                                            </Form>
                                        </Col>
                                        <Col md={{'offset': 0 , 'size' : 2}} className="text-right">
                                            <Form className="text-center">
                                                <FormGroup>
                                                    {/* <Button type="button" color="primary" onClick = { ()=> { onApplyFilter() }}>Apply</Button> */}
                                                </FormGroup>
                                            </Form>
                                        </Col>
                                        <Col md={{'offset': 0 , 'size' : 2}} className="text-right">
                                            <h5 >Total Amount :  {this.state.totalamout / 100}</h5>
                                        </Col>
                                    {/* <Col md={{'offset': 3 , 'size' : 3}} className="text-right">
                                        <Form className="text-right">
                                            <FormGroup>
                                                <Input type="search" name="search" id="exampleEmail" placeholder="Search user" onChange={(e)=>this.searchHandler(e)}/>
                                            </FormGroup>
                                        </Form>
                                    </Col> */}
                                    <Col md={12} style={{ height: '90vh', width: '600px' }} className="ag-theme-material">
                                        <div style={{height : '100%', width : '100%'}}>
                                            <TableComponent 
                                                rowsData={ donations } 
                                                onCellClicked={ this.onCellClicked }
                                                headsAg={headsAg}
                                                editRecord={this.editRecord} 
                                                rowSelectable={'multiple'} 
                                                rowsPerPage={10}
                                                isFetching={isFetching}
                                                isFetched={isFetched}
                                                noRecordsText = {norecordstext}
                                                fetchingRecordsText = {'Fetching Records...'}
                                                noRecordsText = {'No Records Found!'}
                                                {...this.props} 

                                            />
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <TablePagination onPageChange={this.onPageChange} {...this.state}/>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default Donations