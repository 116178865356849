import * as Actions from './actionTypes';
import * as faqFuncs from './../../api/faqApi';


export function  getAllFAQRequest() {
    return {
      type: Actions.GETALLFAQREQUEST,
      payload:{}
    };
  }
   
export function getAllFAQSuccess(data) {
  return {
    type: Actions.GETALLFAQSUCCESS,
    payload : data,
  };
}
  
export function  getAllFAQError(message) {
    return {
      type: Actions.GETALLFAQERROR,
      payload : { message },
  };
}

export function  getFAQDetailsRequest() {
    return {
      type: Actions.GETFAQDETAILSREQUEST,
      payload:{}
    };
  }
   
  export function getFAQDetailsSuccess(data) {
    return {
      type: Actions.GETFAQDETAILSSUCCESS,
      payload : data,
    };
  }
   
  export function  getFAQDetailsError(message) {
      return {
        type: Actions.GETFAQDETAILSERROR,
        payload : { message },
    };
  }
  export function  createFAQRequest() {
    return {
      type: Actions.CREATEFAQREQUEST,
      payload:{}
    };
  }
    
  export function createFAQSuccess(data) {
      return {
        type: Actions.CREATEFAQSUCCESS,
        payload : data,
      };
  }
    
  export function  createFAQError(message) {
      return {
        type: Actions.CREATEFAQERROR,
        payload : { message },
      };
  }

export function  updateFAQRequest() {
  return {
    type: Actions.UPDATEFAQREQUEST,
    payload:{}
  };
}
  
export function updateFAQSuccess(data) {
    return {
      type: Actions.UPDATEFAQSUCCESS,
      payload : data,
    };
}
  
export function  updateFAQError(message) {
    return {
      type: Actions.UPDATEFAQERROR,
      payload : { message },
    };
}

export function  deleteFAQRequest() {
  return {
    type: Actions.DELETEFAQREQUEST,
    payload:{}
  };
}
  
export function deleteFAQSuccess(data) {
    return {
      type: Actions.DELETEFAQSUCCESS,
      payload : data,
    };
}
  
export function  deleteFAQError(message) {
    return {
      type: Actions.DELETEFAQERROR,
      payload : { message },
    };
}

export function  getLangFAQRequest() {
  return {
    type: Actions.GETLANGFAQREQUEST,
    payload:{}
  };
}
 
export function getLangFAQSuccess(data) {
return {
  type: Actions.GETLANGFAQSUCCESS,
  payload : data,
};
}

export function  getLangFAQError(message) {
  return {
    type: Actions.GETLANGFAQERROR,
    payload : { message },
  };
}


// update seuqence
export function  updateFAQSequenceRequest() {
  return {
    type: Actions.UPDATEFAQSEQUENCEREQUEST,
    payload:{}
  };
}

// update seuqence
export function updateFAQSequenceSuccess(data) {
  return {
    type: Actions.UPDATEFAQSEQUENCESUCCESS,
    payload : data,
  };
}

// update seuqence
export function  updateFAQSequenceError(message) {
    return {
      type: Actions.UPDATEFAQSEQUENCEERROR,
      payload : { message },
    };
}

export const addFAQ = (data) =>{
    return (dispatch) => {
        dispatch(createFAQRequest());
        faqFuncs.craeteNewFaq(data).then((res)=>{
            console.log('responseData*****************************', res)
        if(res.status === 200) return dispatch(createFAQSuccess(res.data));
        if(res.status === 400) return dispatch(createFAQError(res.data.message));
        if(res.status === 401) return dispatch(createFAQError(res.data.message));
        if(res.status === 409) return dispatch(createFAQError(res.data.message));

        }).catch((err)=>{
            console.log('err', err)
            return dispatch(createFAQError(err.message));
        })
    }
}

export const editFAQ = (data) =>{
  return (dispatch) => {
      dispatch(updateFAQRequest());
      faqFuncs.updateFaq(data).then((res)=>{
          console.log('responseData*****************************', res)
      if(res.status === 200) return dispatch(updateFAQSuccess(res.data));
      if(res.status === 400) return dispatch(updateFAQError(res.data.message));
      if(res.status === 401) return dispatch(updateFAQError(res.data.message));
      if(res.status === 409) return dispatch(updateFAQError(res.data.message));

      }).catch((err)=>{
          console.log('err', err)
          return dispatch(updateFAQError(err.message));
      })
  }
}

export const deleteFaq = (id) =>{
  return (dispatch) => {
      dispatch(deleteFAQRequest());
      faqFuncs.deleteFaqApi(id).then((res)=>{
        console.log('responseData*****************************', res)
        if(res.status === 200) return dispatch(deleteFAQSuccess(res.data));
        if(res.status === 400) return dispatch(deleteFAQError(res.data.message));
        if(res.status === 401) return dispatch(deleteFAQError(res.data.message));
        if(res.status === 409) return dispatch(deleteFAQError(res.data.message));

      }).catch((err)=>{
          console.log('err', err)
          return dispatch(deleteFAQError(err.message));
      })
  }
}

export const listAllFAQ = (page, limit) =>{
    return (dispatch) => {
        dispatch(getAllFAQRequest());
        faqFuncs.getAllFaq( page, limit ).then((res)=>{
        if(res.status === 200) return dispatch(getAllFAQSuccess(res.data));
        if(res.status === 400) return dispatch(getAllFAQError(res.data.message));
        if(res.status === 401) return dispatch(getAllFAQError(res.data.message));
        if(res.status === 409) return dispatch(getAllFAQError(res.data.message));
        }).catch((err)=>{
            console.log('err', err)
            return dispatch(getAllFAQError(err.message));
        })
    }
}

export const listLangFAQ = (lang) =>{
  return (dispatch) => {
      dispatch(getLangFAQRequest());
      faqFuncs.getAllLangFaq( lang ).then((res)=>{
      if(res.status === 200) return dispatch(getLangFAQSuccess(res.data));
      if(res.status === 400) return dispatch(getLangFAQError(res.data.message));
      if(res.status === 401) return dispatch(getLangFAQError(res.data.message));
      if(res.status === 409) return dispatch(getLangFAQError(res.data.message));
      }).catch((err)=>{
          console.log('err', err)
          return dispatch(getLangFAQError(err.message));
      })
  }
}


export const fetchFaqDetails = (id) =>{
    return (dispatch) => {
        dispatch(getFAQDetailsRequest());
        faqFuncs.getFaqDetails( id ).then((res)=>{
        if(res.status === 200) return dispatch(getFAQDetailsSuccess(res.data));
        if(res.status === 400) return dispatch(getFAQDetailsError(res.data.message));
        if(res.status === 406) return dispatch(getFAQDetailsError(res.data.message));
        if(res.status === 401) return dispatch(getFAQDetailsError(res.data.message));
        if(res.status === 409) return dispatch(getFAQDetailsError(res.data.message));
        }).catch((err)=>{
            console.log('err', err)
            return dispatch(getFAQDetailsError(err.message));
        })
    }
}

// update sequnce of FAQ
export const updateFAQSequence = (data) =>{
  return (dispatch) => {
      dispatch(updateFAQSequenceRequest());
      faqFuncs.updateFaqSeq(data).then((res)=>{
          console.log('responseData*****************************', res)
      if(res.status === 200) return dispatch(updateFAQSequenceSuccess(res.data));
      if(res.status === 400) return dispatch(updateFAQSequenceError(res.data.message));
      if(res.status === 401) return dispatch(updateFAQSequenceError(res.data.message));
      if(res.status === 409) return dispatch(updateFAQSequenceError(res.data.message));

      }).catch((err)=>{
          console.log('err', err)
          return dispatch(updateFAQSequenceError(err.message));
      })
  }
}

