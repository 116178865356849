import React, { PureComponent } from "react";
import SidebarLink from "./SidebarLink";
import SidebarCategory from "./SidebarCategory";
import {
  changeThemeToDark,
  changeThemeToLight,
} from "../../../redux/actions/themeActions";
import { connect } from "react-redux";
import { ADMIN_PERMISSIONS } from "./../../../inc/constants";

class SidebarContent extends PureComponent {
  changeToDark = () => {
    this.props.dispatch(changeThemeToDark());
    this.hideSidebar();
  };

  changeToLight = () => {
    this.props.dispatch(changeThemeToLight());
    this.hideSidebar();
  };

  hideSidebar = () => {
    this.props.onClick();
  };
wrwet
  render() {
    let { user } = this.props;
    let permissions = ["ALL"];
    let role = "view-only"
    if (user && user.user) {
      permissions = user.user.permissions.length > 0 ? user.user.permissions : ["ALL"];
      role = user.user.role[0]
    }
    let allPermissions = ADMIN_PERMISSIONS.map((perm) => {
      let permInside = undefined;
      if (permissions.includes("ALL")) permInside = "ALL";
      if (permissions.includes(perm.id.toString())) permInside = perm.name;
      return permInside;
    }).filter((perm) => {
      return perm !== undefined;
    });
    //console.log("role", role)
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarLink
            title="Home"
            Icon="https://al-anon-media-staging.s3.amazonaws.com/app_icons/home.png"
            route="/dashboard"
            onClick={this.hideSidebar}
          />
          {( role === "superadmin" || role === "admin") && (
              <SidebarLink
                  title="Admins"
                  route="/admin/users"
                  Icon="https://al-anon-media-staging.s3.amazonaws.com/app_icons/admins.png"
                  onClick={this.hideSidebar}
                />
          )}

          {( role === "superadmin" || role === "admin" || role === "view-only") && (
            <>
              <SidebarLink
                title="Users"
                route="/users"
                Icon="https://al-anon-media-staging.s3.amazonaws.com/app_icons/users.png"
                onClick={this.hideSidebar}
              />
              <SidebarLink
              title="Banned Emails"
              route="/banned-emails"
              Icon="https://al-anon-media-staging.s3.amazonaws.com/app_icons/users.png"
              onClick={this.hideSidebar}
            />
            </>
          )}
          {(role === "superadmin" || role === "admin") && (
              <SidebarLink
                title="Categories"
                route="/category/all"
                Icon="https://al-anon-media-staging.s3.amazonaws.com/app_icons/categories.png"
                onClick={this.hideSidebar}
              />
          )}
          {(role === "superadmin" || role === "admin" || role === "view-only") && (
              <SidebarLink
                title="Meetings"
                route="/meetings"
                Icon="https://al-anon-media-staging.s3.amazonaws.com/app_icons/meetings.png"
                onClick={this.hideSidebar}
              />
          )}
          {(role === "superadmin" || role === "admin") && (
            <>
              <SidebarLink
                title="Contributions"
                route="/donations"
                onClick={this.hideSidebar}
                Icon="https://al-anon-media-staging.s3.amazonaws.com/app_icons/contributions.png"
              />
              <SidebarLink
                title="Notifications"
                Icon="https://al-anon-media-staging.s3.amazonaws.com/app_icons/notifications.png"
                route="/notifications"
                onClick={this.hideSidebar}
              />
            <SidebarLink
                title="Premium Content"
                Icon="https://al-anon-media-staging.s3.amazonaws.com/app_icons/premium_content.png"
                route="/subscribed-content/list"
                onClick={this.hideSidebar}
              />
             <SidebarLink
                  title="FAQ"
                  route="/faq"
                  Icon="https://al-anon-media-staging.s3.amazonaws.com/app_icons/faq.png"
                  onClick={this.hideSidebar}
                />
              <SidebarLink
                title="Virtual Tour"
                Icon="https://al-anon-media-staging.s3.amazonaws.com/app_icons/virtual_tour.png"
                route="/virtual-tour"
                onClick={this.hideSidebar}
              />
              <SidebarLink
                title="Feelings"
                Icon="https://al-anon-media-staging.s3.amazonaws.com/app_icons/feelings.png"
                icon="exit"
                route="/feelings"
              />
              <SidebarLink
                title="Reactions"
                Icon="https://al-anon-media-staging.s3.amazonaws.com/app_icons/reactions.png"
                icon="exit"
                route="/reactions"
              />
               <SidebarLink
                title="Terms And Conditions"
                Icon="https://al-anon-media-staging.s3.amazonaws.com/app_icons/terms_and_conditions.png"
                route="/terms-conditions"
                onClick={this.hideSidebar}
              />
            </>
          )}
          {(role === "superadmin" || role === "admin" || role === "view-only") && (
            <>
              <SidebarLink
                title="App Stats"
                Icon="https://al-anon-media-staging.s3.amazonaws.com/app_icons/app_stats.png"
                route="/reports-exports"
                onClick={this.hideSidebar}
              />
            </>
          )}
          { (role === "superadmin" || role === "admin") && (
            <>
              <SidebarLink title='Global Settings' Icon="https://al-anon-media-staging.s3.amazonaws.com/app_icons/global_settings.png" route='/configurations' onClick={this.hideSidebar}/>
              <SidebarLink title='Reported Content' Icon="https://al-anon-media-staging.s3.amazonaws.com/app_icons/reported_content.png" route='/reported-content' onClick={this.hideSidebar}/>
              <SidebarLink title='WSO Categories' 
                Icon="https://al-anon-media-staging.s3.amazonaws.com/app_icons/wso_categories.png"
                route='/al-anon-post-categories' 
                onClick={this.hideSidebar}
              />
              <SidebarLink title='Filtered List' Icon="https://al-anon-media-staging.s3.amazonaws.com/app_icons/filtered_list.png" route='/objectionable-content' onClick={this.hideSidebar}/>
              <SidebarLink title='Issue Log' Icon="https://al-anon-media-staging.s3.amazonaws.com/app_icons/issue_log.png" route='/user-tracking-list' onClick={this.hideSidebar}/>
              <SidebarLink title='Resources' Icon="https://al-anon-media-staging.s3.amazonaws.com/app_icons/resources.png" route='/resources' onClick={this.hideSidebar}/>
            </>
          )}
          <SidebarLink title="Finder Meetings" Icon="https://al-anon-media-staging.s3.amazonaws.com/app_icons/meetings.png"  route='/admin-meeting' onClick={this.hideSidebar} ></SidebarLink>
          {/* <SidebarLink title='Feedback' Icon={EmailMultipleOutlineIcon} route='/dashboard_crypto' onClick={this.hideSidebar}/> */}
          {/* <SidebarCategory title='Virtual Tour' icon='earth'>
              <SidebarLink title='E-Mails' route='/marketting/emails' onClick={this.hideSidebar}/>
              <SidebarLink title='Messages' route='/marketting/messages' onClick={this.hideSidebar}/>
            </SidebarCategory> */}
        </ul>

        {/* <ul className='sidebar__block'>
        <ul className='sidebar__block'>
        </ul>
          <SidebarCategory title='Multilevel Menu ' icon='menu'>
            <SidebarLink title='Second Level Item'/>
            <SidebarCategory title='Second Level Item'>
              <SidebarLink title='Third Level Item'/>
              <SidebarLink title='Third Level Item'/>
            </SidebarCategory>
          </SidebarCategory>
        </ul>
        <ul className='sidebar__block'>
          <SidebarLink title='Documentation' icon='text-align-justify' route='/documentation/introduction'
                       onClick={this.hideSidebar}/>
        </ul> */}
      </div>
    );
  }
}

export default connect()(SidebarContent);
