import * as Actions from './actionTypes';
import * as meetingFuncs from './../../api/meetingApi';

// HOST ACIONS
function  getAllHostsRequest() {
  return {
    type: Actions.LISTHOSTSREQUEST,
    payload:{}
  };
}
   
function getAllHostsSuccess(data) {
  return {
    type: Actions.LISTHOSTSSUCCESS,
    payload : data,
  };
}
   
function  getAllHostsError(message) {
  return {
    type: Actions.LISTHOSTSERROR,
    payload : { message },
  };
}

// fetch language actions

function  getAllActiveLanguagesRequest() {
  return {
    type: Actions.LISTLANUGAGESREQUEST,
    payload:{}
  };
}
   
function getAllActiveLanguagesSuccess(data) {
  return {
    type: Actions.LISTLANUGAGESSUCCESS,
    payload : data,
  };
}
   
function  getAllActiveLanguagesError(message) {
  return {
    type: Actions.LISTLANUGAGESERROR,
    payload : { message },
  };
}


function  createNewMeetingRequest() {
  return {
    type: Actions.CREATEMEETINGREQUEST,
    payload:{}
  };
}
     
function createNewMeetingSuccess(data) {
  return {
    type: Actions.CREATEMEETINGSUCCESS,
    payload : data,
  };
}
     
function  createNewMeetingError(message) {
    return {
      type: Actions.CREATEMEETINGERROR,
      payload : { message },
    };
}

function  updateMeetingRequest() {
  return {
    type: Actions.CREATEMEETINGREQUEST,
    payload:{}
  };
}
     
function updateMeetingSuccess(data) {
  return {
    type: Actions.CREATEMEETINGSUCCESS,
    payload : data,
  };
}
     
function  updateMeetingError(message) {
    return {
      type: Actions.CREATEMEETINGERROR,
      payload : { message },
    };
}

function  fetchMetingsRequest() {
  return {
    type: Actions.FETCHMEETINGREQUEST,
    payload:{}
  };
}
     
function fetchMetingsSuccess(data) {
  return {
    type: Actions.FETCHMEETINGSUCCESS,
    payload : data,
  };
}
     
function  fetchMetingsError(message) {
    return {
      type: Actions.FETCHMEETINGERROR,
      payload : { message },
    };
}

function authError(message) {
    return {
      type: Actions.LOGINERROR,
      payload : { message },
    };
}


function  deleteMeetingRequest() {
  return {
    type: Actions.DELETEMEETINGREQUEST,
    payload:{}
  };
}
     
function deleteMeetingSuccess(data) {
  return {
    type: Actions.DELETEMEETINGSUCCESS,
    payload : data,
  };
}
     
function  deleteMeetingError(message) {
    return {
      type: Actions.DELETEMEETINGERROR,
      payload : { message },
    };
}

export const listLanguages = () =>{
  return (dispatch) => {
      dispatch(getAllActiveLanguagesRequest());
      meetingFuncs.fetchLanguages().then((res)=>{
          console.log('languages', res);
          if(res.status === 200) return dispatch(getAllActiveLanguagesSuccess(res.data));
          if(res.status === 400) return dispatch(getAllActiveLanguagesError(res.data.message));
          if(res.status === 401) return dispatch(authError(res.data.message));
          if(res.status === 409) return dispatch(authError(res.data.message));
      }).catch((err)=>{
          console.log('err', err)
          return dispatch(getAllActiveLanguagesSuccess(err.message));
      })
  }
}

export const listhosts = () =>{
    return (dispatch) => {
        dispatch(getAllHostsRequest());
        meetingFuncs.fetchHosts().then((res)=>{
            console.log('hosts', res);
            if(res.status === 200) return dispatch(getAllHostsSuccess(res.data));
            if(res.status === 400) return dispatch(getAllHostsError(res.data.message));
            if(res.status === 401) return dispatch(authError(res.data.message));
            if(res.status === 409) return dispatch(authError(res.data.message));
        }).catch((err)=>{
            console.log('err', err)
            return dispatch(getAllHostsError(err.message));
        })
    }
}

export const createMeeting = (data) =>{
  return (dispatch) => {
      dispatch(createNewMeetingRequest());
      meetingFuncs.createMeeting(data).then((res)=>{
          console.log('new meeting', res);
          if(res.status === 200) return dispatch(createNewMeetingSuccess(res.data));
          if(res.status === 400) return dispatch(createNewMeetingError(res.data.message));
          if(res.status === 500) return dispatch(createNewMeetingError(res.data.message));
          if(res.status === 401) return dispatch(authError(res.data.message));
          if(res.status === 409) return dispatch(authError(res.data.message));
      }).catch((err)=>{
          console.log('err', err)
          return dispatch(createNewMeetingError(err.message));
      })
  }
}

export const updateMeeting = (data) =>{
  return (dispatch) => {
      dispatch(updateMeetingRequest());
      meetingFuncs.updateMeeting(data).then((res)=>{
          console.log('new meeting', res);
          if(res.status === 200) return dispatch(updateMeetingSuccess(res.data));
          if(res.status === 400) return dispatch(updateMeetingError(res.data.message));
          if(res.status === 500) return dispatch(updateMeetingError(res.data.message));
          if(res.status === 401) return dispatch(authError(res.data.message));
          if(res.status === 409) return dispatch(authError(res.data.message));
      }).catch((err)=>{
          console.log('err', err)
          return dispatch(updateMeetingError(err.message));
      })
  }
}

export const getAllMeetings = (page, limit, searchkey, lang, meet_type, host_filter, day_filter) =>{
  return (dispatch) => {
      dispatch(fetchMetingsRequest());
      meetingFuncs.fetchMeetings(page, limit, searchkey, lang, meet_type, host_filter, day_filter).then((res)=>{
          console.log('new meeting', res);
          if(res.status === 200) return dispatch(fetchMetingsSuccess(res.data));
          if(res.status === 400) return dispatch(fetchMetingsError(res.data.message));
          if(res.status === 401) return dispatch(authError(res.data.message));
          if(res.status === 409) return dispatch(authError(res.data.message));
      }).catch((err)=>{
          console.log('err', err)
          return dispatch(fetchMetingsError(err.message));
      })
  }
}

export const deleteMeeting = (meetId) =>{
  return (dispatch) => {
      dispatch(deleteMeetingRequest());
      meetingFuncs.deleteMeeting(meetId).then((res)=>{
          console.log('new meeting', res);
          if(res.status === 200) return dispatch(deleteMeetingSuccess(res.data));
          if(res.status === 400) return dispatch(deleteMeetingError(res.data.message));
          if(res.status === 500) return dispatch(deleteMeetingError(res.data.message));
          if(res.status === 401) return dispatch(authError(res.data.message));
          if(res.status === 409) return dispatch(authError(res.data.message));
      }).catch((err)=>{
          console.log('err', err)
          return dispatch(deleteMeetingError(err.message));
      })
  }
}