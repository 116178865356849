import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';

class AuthRoutes extends Component{
    render(){
      let { checked, isAuthenticated, ComponentRender } = this.props 
      if(!checked){
        return (
            <div className={`load${checked ? '' : ' loaded'}`}>
                <div className='load__icon-wrap'>
                    <svg className='load__icon'>
                    <path fill='#4ce1b6' d='M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z'/>
                    </svg>
                </div>
            </div>  
        )
      }else{
          return (
          <Route
            {...this.props}
            render={() =>{
              return isAuthenticated ? (
                <ComponentRender {...this.props} />
              ) : (
                <Redirect
                  to={{
                    pathname: "/login",
                    state: { from: this.props.location }
                  }}
                />
              )
            }
            }
          />
          )
        }
    }
}

export default AuthRoutes