import React from 'react';
import {Container, Row, Col, Card, CardBody, Form, Button, Label, Input, FormGroup} from 'reactstrap';
import notificaiton from './../../helpers/notificationHelper';
import { DAYS_ARRAY, SPOKEN_LANGUAG_ARRAY } from './../../inc/constants';
import { Multiselect } from 'react-widgets';
import moment from 'moment';
import { meetingTitleValidator } from './../../helpers/validationHelpers';
import 'react-widgets/dist/css/react-widgets.css';

export default class AddNewFAQ extends React.Component{
    state = {
        title : '',
        description : '',
        isFetching : false,
        language : ''
    }

    static getDerivedStateFromProps(props, state){
        //console.log('meeting props', props.meeting);
        if(props.meeting && !props.meeting.isFetching && props.meeting.isFetching !== state.isFetching){
            return {
                isFetching : false
            }
        }
        return null;
    }

    componentDidUpdate(prevProps, prevStates){
        // console.clear();
        //console.log('props', this.props);
        let { createFAQ } = this.props;
        if(createFAQ !== prevProps.createFAQ){
            if( createFAQ.isFetched && !createFAQ.err && !createFAQ.isFetching){
                notificaiton('success', createFAQ.message);
                return this.props.history.push('/faq');
            }
            if( !createFAQ.isFetched && createFAQ.err && !createFAQ.isFetching){
                return notificaiton('error', createFAQ.message);
                // return this.props.history.push('/meetings');
            }
        }
    }
    
    onChangeHandler = (e) => {
        // console.log('name', e.target.name)
        // console.log('value', e.target.value); 
        let { name, value } = e.target;
        let data = {
            [name] : value
        }
        return this.setState(data)
    }

    onSubmitHandler = (e) => {
        //console.log("submitted");
        e.preventDefault();
        let { title, description, language  } = this.state;
        if(!title || !description || !language) return notificaiton('error', 'All Fields are mandatory !')
        let data = {
            title,
            description,
            language
        }
        return this.props._onCreate(data);
    }

    render(){
        let { title, description, language, isFetching } = this.state;
        let { categories } = this.props;
        categories = categories && categories.length > 0 ? categories : []
        return(
            <Container  >
                <Row>
                    <Col md={12}>
                        <Card>
                            <CardBody className="bg-white ">
                                <Row className='page-title bb-1 border-gray mb-4'>
                                    <Col md={6}>
                                        <h3 className='page-title mb-4'>Add FAQ</h3>
                                    </Col>
                                    <Col md={6} className="text-right">
                                        <Button color="primary" onClick={()=>{ this.props.history.push('/faq') } }>List</Button>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={12}>
                                        <Form onSubmit ={this.onSubmitHandler}>
                                            <Row form className="mb-3">
                                                <Col md={12} sm={12}>
                                                    <Label className="text-muted">Title:</Label>
                                                    <Input type="text" name="title" value={title} 
                                                        onChange={ this.onChangeHandler } required 
                                                        placeholder = "Enter Title" 
                                                    />
                                                </Col>
                                                
                                            </Row>
                                            
                                            <Row className="text-right mb-2">
                                                <Col md={12}  sm={12}>
                                                <Input type="textarea" name="description" value ={description}
                                                        onChange={ this.onChangeHandler } required
                                                        placeholder = "Enter Description" 
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md={4} sm={12}>
                                                    {/* <Label htmlFor="typeselect">Language For :- </Label> */}
                                                    <Input type="select" name="language" id="typeselect" value={language} defaultValue="" onChange={this.onChangeHandler}>
                                                        <option value="" >Select Language</option>
                                                        {SPOKEN_LANGUAG_ARRAY && SPOKEN_LANGUAG_ARRAY.length > 0 && (
                                                            SPOKEN_LANGUAG_ARRAY.map((lang) => {
                                                                return (
                                                                    <option key={lang.value} value={lang.value}>{lang.name}</option>
                                                                )
                                                            })
                                                        )}
                                                    </Input>
                                                </Col>
                                            </Row>

                                            <Row className="text-right mt-2">
                                                <Col md={12}  sm={12}>
                                                    {isFetching ? (
                                                        <Button color="default" disabled>Creating...</Button>
                                                    ) : (
                                                        <Button color="primary" type="submit">Create</Button>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}