import { wrappedFetch, wrappedGet } from './urlFuncs';
import { LIST_CATEGORIES_URL,ADD_CATEGORIES_URL, LIST_HOSTS_URL, CREATE_NEW_MEETING, LIST_ALL_MEETING, LIST_LANGUAGES_URL } from './../inc/constants';
import {API_URL} from './../inc/config';

export const fetchHosts = () =>{
  return wrappedGet(`${API_URL}${LIST_HOSTS_URL}`, 'GET');
}

export const createMeeting = (data) =>{
  //console.log('meeting data', data)
  return wrappedFetch(`${API_URL}${CREATE_NEW_MEETING}`, 'POST',data);
}

export const fetchMeetings = (page, limit, search, lang, meet_type,host_filter,day_filter) =>{
  //console.log('page, limit', page, limit)
  return wrappedGet(`${API_URL}${LIST_ALL_MEETING}?page=${page}&limit=${limit}&searchkey=${search}&lang=${lang}&meetType=${meet_type}&hostFilter=${host_filter}&dayFilter=${day_filter}`, 'GET');
}

export const updateMeeting = (data) =>{
  //console.log('meeting data', data)
  return wrappedFetch(`${API_URL}${CREATE_NEW_MEETING}`, 'PATCH',data);
}

export const deleteMeeting = (meetId) =>{
  //console.log('meetId', meetId)
  return wrappedFetch(`${API_URL}${CREATE_NEW_MEETING}${meetId}`, 'DELETE');
}

export const fetchLanguages = () =>{
  return wrappedGet(`${API_URL}${LIST_LANGUAGES_URL}`, 'GET');
}