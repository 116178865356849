// import { ADDADMIN, ERROR, FORGETPASSWORD } from '../actions/ADDADMIN_action';
import {
  GETALLREACTIONSCLEAR,
  GETALLREACTIONSERROR,
  GETALLREACTIONSUCCESS,
  GETALLREACTIONSREQUEST
} from "./../actions/actionTypes";

const initialState = {
  requestDone: false,
  isFetched: false,
  isFetching: false,
  err: false,
  message: "",
  reactions: [],
  total: 0,
};

// receiving response sent by action according to type of action
export default function allReactionsReduces(state = initialState, action) {
  //console.log(action, "action");
  let { payload } = action;
  switch (action.type) {
    case GETALLREACTIONSCLEAR:
      return {
        requestDone: false,
        isFetched: false,
        isFetching: false,
        err: false,
        message: "",
        reactions: [],
        total: 0,
      };
      break;
    case GETALLREACTIONSREQUEST:
      return {
        requestDone: false,
        isFetched: false,
        isFetching: true,
        err: false,
        message: "",
        reactions: [],
        total: 0,
      };
      break;
    case GETALLREACTIONSUCCESS:
      //console.log("========in switch", payload.reactions);
      return {
        requestDone: true,
        isFetched: true,
        isFetching: false,
        err: false,
        message: "",
        reactions: payload.reactions,
        total: payload.count,
      };
      break;

    case GETALLREACTIONSERROR:
      return {
        requestDone: true,
        isFetched: false,
        isFetching: false,
        err: true,
        message: payload.message,
        reactions: [],
        total: 0,
      };
      break;
    default:
      return state;
  }
}
