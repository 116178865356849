import { 
    CREATENOTIFICATIONSREQUEST, 
    CREATENOTIFICATIONSSUCCESS, 
    CREATENOTIFICATIONSERROR,
    FETCHNOTIFICATIONSERROR,
    FETCHNOTIFICATIONSREQUEST,
    FETCHNOTIFICATIONSSUCCESS,
    DELETENOTIFICATIONSREQUEST,
    DELETENOTIFICATIONSSUCCESS,
    DELETENOTIFICATIONSERROR,
    UPDATENOTIFICATIONSERROR,
    UPDATENOTIFICATIONSREQUEST,
    UPDATENOTIFICATIONSSUCCESS
  } from './actionTypes';
  import * as notificationFuncs from './../../api/notificationsFuncs';
  
  
  
  export function  createNotificationsRequest() {
      return {
        type: CREATENOTIFICATIONSREQUEST,
        payload:{}
      };
    }
     
    export function createNotificationsSuccess(data) {
      return {
        type: CREATENOTIFICATIONSSUCCESS,
        payload : data,
      };
    }
     
    export function  createNotificationsError(message) {
        return {
          type: CREATENOTIFICATIONSERROR,
          payload : { message },
        };
    }
  
    export function  fetchNotificationsRequest() {
      return {
        type: FETCHNOTIFICATIONSREQUEST,
        payload:{}
      };
    }
     
    export function fetchNotificationsSuccess(data) {
      return {
        type: FETCHNOTIFICATIONSSUCCESS,
        payload : data,
      };
    }
     
    export function fetchNotificationsError(message) {
        return {
          type: FETCHNOTIFICATIONSERROR,
          payload : { message },
        };
    }

    export function  deleteNotificationsRequest() {
      return {
        type: DELETENOTIFICATIONSREQUEST,
        payload:{}
      };
    }
     
    export function deleteNotificationsSuccess(data) {
      return {
        type: DELETENOTIFICATIONSSUCCESS,
        payload : data,
      };
    }
     
    export function deleteNotificationsError(message) {
        return {
          type: DELETENOTIFICATIONSERROR,
          payload : { message },
        };
    }

    export function  updateNotificationsRequest() {
      return {
        type: UPDATENOTIFICATIONSREQUEST,
        payload:{}
      };
    }
     
    export function updateNotificationsSuccess(data) {
      return {
        type: UPDATENOTIFICATIONSSUCCESS,
        payload : data,
      };
    }
     
    export function updateNotificationsError(message) {
        return {
          type: UPDATENOTIFICATIONSERROR,
          payload : { message },
        };
    }
  
  export const createNotifications = (data) =>{
    return (dispatch) => {
        dispatch(createNotificationsRequest());
        console.log('notifications data', data);
        notificationFuncs.craeteNotifications(data).then((res)=>{
            console.log('responseData*****************************', res)
            if(res.status === 200) return dispatch(createNotificationsSuccess(res.data));
            if(res.status === 400) return dispatch(createNotificationsError(res.data.message));
            if(res.status === 401) return dispatch(createNotificationsError(res.data.message));
            if(res.status === 409) return dispatch(createNotificationsError(res.data.message));
  
        }).catch((err)=>{
            console.log('err', err)
            return dispatch(createNotificationsError(err.message));
        })
    }
  }
  
  
  export const fetchNotifications = (page, limit) =>{
    return (dispatch) => {
        dispatch(deleteNotificationsRequest());
        notificationFuncs.fetchNotificationsList(page, limit).then((res)=>{
            console.log('responseData*****************************', res)
            if(res.status === 200) return dispatch(fetchNotificationsSuccess(res.data));
            if(res.status === 400) return dispatch(fetchNotificationsError(res.data.message));
            if(res.status === 401) return dispatch(fetchNotificationsError(res.data.message));
            if(res.status === 409) return dispatch(fetchNotificationsError(res.data.message));
  
        }).catch((err)=>{
            console.log('err', err)
            return dispatch(fetchNotificationsError(err.message));
        })
    }
  }
  //action creator to delete notifications
  export const deleteNotifications = (id) =>{
    return (dispatch) => {
      dispatch(fetchNotificationsRequest());
      notificationFuncs.removeNotifications(id).then((res)=>{
        console.log('responseData*****************************', res)
        if(res.status === 200) return dispatch(deleteNotificationsSuccess(res.data));
        if(res.status === 400) return dispatch(deleteNotificationsError(res.data.message));
        if(res.status === 401) return dispatch(deleteNotificationsError(res.data.message));
        if(res.status === 409) return dispatch(deleteNotificationsError(res.data.message));
        
      }).catch((err)=>{
        console.log('err', err)
        return dispatch(deleteNotificationsError(err.message));
      })
    }
  }
  
  //action creator to udpate notifications
  export const updateNotification = (id, data) =>{
    return (dispatch) => {
        dispatch(fetchNotificationsRequest());
        notificationFuncs.updateNotifications(id, data).then((res)=>{
            console.log('responseData*****************************', res)
            if(res.status === 200) return dispatch(updateNotificationsSuccess(res.data));
            if(res.status === 400) return dispatch(updateNotificationsError(res.data.message));
            if(res.status === 401) return dispatch(updateNotificationsError(res.data.message));
            if(res.status === 409) return dispatch(updateNotificationsError(res.data.message));
  
        }).catch((err)=>{
            console.log('err', err)
            return dispatch(updateNotificationsError(err.message));
        })
    }
  }
  