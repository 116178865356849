import React from 'react';
import {Container, Row, Col, FormGroup, Form, Button, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import moment from 'moment';
import { SPOKEN_LANGUAGE_OBJECT, SPOKEN_LANGUAG_ARRAY } from './../../inc/constants';
const AddCategory = (props) => {

    // render(){
        let { modal, toggle , modalTitle, CustomClass, title, content, date, language, onChangeHandler, onUpdatehandler} = props;
        return(
            <Form>
                <Modal isOpen={modal} toggle={toggle} className={CustomClass}>
                    <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
                    <ModalBody>
                            {/* <Row> */}
                                <Form name = "add" onSubmit={onUpdatehandler}>
                                    <FormGroup row >
                                        <Label htmlFor="enTitle" sm={2}>Title</Label>
                                        <Col sm={10}>
                                        <Input type="text" name="title" value={title}  onChange={(e) => {onChangeHandler(e.target.name,e.target.value)}} required placeholder="Enter English Title" />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label htmlFor="esTitle" sm={2}>Content Title</Label>
                                        <Col sm={10}>
                                        <Input type="textarea" name="content" value={content} onChange={(e) => {onChangeHandler(e.target.name,e.target.value)}} required placeholder="Enter Spanish Title" />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label htmlFor="esTitle" sm={2}>Date</Label>
                                        <Col sm={10}>
                                        <Input type="date" name="date" defaultValue={ moment(date).format('YYYY-MM-DD') } format="YYYY-MM-DD" onChange={(e) => {onChangeHandler(e.target.name,e.target.value)}} required placeholder="Enter Spanish Title" />
                                        </Col>
                                    </FormGroup>
                                 
                                    <FormGroup>
                                        <Row>
                                            <Col md={12} sm={12}>
                                                <Label htmlFor="typeselect">Spoken Language</Label>
                                                <Input type="select" name="language" id="typeselect" defaultValue={ language } onChange={(e) => {onChangeHandler(e.target.name,e.target.value)}}>
                                                    <option value="" ></option>
                                                    {SPOKEN_LANGUAG_ARRAY && SPOKEN_LANGUAG_ARRAY.length > 0 && (
                                                        SPOKEN_LANGUAG_ARRAY.map((lang) => {
                                                            return (
                                                                <option  key={lang.value} value={lang.value}>{lang.name}</option>
                                                            )
                                                        })
                                                    )}
                                                </Input>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                        <hr />
                                    <div style={{textAlign:'end'}}>
                                        <Button color="primary" type="submit" name = 'update'  >Update</Button>{' '}
                                        <Button color="secondary"  name = 'cancel' onClick={(e) => toggle()}> Cancel</Button>
                                    </div>
                            </Form>
                            {/* </Row> */}
                    </ModalBody>
                    
                </Modal>
            </Form>
        )
}

export default AddCategory