import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form, FormGroup, Input, CustomInput, Label } from 'reactstrap';

const withModal = (ParentComp) => {
    return class constModal extends React.Component {
        state = {
            limit : 100,
            page : 1,
            modal : false,
            active : false,
            subscribed : false,
            join_date_sort : 'desc',
            sortBy : '',
            filter_subscribed : false,
            filter_active : false,
            filter_tester : false,
            filter_blocked : false,
            sort_join_date : 'desc',
            sort_other : ''
        }

        toggleModal = () => {
            this.setState({
                modal : !this.state.modal
            })
        }

        onChangeHandler = (name, value) => {
            this.setState({ [name] : value })
        }
        onClearFilter = (name, value) => {
            this.setState({
                join_date_sort : 'desc',
                sortBy : '',
                filter_subscribed : false,
                filter_active : false,
                filter_tester : false,
                filter_blocked : false,
            })
        }
        render(){
            console.log('props in container', this.props);
            console.log('state in container', this.state);
            const {   
                toggle,
                modal,
                confirmAction,
                modalTitle,
                modalFor, 
            } = this.state;
        let { page, limit, filter_active, filter_subscribed, filter_blocked, filter_tester, sort_join_date, sort_other } = this.state;

        
            return (
                <>
                    <div  style={{textAlign:'center'}}>
                        {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
                        <Modal size={'lg'} isOpen={modal} fade={true} toggle={toggle} keyboard={false} backdrop={false}>
                            <span>
                                <ModalHeader toggle={toggle}>Select Filters</ModalHeader>
                            </span>
                            <ModalBody>
                                <div>
                                    <Row className='page-title pb-2'>
                                        <Col md={{'offset': 0 , 'size' : 6}} className="pt-2">
                                            <Form className="">
                                                <FormGroup check>
                                                        <CustomInput type="switch" id={Math.random()*10000} name={'filter_subscribed'} defaultChecked={filter_subscribed} label="Only Subscribed Users" onChange={ (e) => {this.onChangeHandler(e.target.name, e.target.checked)} }/>
                                                </FormGroup >
                                            </Form>
                                        </Col>
                                        <Col md={{'offset': 0 , 'size' : 6}} className="pt-2">
                                            <Form className="">
                                                <FormGroup check>
                                                        <CustomInput type="switch" id={Math.random()*10000} name={'filter_active'} defaultChecked={filter_active} label="Active Users" onChange={ (e) => {this.onChangeHandler(e.target.name, e.target.checked)} }/>
                                                </FormGroup >
                                            </Form>
                                        </Col>
                                        <Col md={{'offset': 0 , 'size' : 6}} className="pt-2">
                                            <Form className="">
                                                <FormGroup check>
                                                        <CustomInput type="switch" id={Math.random()*10000} name={'filter_tester'} defaultChecked={filter_tester} label="Tester Users" onChange={ (e) => {this.onChangeHandler(e.target.name, e.target.checked)} }/>
                                                </FormGroup >
                                            </Form>
                                        </Col>
                                        <Col md={{'offset': 0 , 'size' : 6}} className="pt-2">
                                            <Form className="">
                                                <FormGroup check>
                                                        <CustomInput type="switch" id={Math.random()*10000} name={'filter_blocked'} defaultChecked={filter_blocked} label="Blocked Users" onChange={ (e) => {this.onChangeHandler(e.target.name, e.target.checked)} }/>
                                                </FormGroup >
                                            </Form>
                                        </Col>
                                    </Row>
                                    <Row>
                                            <Col md={{'offset': 0 , 'size' : 6}} className="pt-2">
                                                <Form className="">
                                                    <FormGroup row>
                                                        <Label sm={12}>Join Date :- </Label>
                                                        <Col sm={10}>
                                                            <Input type="select" name="sort_join_date" id="typeselect" value={ sort_join_date } onChange={ (e) => {this.onChangeHandler(e.target.name, e.target.value)} }>
                                                                <option value="desc">Latest</option>
                                                                <option value="asc" >Old</option>                                            
                                                            </Input>
                                                        </Col>
                                                    </FormGroup>
                                                </Form>
                                            </Col>
                                            <Col md={{'offset': 0 , 'size' : 6}} className="pt-2">
                                                <Form className="">
                                                    <FormGroup row>
                                                        <Label sm={12}>Other :- </Label>
                                                        <Col sm={10}>
                                                            <Input type="select" name="sort_other" id="typeselect" value={ sort_other } onChange={ (e) => {this.onChangeHandler(e.target.name, e.target.value)} }>
                                                                <option value="" >Select</option>                                            
                                                                <option value="subscribed" >Subcribed Users</option>                                            
                                                                <option value="host" >Sort by Hosts</option>                                            
                                                            </Input>
                                                        </Col>
                                                    </FormGroup>
                                                </Form>
                                            </Col>
                                    </Row>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={ () => { this.onClearFilter() } }>Clear Filters</Button>   
                                {/* <Button color="primary" name ={modalFor} onClick={(e)=>confirmAction(e)}>Yes{' '}</Button>{' '} */}
                                <Button color="secondary" onClick={ () => { this.toggleModal() } }>Close</Button>
                            </ModalFooter>
                        </Modal>
                        <ParentComp { ...this.props } { ...this.state } showFilters={ this.toggleModal } />
                    </div>
                </>
            )
        }
    }
}

export default withModal;