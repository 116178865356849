import React from 'react';
import {Container, Row, Col, Card, CardBody, Form, Button, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

const AddCategory = (props) => {

    // render(){
        let { modal, toggle , modalTitle, CustomClass, buttonLabel, cancelAction, confirmAction, confirmButtonText, cancelButtonText} = props;
        return(
            <Form>
                <Modal isOpen={modal} toggle={toggle} className={CustomClass}>
                    <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
                    <ModalBody>
                            <Form name = "add" onSubmit={confirmAction}>
                                <div style={{textAlign:'end'}}>
                                    <Button color="primary" type="submit" name = 'update'  >Yes</Button>{' '}
                                    <Button color="secondary"  name = 'cancel' onClick={(e) => toggle }> Cancel</Button>
                                </div>
                            </Form>
                    </ModalBody>
                    
                </Modal>
            </Form>
        )
}

export default AddCategory