import React, { useState, useEffect } from 'react';
import { Container, Card, CardBody, CustomInput, Row, Col , Form , Input } from 'reactstrap';
import TableComponent from './../Shared/Table/Table';
import TablePagination from './../Shared/Table/TablePagination';
import moment from 'moment';
import { SPOKEN_LANGUAGE_OBJECT } from './../../inc/constants';
import EditIcon from 'mdi-react/EditIcon';
import PenIcon from 'mdi-react/PencilIcon';
import DeleteIcon from 'mdi-react/CancelIcon';
import ViewIcon from 'mdi-react/EyeIcon';
import TickIcon from 'mdi-react/TickIcon';
import notification from '../../helpers/notificationHelper';
import DeleteModal from './DeleteModal';
import ListUsersModal from './ListUsersModal';
import ViewModal from './ViewModal';
// import EditModal from './EditModal';
const EditModal = React.lazy(() => import('./EditModal'));

// import DeleteModal from './DeleteModal';
// import ViewModal from './ViewModal';
// import EditModal from './EditModal';

const ListNotifications = (props) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [selectedRecord, setSelectRecord] = useState('');
  const [total, setTotal] = useState(10);
  const [isFetched, setFetched] = useState(false);
  const [content, setContent] = useState('');
  const [contentByUsers, setContentByUsers] = useState([]);
  const [contentByUser, setContentByUser] = useState('')
  const [byStatus, setByStatus] = useState('');
  const [contentId, setContentId] = useState('');
  const [title, setTitle] = useState('');
  const [comment, setComment] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [modalDelete, setModalDelete] = useState(false);
  const [modalView, setModalView] = useState(false);
  const [modalUsers, setModalUsers] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [users, setUsers] = useState([]);
  const [modalType, setModalType] = useState('');
  const [viewDetailsModal, setViewDetailsModal] = useState(false);
  const [ media, setMedia ] = useState('')
  const [ showComment, setShowComment] = useState(true)
  
  useEffect(() => {
    //   console.log('props in use effect', props);
          props._fetchData(page, limit, contentByUser, byStatus);
    //   setContent(props.premiumContentList)
  }, [page, contentByUser, byStatus])

  useEffect(() => {
    if(!props.updateContent.isFetching && props.updateContent.isFetched && !props.updateContent.err ){
        toggleDeleteModal();
        props._fetchData(page, limit, contentByUser, byStatus);
        // setComment('');
        notification('success', props.updateContent.message);
    }
    if(!props.updateContent.isFetching && props.updateContent.isFetched && props.updateContent.err ){
        notification('error', props.deleteNotification.message);
    }
  }, [ props.updateContent ])

  useEffect(() => {
      console.log('listReporteeUsers', props.listReporteeUsers);
    if(!props.listReporteeUsers.isFetching && props.listReporteeUsers.isFetched && !props.listReporteeUsers.err ){
        toggleUserModal();
        // props._fetchNotifications(page, limit);
        // notification('success', props.updateNotification.message);
        setUsers(props.listReporteeUsers.data)
    }
    if( !props.listReporteeUsers.isFetching && props.listReporteeUsers.isFetched && props.listReporteeUsers.err ){
        notification('error', props.listReporteeUsers.message);
    }
}, [props.listReporteeUsers])
  
useEffect(() => {
    console.log('props in use effect', props);
      //   props.fetchContent(page, limit);
      if(!props.premiumContentList.isFetching && props.premiumContentList.isFetched && props.premiumContentList.data !== content ){
          setContent(props.premiumContentList.data);
          setFetched(props.premiumContentList.isFetched);
          setTotal(props.premiumContentList.count)
          setContentByUsers(props.premiumContentList.contentBy)
          //   setTotal(props.premiumContentList.count);
      }
    
  //   setContent(props.premiumContentList)
}, [props.premiumContentList])

const renderIndex=(record) => {
    let index = record.rowIndex + 1 ;
    console.log('page-- --', page);
    if(page > 1){
        index  = ( page - 1)*limit + record.rowIndex + 1
    }
    return index;
}

const editRecord = (record) => {
    let id = record.id;
    console.log('record', record)
}

const onPageChange = (pagenum) => {
    console.log('page num', page)
    setPage(pagenum);
    // props.fetchUsers(page, limit);
}


const setData =(data, type) => {
    setSelectRecord(data)
    
    if(type==='deleteModal') {
        setModalType('reject');
        setTitle('Reinstated - Are you sure, you want to approve this post?');
        setShowComment(false)
        return setModalView(true);
    }else if(type==='acceptModal') {
        setModalType('accept');
        setTitle('Revoked - This post is inappropriate, it is now not visible to any user.');
        setShowComment(true)
        return setModalView(true);
    } 
    else if(type==='editRequest') {
        setModalType('edit');
        setTitle('Are you want to edit the report ?');
        return setModalView(true);
    }
    else if(type==='viewDetails') {
        setModalType('viewDetails');
        console.log('data.content',data.content);
        setTitle(data.content.description);
        setMedia(data.content.media);
        return setViewDetailsModal(true)
    }
}

const renderLanguage = (data) => {
    console.log('data in language', data)
    // return 'English';
    let lang = data.data.language || 'en'
    return SPOKEN_LANGUAGE_OBJECT[lang]
}

const renderDate = (data) => {
    return moment(data.data.date).format('MM-DD-YYYY')
}

const changeContentBy = (e) => {
    setContentByUser(e.target.value)
}

const changeByStatus = (e) => {
    setByStatus(e.target.value)
}

const renderAction = (record) => {
    let buttons = <span>
        <ViewIcon className="cursor-pointer ml-2" size={18} onClick={()=>{ setData(record.data, 'viewDetails') } }/>
            { (!record.data.status || (record.data.status !== 'accepted' && record.data.status !== 'rejected' && record.data.status !== 'notify_to_edit') ) && (
            <>
                <TickIcon className="cursor-pointer ml-2" onClick={()=>{ setData(record.data,'deleteModal' ) } } size={'18px'} />
                <DeleteIcon className="cursor-pointer ml-2" size={18} onClick={()=>{ setData(record.data, 'acceptModal') } }/>
                {/* <EditIcon className="cursor-pointer ml-2" size={18} onClick={()=>{ setData(record.data, 'editRequest') } }/> */}
            </>
        )}
    </span>;
return buttons;
}

const toggleDeleteModal = () => {
    setModalView(!modalView);
}

//render created by cell - displyname
const renderContentBY = (data) => {
    //console.log('data.data.time',data.data);
    if(data.data.content === undefined) return 'NA' 
    return data.data.content.createdBy ? data.data.content.createdBy.displayname : " ";
}

const renderContentBYEmail = (data) => {
    //console.log('data.data.time',data.data);
    if(data.data.content === undefined) return 'NA' 
    return data.data.content.createdBy ? data.data.content.createdBy.email.mail : " ";
}

const renderContentTitle = (data) => {
    console.log('data.data.time',data.data);
    if(data.data.content === undefined) return 'NA' 
    return data.data.content.description;
}
const renderPostList=(data)=>{
    
    
    if(data.data.content === undefined) return 'NA' 
    return moment(data.data.content.createdAt).format("MM-DD-YYYY");

}

//render status of the content
let renderContentIsActive = (data) => {
    console.log('data.data.time',data.data);
    if(data.data.content === undefined) return 'NA';
    let { content : { isActive } } = data.data;
    console.log('isActive', isActive); 
    return (isActive ) ? 'Yes' : 'No' ;
}

//render status of the content
let renderContentStatus = (data) => {
    /*console.log('data.data.time',data.data);
    if(data.data.content === undefined) return 'NA';
    let { status } = data.data;
    console.log('isActive', status); 
    return status ? status : 'NA';*/

    if(data.data.flagCounter < 2 && data.data.status !== 'accepted' && data.data.status !== 'rejected')
    {
        return 'Active'
    }else{
        if(data.data.status == 'accepted')
        {
            return 'Revoked'
        }else if(data.data.status == 'rejected'){
            return 'Reinstated'
        }else{
            return 'Hidden'
        }
    }
}

const renderUsersList = (data) => {
    return <p className="cursor-pointer"  onClick={ () => { fetchUsers(data.data._id) } }>{data.data.flagCounter}</p>
}

const headsAg = [
    //{ headerName: "Id", field: "_id", width:"80", sortable:true, 
        //cellRendererFramework : renderIndex ,
        // checkboxSelection : true
    //},
    { headerName: "Content Title", cellRendererFramework : renderContentTitle},
    { headerName: "Content By", cellRendererFramework : renderContentBY},
    { headerName: "Content By Email", cellRendererFramework : renderContentBYEmail},
    { headerName: "Flags Count", sortable:true, field : "flagCounter", cellRendererFramework : renderUsersList},
    {headerName:"Created At" , cellRendererFramework:renderPostList},
    //{ headerName: "Reported By", cellRendererFramework : renderUsersList },
    //{ headerName: "is Active", sortable:true, filter:'text', cellRendererFramework : renderContentIsActive},
    { headerName: "Status", field: "status", cellRendererFramework : renderContentStatus },
    {
        headerName: "Actions", 
        cellRendererFramework : renderAction, 
        cellRendererParams: {
            textAlign : "center"
        },
        sortable:true, 
        filter:'text'
    },
]

const onDeleteHandler = (e) => {
    // alert('here in delete handler');
    console.log('selectedRecord', selectedRecord);
    e.preventDefault();
    let data = { 
        contentId : selectedRecord.contentId,
        comment : comment 
    };
    if(modalType === 'accept') data['status'] = 'accepted';
    if(modalType === 'reject') data['status'] = 'rejected';
    if(modalType === 'notify') data['status'] = 'notify_to_edit';
    setComment('')
    return props._updateReportedContent(selectedRecord._id, data);
}

const fetchUsers = (recordId) => {
    console.log('recordId',recordId);
    return props._fetchReporteeUsers(recordId);
} 

const toggleUserModal = () => {
    setModalUsers(!modalUsers)
}

const toggleViewModal = () => {
    setViewDetailsModal(!viewDetailsModal);
}

const onChangeHandler = ( value ) => {
    setComment(value);
}
  //console.log('data modal meeting', content);
  let { data } = props;
  return (
    <Container  style={{textAlign:''}}>
          <Row>
            <Col md={12}>
                <Card>
                    <CardBody className="bg-white ">
                        <h3 className='page-title bb-1 border-gray mb-4'>Reported Contents</h3>
                        <Col md={{ 'size' : 5}} className="pt-2">
                            <Form className="d-flex align-items-between">
                                {/* <Label>Contribution Date :- </Label> */}
                                <Input className="mr-2" type="select" name="contentBy" id="typeselect" onChange={changeContentBy} value={contentByUser} >
                                    <option value={''}>--content by--</option>
                                    { contentByUsers && contentByUsers.map(( m ) => {
                                        return(
                                            <option value={m._id}> {m.user} </option>
                                        )
                                    }) }
                                </Input>
                                <Input type='select' name='byStatus' id="byStatus" value={byStatus} onChange={changeByStatus} >
                                    <option value={''} >--status--</option>
                                    <option value={"active"}>Active</option>
                                    <option value={"accepted"}>Revoked</option>
                                    <option value={"rejected"}>Reinstated</option>
                                    <option value={"hidden"}>Hidden</option>
                                </Input>
                            </Form>
                        </Col>
                        <Row className="mt-3">
                            <Col style={{ height: '90vh', width: '600px' }} className="ag-theme-material">
                            <div style={{height : '100%', width : '100%'}}>
                                <TableComponent 
                                    rowsData={ content } 
                                    // onCellClicked={ onCellClicked }
                                    headsAg={ headsAg }
                                    // editRecord={editRecord} 
                                    isFetched ={ isFetched }
                                    rowSelectable={'multiple'} 
                                    rowsPerPage={10}
                                    checkboxSelection={ true }
                                    // noRecordsText = {norecordstext}
                                    fetchingRecordsText = {'Fetching List...'}
                                    noRecordsText = {'No Notifications Found!'}
                                    {...props} 

                                />
                            </div>
                            </Col>
                            <Col md={12} className="mt-2">
                                <TablePagination onPageChange={onPageChange} page={page} limit={limit} total={total}/>
                            </Col>
                            <DeleteModal 
                                modal={modalView} 
                                toggle={ toggleDeleteModal }
                                modalTitle={ title }
                                confirmAction = { onDeleteHandler } 
                                onChangeHandler={ onChangeHandler }
                                comment = { comment }
                                showComment = { showComment }
                            />

                            <ListUsersModal 
                                modal ={modalUsers} 
                                data ={users} 
                                {...props} 
                                total = {total}
                                modalTitle = {'Selected Users'}
                                confirmAction={toggleUserModal}
                                // isFetched = {fetchUsers}
                                handleClose = {toggleUserModal}
                            />
                               <ViewModal 
                                    modal={viewDetailsModal} 
                                    toggle={ toggleViewModal }
                                    modalTitle={ 'Post Details' }
                                    description={ title }
                                    media={ media }
                                    confirmAction = { onDeleteHandler }
                                />
                            
                        </Row>
                    </CardBody>
                </Card>
            </Col>
          </Row>
    </Container>
  );
}

export default ListNotifications;