import { connect } from "react-redux";
import { getAllBannedEmails, addBannedEmail } from '../../redux/actions/BannedEmailAction';
import { withRouter } from 'react-router-dom';
import AddeNewBannedEmail from "../../components/BannedEmail/AddNewBannedEmail";
export const mapStateToProps = state => {
  return {
    bannedEmail : state.addBannedEmail
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    _addNewBannedEmail: (data) => {
      return dispatch(addBannedEmail(data));
    },
  };
};

const AddeNewBannedEmailContainer = connect(mapStateToProps, mapDispatchToProps)(
  AddeNewBannedEmail
);

export default withRouter(AddeNewBannedEmailContainer);
