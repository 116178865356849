import { wrappedFetch, wrappedGet } from './urlFuncs';
import {
    LIST_RESOURCES_URL,
} from "./../inc/constants";
import { API_URL } from './../inc/config';

export const fetchResourcesList = (page, limit, type) =>{
    return wrappedGet(`${API_URL}${LIST_RESOURCES_URL}?page=${page}&limit=${limit}&type=${type}`, 'GET');
}

export const createResources = (data) => {
    return wrappedFetch(`${API_URL}${LIST_RESOURCES_URL}`, 'POST', data,{
        'Content-Type': 'multipart/form-data'
    });
}

export const updateResource = (data) => {
    return wrappedFetch(`${API_URL}${LIST_RESOURCES_URL}`, 'PUT', data);
}

export const deleteResource = (resourceId) => {
    return wrappedFetch(`${API_URL}${LIST_RESOURCES_URL}${resourceId}`, 'DELETE');
}