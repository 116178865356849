import { connect } from "react-redux";
import { getAllBannedEmails, removeBanndEmail } from '../../redux/actions/BannedEmailAction';
import { withRouter } from 'react-router-dom';
import ListBannedEmail from "../../components/BannedEmail/ListBannedEmail";

export const mapStateToProps = state => {
  return {
    bannedEmails : state.bannedEmails ,
    removeBannedEmail : state.removeBannedEmail
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    _getAllBannedEmails: (data) => {
      return dispatch(getAllBannedEmails(data));
    },
    _removeBannedEmail : (emailId) => {
      return dispatch(removeBanndEmail(emailId));
    }
  };
};

const AllBannedEmailContainer = connect(mapStateToProps, mapDispatchToProps)(
    ListBannedEmail
);

export default withRouter(AllBannedEmailContainer);
