// import { ADDADMIN, ERROR, FORGETPASSWORD } from '../actions/ADDADMIN_action';
import {ADDADMINSUCCESS, ADDADMINREQUEST, ADDADMINERROR } from './../actions/actionTypes'

const initialState = {
    requestDone: false,
    isCreated: false,
    addAdminRequest:false,
    err : false,
    message:'',
  };
  
  // receiving response sent by action according to type of action
  export default function addAdminReducers(state = initialState, action) {
      //console.log(action,'action')
      let {payload} = action
    switch (action.type) {

        case ADDADMINREQUEST:
          return { 
            requestDone: false,
            isCreated: false,
            addAdminRequest: true,
            err : false,
            message: payload.message,
           };
          break;
        case ADDADMINSUCCESS:
        return { 
            requestDone: true,
            isCreated: true,
            addAdminRequest: false,
            err : false,
            message: payload.message,
        };
        break;
  
        case ADDADMINERROR:
        return { 
            requestDone: true,
            isCreated: false,
            addAdminRequest: false,
            err : true,
            message: payload.message
        };
        break;
  
      
      default:
        return state;
    }
  }
  