import * as Actions from '../actions/actionTypes';

const initialState = {
    isFetching: false,
    contentCreated: false,
    error:false,
    message : '',
    data : {}
  };
  
  // receiving response sent by action according to type of action
  export default function vierualTourReducers(state = initialState, action) {
      //console.log(action,'action');
      let { payload } = action;
    switch (action.type) {
      case Actions.FETCHVIRTUALTOURREQUEST:
        return { 
            isFetching: true,
            contentCreated: false,
            error:false,
            data:{},
            message : ''
        };
        break;
  
      case Actions.FETCHVIRTUALTOURSUCCESS:
        return { 
            isFetching: false,
            contentCreated: true,
            error:false,
            message : payload.message,
            data: payload 
        };
        break;
  
      case Actions.FETCHVIRTUALTOURERROR:
        return { 
            isFetching: false,
            contentCreated: false,
            error:true,
            data : payload,
            message : payload.message,

         };
        break;
        break;

      default:
        return state;
    }
  }
  