import React, {PureComponent} from 'react';
import LogInForm from '../../../components/Account/LogInForm';
import { withRouter } from 'react-router-dom';
class LogIn extends PureComponent {
  
  render() {
    return (
      <div className='account'>
        <div className='account__wrapper'>
          <div className='account__card'>
            <div className='account__head'>
              <h3 className='account__title'>Welcome to <span className='account__logo'>AlAnon<span
                className='account__logo-accent'>DEV</span></span></h3>
              <h4 className='account__subhead subhead'>Start your business easily</h4>
            </div>
            <LogInForm {...this.props} onSubmit/>
          </div>
        </div>
      </div>
    )
  }
}

export default  withRouter(LogIn)