import * as Actions from '../actions/actionTypes';

const initialState = {
    isFetching: false,
    fetched: false,
    error:false,
    message : '',
    data:[],
    contentBy : [],
    count : 0
  };
  
  // receiving response sent by action according to type of action
  export default function reportContentReducer(state = initialState, action) {
      //console.log(action,'action in list');
      let { payload } = action;
    switch (action.type) {
      case Actions.FETCHREPORTEDCONTENTREQUEST:
        return { 
            isFetching: true,
            isFetched: false,
            error:false,
            data:[],
            contentBy : [],
            message : '',
            count : 0
        };
        break;
  
      case Actions.FETCHREPORTEDCONTENTSUCCESS:
        return { 
            isFetching: false,
            isFetched: true,
            error:false,
            message : payload.message,
            data: payload.data,
            contentBy : payload.contentBy,
            count : payload.total 
        };
        break;
  
      case Actions.FETCHREPORTEDCONTENTERROR:
        return { 
            isFetching: false,
            isFetched: false,
            error:true,
            data: payload,
            contentBy : [],
            message : payload.message,
            count : 0

         };
        break;
        break;

      default:
        return state;
    }
  }
  