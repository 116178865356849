import { connect } from "react-redux";
import ListPostCategories from '../../components/PostCategories/ListPostCategories';
import { fetchPostCategories, updateCategory, makeAllCategoryVisible, updateCategoryVisibilty  } from '../../redux/actions/postCategoryActions';

const mapStateToProps = state => {
    //console.log('state in container', state);
    return {
        categories: state.categories, 
        updatePostCategory: state.updatePostCategory,
        makeAllPostCategoryVisible : state.makeAllPostCategoryVisible
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        _fetchPostCategories: (page, skip) => {
            return dispatch(fetchPostCategories(page, skip));
        },
        _updateCategory : (data) => {
            return dispatch(updateCategory(data));
        },
        _makeAllCategoryVisible : () => {
            return dispatch(makeAllCategoryVisible());
        },
        _updateCategoryVisibilty : (data) => {
            return dispatch(updateCategoryVisibilty(data));
        }
    };
};

const ListPostCategoriesContainer = connect(mapStateToProps, mapDispatchToProps)(
    ListPostCategories
);
 
export default ListPostCategoriesContainer;