import React from "react";
import { AgGridReact } from "ag-grid-react";
// import { AllCommunityModules } from "@ag-grid-community/all-modules";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";

class TableComponent extends React.Component {
  state = {
    // modules: AllCommunityModules,
    rows: [],
    headAg: [],
    isFetching: false,
    isFetched: true,
  };
  componentDidMount() {
    let { users, categories } = this.props;
    // console.log("=======>>>>>>>>>>>",users,categories)
    // console.log('here in all users');
    if (users) {
      var { isFetching, isFetched } = users;
    }
    if (categories) {
      var { isFetching, isFetched } = categories;
    }
    this.setState({ isFetched, isFetching });
  }

  parentRenderer = (params) => {
    console.log("params", params);
    return <span>{params.data.parent}</span>;
  };

  handleClick = (data) => {
    //console.log('event, id', data)
    const { selected } = this.state;
    this.props.onRowSelect(data.data, data.node);
    // this.setState({ selected: newSelected });, data.node
  };

  handleChangePage = (event, page) => {
    //console.log('handleChangePage', page)
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    //console.log('handleChangeRowsPerPage', event.target.value)
    this.setState({ rowsPerPage: event.target.value });
  };

  editRecord = (record) => {
    let id = record.id;
    //console.log('record', record)
  };

  deleteRecord = (records) => {
    //console.log('record', records)
    if (records.length === 1) {
      console.log("single record");
    } else if (records.length > 1) {
      console.log("single record");
    }
  };

  getSelectedRows = () => {
    const selectedNodes = this.gridApi.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    // const selectedDataStringPresentation = selectedData.map( node => node.make + ' ' + node.model).join(', ')
    //console.log(`Selected nodes: ${selectedNodes}`)
  };

  static getDerivedStateFromProps(props, state) {
    let { users } = props;
    if (users) {
      var { isFetching, isFetched } = users;
    }
    if (isFetching != state.isFetching) {
      return {
        isFetching: isFetching,
        isFetched: isFetched,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevStates) {
    // console.log('props table', this.props)
    // console.log('prevProps table', prevProps)

    if (this.gridApi) {
      //console.log('this.gridApi',this.gridApi);

      let { isFetched, users, rowsData, isModal } = this.props;
      if (prevProps.users !== users && users.isFetched) {
        // var { isFetching, isFetched } = users
        this.gridApi.hideOverlay();
      }
      if (
        prevProps.rowsData !== rowsData &&
        rowsData.length === 0 &&
        isFetched
      ) {
        // var { isFetching, isFetched } = users
        // this.gridApi.hideOverlay();
        // this.gridApi.hideOverlay();
        //console.log('no rows hereee')
        this.gridApi.showNoRowsOverlay && this.gridApi.showNoRowsOverlay();
      }
      if (prevProps.rowsData !== rowsData && rowsData.length > 0 && isFetched) {
        // var { isFetching, isFetched } = users
        // this.gridApi.hideOverlay();
        // this.gridApi.hideOverlay();
        //console.log('no rows hereee')
        this.gridApi.hideOverlay && this.gridApi.hideOverlay();
      }
      // if(isFetched) if(this.gridApi) this.gridApi.hideOverlay();
    }
  }

  onGridReady = (params) => {
    //console.log('params grid ready', params)
    let { isModal, selectedRows, setGridOptions } = this.props;
    this.gridApi = params.api;
    if (setGridOptions) {
      setGridOptions(params.api);
    }
    //console.log('selectedRows', selectedRows);
    // console.log('selectedRows', prevProps.selectedRows);
    if (selectedRows && selectedRows.length > 0) {
      this.gridApi.forEachNode((node) => {
        // console.log('node selected', node);
        // console.log('node selected', node.data._id);
        // console.log('node selected', node.isSelected());
        if (
          selectedRows.includes(node.data._id.toString()) &&
          !node.isSelected()
        ) {
          node.setSelected(true, false, true);
        }
        // if(!selectedRows.includes(node.data._id.toString()) && node.selected === true) {
        // }
      });
    }
    this.gridColumnApi = params.columnApi;
    params.rowHeight = 100;
    if (isModal) return;
    return this.gridApi.showLoadingOverlay();
  };

  render() {
    // let {isFetching} = this.props.users
    // let { page,  } = this.state
    let {
      rowsData,
      rowSelection,
      rowMultiSelectWithClick,
      page,
      rowsPerPage,
      editRecord,
      headsAg,
      onCellClicked,
      noRecordsText,
      fetchingRecordsText,
      checkboxSelection,
    } = this.props;
    //console.log('rowsData============', this.state);
    return (
      <>
        <AgGridReact
          onGridReady={this.onGridReady}
          columnDefs={headsAg}
          onRowSelected={this.handleClick}
          onPageChange={this.handleChangePage}
          onRowNumberChange={this.handleChangeRowsPerPage}
          rowData={rowsData}
          rowsPerPage={rowsPerPage}
          page={page}
          rowSelection={rowSelection}
          rowMultiSelectWithClick={rowMultiSelectWithClick}
          enableCellChangeFlash={true}
          overlayLoadingTemplate={fetchingRecordsText}
          overlayNoRowsTemplate={noRecordsText}
          rowHeight={"50"}
          checkboxSelection={checkboxSelection}
          tooltipShowDelay={0}
          tooltipHideDelay={2000}
          // suppressRowClickSelection={true}
        />
      </>
    );
  }
}

export default TableComponent;
